import React from 'react';
import _ from 'lodash';

import Button from '../../../components/Button/Button';
import Spinner from '../../../components/Spinner/Spinner';
import { default as FormComponent } from '../../../components/Form/Form';

import FormCategories from './FormCategories';
import FormDescriptions from './FormDescriptions';
import FormImages from './FormImages';
import FormInformations from './FormInformations';
import FormInformationsComplementary from './FormInformationsComplementary';
import FormVideos from './FormVideos';
import FormSizes from './FormSizes';

import '../../styles/Form.scss';

const Form = (props) => {
  const {
    className,
    loading,
    values,
    onChange,
    onChangeNameField,
    onChangeProduct,
    onSubmit,
  } = props;

  const handleRemoveLaboratory = () => {
    const newProduct = {...values};

    newProduct.laboratory = '';
    newProduct.brand = '';
    newProduct.productLine = '';
    onChangeProduct(newProduct);
  };

  const handleRemoveBrand = () => {
    const newProduct = {...values};

    newProduct.brand = '';
    newProduct.productLine = '';
    onChangeProduct(newProduct);
  };

  const handleRemoveCatalogs = (field, value) => {
    const valuesSelected = !!values[field] ? values[field] : [];
    const filteredValues = valuesSelected.filter((valueSelected) => valueSelected.value !== value.value);

    let newClassifications = [...values.classifications];
    const classificationsToRemove = newClassifications.filter((classification) => classification.catalog === value.value).map((classification) => classification.value)
    newClassifications = newClassifications.filter((classification) => classification.catalog !== value.value);

    let newCategories = [...values.categories];
    newCategories = newCategories.map((cat) => cat.filter((category) => !classificationsToRemove.includes(category.classification))).filter((c) => c.length > 0);

    const newProduct = {...values};
    newProduct.classifications = newClassifications;
    newProduct.catalogs = filteredValues;
    newProduct.categories = newCategories;

    onChangeProduct(newProduct);
  };

  const handleRemoveClassifications = (field, value) => {
    const valuesSelected = !!values[field] ? values[field] : [];
    const filteredValues = valuesSelected.filter((valueSelected) => valueSelected.value !== value.value);

    let newCategories = [...values.categories];
    newCategories = newCategories.map((cat) => cat.filter((category) => category.classification !== value.value)).filter((c) => c.length > 0);

    const newProduct = {...values};
    newProduct.categories = newCategories;
    newProduct.classifications = filteredValues;

    onChangeProduct(newProduct);
  };

  //TODO: send only needed values into Forms (not all values)
  return (
    <FormComponent onSubmit={onSubmit} className={className}>
      {
        loading
          ?
            <Spinner className="d-flex align-items-center h-100 justify-content-center" />
          :
            <>
              <div className="block-form-product">
                <div className="form-product-column">
                  <FormInformations
                    { ...(_.pick(values, 'name', 'laboratory', 'brand', 'productLine', 'EAN13', 'ACL13', 'CIP7', 'createdAt', 'updatedAt')) }
                    onChange={onChange}
                    onChangeNameField={onChangeNameField}
                    onRemoveLaboratory={handleRemoveLaboratory}
                    onRemoveBrand={handleRemoveBrand}
                  />

                  <FormDescriptions
                    shortDescription={values.shortDescription}
                    description={values.description}
                    onChangeNameField={onChangeNameField}
                  />

                  <FormVideos
                    urlVideos={values.urlVideos}
                    onChangeNameField={onChangeNameField}
                  />

                  <FormSizes
                    weight={values.weight}
                    height={values.height}
                    depth={values.depth}
                    width={values.width}
                    onChangeNameField={onChangeNameField}
                  />
                </div>

                <div className="form-product-column">
                
                  <FormCategories
                    classifications={values.classifications}
                    categories={values.categories}
                    catalogs={values.catalogs}
                    onChangeNameField={onChangeNameField}
                    onRemoveCatalogs={handleRemoveCatalogs}
                    onRemoveClassifications={handleRemoveClassifications}
                  />

                  <FormImages 
                    productFiles={values.productFiles}
                    onChangeNameField={onChangeNameField}
                  />

                  <FormInformationsComplementary
                    caution={values.caution}
                    advice={values.advice}
                    composition={values.composition}
                    notice={values.notice}
                    onChangeNameField={onChangeNameField}
                  />
                </div>
              </div>

              <Button type="submit" className="btn-secondary" onClick={onSubmit}>
                Enregistrer
              </Button>
            </>
      }
    </FormComponent>
  );
};

export default Form;