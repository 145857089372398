import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import InputSearch from './InputSearch';

import { productLineService } from '../../ProductLine/service';
import * as productLineConstants from '../../ProductLine/constants';

const { API_PATH } = productLineConstants;

const InputSearchProductLine = (props) => {
  const { className, field, brand, value } = props;
  //const [ suggests, setSuggests ] = useState([]);
  const [ productLines, setProductLines ] = useState([]);
  const [ selected, setSelected ] = useState({});
  const [ loading, setLoading ] = useState(false);

  const productLinesSuggests = productLines.map((productLine) => {
    return {
      label: productLine.name,
      value: productLine.id,
    };
  });

  const handleFocus = () => {
    if (productLines.length === 0 && Object.keys(selected).length === 0) {
      if (!!brand && !!brand.value) {
        const filters = { brand: brand.value };
        setLoading(true);
        productLineService.getAll(API_PATH, filters).then((newProductLines) => {
          setProductLines(newProductLines && newProductLines["hydra:member"]);
          setLoading(false);
        });
      }
    }
    if (productLines.length === 0 && !!selected && !!selected.label && !!selected.value) {
      productLinesSuggests.push({
        label: selected.label,
        value: selected.value
      });
    }
  };

  const handleSearchChange = (field, value) => {
    const { brand, onChange } = props;
    const filters = { name: value };

    if (typeof brand === 'object') {
      Object.assign(filters,
        { field: 'brand', value: brand.value }
      ); 
    }

    onChange(field, value);

    if (value.length > 2) {
      setLoading(true);
      productLineService.getAll(API_PATH, filters).then((newProductLines) => {
        setProductLines(newProductLines && newProductLines["hydra:member"]);
        setLoading(false);
      });
    } else {
      setProductLines([]);
    }
  };

  const handleClear = (field) => {
    const { onClear } = props;

    onClear(field);
    setSelected({});
    setProductLines([]);
  };

  const handleSelect = (field, value) => {
    const { onSelect } = props;

    setSelected(value);
    onSelect(field, value);
  };

  const selectClassName = classNames({
    'input-search-product-line': true,
    [className]: !!className,
  });

  useEffect(() => {
    if (!!brand && !!brand.value) {
      const filters = { brand: brand.value };
      setLoading(true);
      productLineService.getAll(API_PATH, filters).then((newProductLines) => {
        setProductLines(newProductLines && newProductLines["hydra:member"]);
        setLoading(false);
      });
    }
  }, [brand]);

  //TODO: onRemove ?

  return (
    <InputSearch
      field={!!field ? field : "brand"}
      label="Gamme"
      closeOnSelect
      value={value}
      suggests={productLinesSuggests}
      onClear={handleClear}
      onChange={handleSearchChange}
      onSelect={handleSelect}
      onFocus={handleFocus}
      className={selectClassName}
      loading={loading}
    />
  );
};

export default InputSearchProductLine;