// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconTemplate = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-template', className),
    onClick,
  };

  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...newProps}>
      <path d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z" />
      <path d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z" />
      <path d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z" />
    </svg>
  );
};

IconTemplate.defaultProps = {
  className: 'icon-template',
  onClick: () => {},
};

export default IconTemplate;
