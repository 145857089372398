export const initialClassification = {
  name: '',
  catalog: '',
};

export const TABLE_HEADERS = [
  {
    field: 'id',
    sortable: false,
    translation: 'Id',
  },
  {
    field: 'name',
    sortable: true,
    translation: 'Nom',
  },
  {
    field: 'catalog',
    sortable: false,
    translation: 'Catalogue',
  },
  {
    field: 'actions',
    sortable: false,
    translation: 'Actions',
    colSpan: 3
  },
];

export const API_PATH = "api/classifications";
export const PATH = "/classifications/";