import React from 'react';

import Button from '../../../components/Button/Button';
import { default as FormComponent } from '../../../components/Form/Form';
import Spinner from '../../../components/Spinner/Spinner';

const Form = (props) => {
  const {
    className,
    loading,
    values,
    onChange,
    onDelete,
    onSubmit
  } = props;

  const handleChangeFiles = (event, type) => {
    const newValues = [ ...values ];

    const file = newValues.find((value) => value.type === type);

    file.file = event.target.files[0];

    onChange(type, file);
  }

  const bcbFileEan = values.find((bcbFile) => bcbFile.type === 'EAN');
  let infoFileEanDisplay = undefined;
  if (!!bcbFileEan && !isNaN(Date.parse(bcbFileEan.createdAt))) {
    const createdAtDate = new Date(bcbFileEan.createdAt);
    infoFileEanDisplay = 'Fichier (' + bcbFileEan.file.originalName + ') uploadé le : ' + createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }
  const bcbFileCip = values.find((bcbFile) => bcbFile.type === 'CIP');
  let infoFileCipDisplay = undefined;
  if (!!bcbFileCip && !isNaN(Date.parse(bcbFileCip.createdAt))) {
    const createdAtDate = new Date(bcbFileCip.createdAt);
    infoFileCipDisplay = 'Fichier (' + bcbFileCip.file.originalName + ') uploadé le : ' + createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  return (
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-md-10 col-sm-12">
          <FormComponent onSubmit={onSubmit} className={className}>
            {
              loading
                ?
                  <Spinner className="d-flex align-items-center h-100 justify-content-center" />
                :
                <>
                  <div className="d-flex justify-content-between">
                    <div className="p-3 border mb-3 me-3 d-inline-flex flex-column">
                      <div className="form-group">
                        <label
                          htmlFor="bcb_files"
                          className="form-control-label"
                        >
                          EAN
                        </label>
                        <input
                          type="file"
                          id='bcb_files'
                          name="files"
                          className="form-control"
                          onChange={(e) => handleChangeFiles(e, 'EAN')}
                          disabled={Object.keys(bcbFileEan.file).length > 0}
                        />
                      </div>
                      <span>{ infoFileEanDisplay }</span>
                      {
                        Object.keys(bcbFileEan.file).length > 0 && (
                          <div className="pt-3">
                            <Button className="btn-error btn-delete p-1" onClick={() => onDelete(bcbFileEan.id)}>Supprimer</Button>
                          </div>
                        )
                      }
                    </div>

                    <div className="p-3 border mb-3 me-3 d-inline-flex flex-column">
                      <div className="form-group">
                        <label
                          htmlFor="bcb_files"
                          className="form-control-label"
                        >
                          CIP
                        </label>
                        <input
                          type="file"
                          id='bcb_files'
                          name="files"
                          className="form-control"
                          onChange={(e) => handleChangeFiles(e, 'CIP')}
                          disabled={Object.keys(bcbFileCip.file).length > 0}
                        />
                      </div>
                      <span>{ infoFileCipDisplay }</span>
                      {
                        Object.keys(bcbFileCip.file).length > 0 && (
                          <div className="pt-3">
                            <Button className="btn-error btn-delete p-1" onClick={() => onDelete(bcbFileCip.id)}>Supprimer</Button>
                          </div>
                        )
                      }
                    </div>
                  </div>

                  <Button type="submit" className="btn-secondary" onClick={onSubmit}>
                    Enregistrer
                  </Button>
                </>
            }
          </FormComponent>
        </div>
      </div>
    </div>
  );
};

export default Form;