// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';

import * as tagConstants from './constants';

import './styles/Tag.scss';

type Props = {
  className: string,
  icon: Node,
  style?: string,
  text: string,
}

export const Tag = (props: Props) => {
  const {
    className,
    icon,
    style,
    text,
  } = props;

  const tagStyle = style && tagConstants.TAG_STYLES.includes(style)
    ? style
    : tagConstants.PRIMARY;

  const tagClassName = classNames({
    tag: true,
    [`tag-${tagStyle}`]: true,
    [className]: !!className,
  });

  return (
    <div className={tagClassName}>
      <span className="tag-text">{text}</span>
      {icon}
    </div>
  );
};

Tag.defaultProps = {
  className: 'tag',
  style: tagConstants.PRIMARY,
};

export default Tag;
