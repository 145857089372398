export const initialProduct = {
  name: '',
  catalogs: [],
  classifications: [],
  categories: [],
  laboratory: '',
  brand: '',
  productLine: '',
  EAN13: '',
  ACL13: '',
  CIP7: '',
  shortDescription: '',
  description: '',
  urlVideos: [
    ''
  ],
  advice: '',
  caution: '',
  composition: '',
  notice: '',
  weight: {
    value: 0,
    unit: '',
  },
  height: {
    value: 0,
    unit: '',
  },
  depth: {
    value: 0,
    unit: '',
  },
  width: {
    value: 0,
    unit: '',
  },
  //generalFeatures: [],
  productFiles: []
};

export const initialBcbFiles = [
  {
    type: 'EAN',
    file: {},
  },
  {
    type: 'CIP',
    file: {},
  },
];

export const TABLE_HEADERS = [
  {
    field: 'id',
    sortable: false,
    className: 'th-id',
    translation: '',
  },
  {
    field: 'laboratory',
    sortable: false,
    translation: 'Laboratoire',
  },
  {
    field: 'brand',
    sortable: false,
    translation: 'Marque',
  },
  {
    field: 'product_line',
    sortable: false,
    translation: 'Gamme',
  },
  {
    field: 'name',
    sortable: true,
    translation: 'Nom',
  },
  {
    field: 'EAN13',
    sortable: true,
    translation: 'EAN 13',
  },
  {
    field: 'ACL13',
    sortable: true,
    translation: 'ACL 13',
  },
  {
    field: 'CIP7',
    sortable: true,
    translation: 'CIP 7',
  },
  {
    field: 'productFiles',
    sortable: false,
    translation: 'Images',
    className: 'text-center',
  },
  {
    field: 'actions',
    sortable: false,
    translation: 'Actions',
    colSpan: 3,
  },
];

export const TABLE_HEADERS_BCB = [
  {
    field: 'id',
    sortable: false,
    translation: 'Id',
  },
  {
    field: 'productName',
    sortable: false,
    translation: 'Nom de produit',
  },
  {
    field: 'productEAN',
    sortable: false,
    translation: 'EAN',
  },
  {
    field: 'productCIP',
    sortable: false,
    translation: 'CIP',
  },
];

export const API_PATH = "api/products";
export const API_PATH_IMPORTS = "api/product_imports";
export const API_PATH_BCB = "api/bcb_files";
export const API_PATH_LOGS = "api/check_product_logs";
export const PATH = "/products/";
export const PATH_IMPORTS = "/products/imports/";
export const PATH_BCB = "/products/bcb/";