import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import qs from 'qs';
import { withRouter } from 'react-router-dom';

import HeaderParamsList from '../HeaderParamsList/HeaderParamsList';
import PaginationWithSearch from '../Pagination/PaginationWithSearch';
import Spinner from '../Spinner/Spinner';
import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';

import * as productConstants from '../../Product/constants';

const { PATH: PATH_PRODUCTS } = productConstants;

const List = (props) => {
  const { 
    children,
    className,
    history,
    header,
    errorPairs,
    loading: propsLoading,
    deletingIdsPairs,
    categoriesDisplayedPairs,
    descriptionDisplayedPairs,
    config,
    noCreate,
  } = props;
  let { tableHeaders } = config;
  const {
    API_PATH,
    PATH,
    pathForButtons,
    defaultParams,
    service,
  } = config;
  const [ error, setError ] = errorPairs;
  const [ deletingIds, setDeletingIds ] = deletingIdsPairs;
  const [ isCategoriesDisplayed, ] = categoriesDisplayedPairs;
  const [ isDescriptionDisplayed, ] = descriptionDisplayedPairs;
  const [ items, setItems ] = useState([]);
  const [ loading, setLoading ] = useState(typeof propsLoading !== 'undefined' ? propsLoading : false);
  const [ urlsPagination, setUrlsPagination ] = useState({});
  const [ total, setTotal ] = useState(0);
  const search = decodeURIComponent(history.location.search);
  const params = search === "" ? defaultParams : qs.parse(search, { ignoreQueryPrefix: true });
  const [ searchParams, setSearchParams ] = useState(params);
  const order = !!searchParams.order ? searchParams.order : defaultParams.order;
  const [ itemsPerPage, setItemsPerPage ] = useState(!!searchParams.itemsPerPage ? searchParams.itemsPerPage : defaultParams.itemsPerPage);

  const handleSubmit = (newFilters) => {
    let searchParamsString = '';
    const newFiltersQS = {
      page: defaultParams.page,
      itemsPerPage: !!searchParams.itemsPerPage ? searchParams.itemsPerPage : defaultParams.itemsPerPage,
      order: !!searchParams.order ? searchParams.order : defaultParams.order,
      ...newFilters,
    };
    searchParamsString = qs.stringify(newFiltersQS, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString)});
    setSearchParams(newFiltersQS);
  };

  const handleChangeItemsPerPage = (e) => {
    setItemsPerPage(e.target.value);

    const newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams.itemsPerPage = parseInt(e.target.value, 10);
    setSearchParams(newSearchParams);

    let searchParamsString = '';
    searchParamsString = qs.stringify(newSearchParams, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString)});
  };

  const handleChangeOrder = (column, direction) => {
    const newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams.order = {
      [column]: direction
    };
    setSearchParams(newSearchParams);

    let searchParamsString = '';
    searchParamsString = qs.stringify(newSearchParams, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH}`, search: encodeURIComponent(searchParamsString)});
  };

  const handleDelete = async (itemId) => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      try {
        setLoading(true);
        await service._delete(itemId);
        const endpoint = `${API_PATH}/`;
        if (!searchParams.itemsPerPage) {
          searchParams.itemsPerPage = defaultParams.itemsPerPage;
        }
        if (!searchParams.order) {
          searchParams.order = defaultParams.order;
        }
        const newItems = await service.getAll(endpoint, searchParams);
        setError('');
        setItems(newItems["hydra:member"]);
        setUrlsPagination(newItems["hydra:view"]);
        setTotal(newItems["hydra:totalItems"]);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setItems([]);
        setUrlsPagination({});
        setTotal(0);
        setLoading(false);
      }
    }
  };

  const handleSelectedAllForDeleting = () => {
    let newDeletingIds = [ ...deletingIds ];

    if (newDeletingIds.length === items.length) {
      newDeletingIds = [];
    } else {
      newDeletingIds = items.map((product) => product.id);
    }

    setDeletingIds(newDeletingIds);
  };

  const handleSelectedForDeleting = (e) => {
    const { value, checked } = e.target;
    let newDeletingIds = [ ...deletingIds ];

    if (checked) {
      newDeletingIds.push(parseInt(e.target.value, 10));
    } else {
      newDeletingIds = newDeletingIds.filter((deletingId) => deletingId !== parseInt(value, 10));
    }
    setDeletingIds(newDeletingIds);
  };

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();

    if (mounted) {  
      const search = decodeURIComponent(history.location.search);
      if (qs.stringify(searchParams, { addQueryPrefix: true }) !== search) {
        setSearchParams(qs.parse(search, { ignoreQueryPrefix: true }))
      } else {
        setLoading(true);
        const endpoint = `${API_PATH}/`;
        if (!searchParams.itemsPerPage) {
          searchParams.itemsPerPage = defaultParams.itemsPerPage;
        }
        if (!searchParams.order) {
          searchParams.order = defaultParams.order;
        }
        service.getAll(endpoint, searchParams, {
          signal: controller.signal
        }).then((newItems) => {
          setError('');
          setItems(newItems["hydra:member"]);
          setUrlsPagination(newItems["hydra:view"]);
          setTotal(newItems["hydra:totalItems"]);
          setLoading(false);

          if (history.location.hash) {
            const id = history.location.hash.replace('#', '');
            if (!!id) {
              const found = newItems["hydra:member"].find((newItem) => newItem.id.toString() === id);
              if (found) {
                const ofTop = document.getElementById(id).offsetTop;
                document.getElementById(`block-table-${className}`).scrollTop = ofTop - 65;
              }
            }
          }
        }).catch((e) => {
          setError(!!e && !!e.message ? e.message : e);
          setItems([]);
          setUrlsPagination({});
          setTotal(0);
          setLoading(false);
        });      }
    }

    return () => {
      mounted = false;
      controller?.abort();
    }
  }, [
    searchParams,
    history.location.search,
    history.location.hash,
    API_PATH,
    className,
    defaultParams.itemsPerPage,
    defaultParams.order,
    service,
    setError,
  ])

   const classNames = classnames({
    'd-flex': true,
    'flex-column': true,
    'h-100': true,
    'mh-90vh': true,
    'justify-content-between': true,
    [`list-${className}`]: !!className,
  });
  const id = `block-table-${className}`;

  if (PATH === PATH_PRODUCTS) {
    tableHeaders[0].translation = (
      <div className="d-inline-flex">
      <input className="form-check-input" type="checkbox" onChange={handleSelectedAllForDeleting} checked={deletingIds.length === items.length} />
    </div>
    );

    const foundCategories = tableHeaders.find((tableHeader) => tableHeader.field === 'categories');
    const foundShortDescription = tableHeaders.find((tableHeader) => tableHeader.field === 'shortDescription');

    if (isCategoriesDisplayed && typeof foundCategories === 'undefined') {
      tableHeaders.splice(8, 0, {
        field: 'categories',
        sortable: false,
        translation: 'Catégories',
        className: 'categories',
      });
    } else if (!isCategoriesDisplayed) {
      tableHeaders = tableHeaders.filter((tableHeader) => tableHeader.field !== 'categories');
    }
    if (isDescriptionDisplayed && typeof foundShortDescription === 'undefined') {
      tableHeaders.splice((isCategoriesDisplayed ? 9 : 8), 0, {
        field: 'shortDescription',
        sortable: false,
        translation: 'Description',
      });
    } else if (!isDescriptionDisplayed) {
      tableHeaders = tableHeaders.filter((tableHeader) => tableHeader.field !== 'shortDescription');
    }
  }

  return (
    <div className={classNames}>
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      {
        !!header
          ?
            React.cloneElement(header, {
              history,
              categoriesDisplayedPairs,
              descriptionDisplayedPairs,
              deletingIds,
              itemsPerPage,
              searchParams,
              total,
              onChangeItemsPerPage: handleChangeItemsPerPage,
            })
          :
            <HeaderParamsList
              itemsPerPage={itemsPerPage}
              page={searchParams.page}
              path={pathForButtons}
              total={total}
              onChangeItemsPerPage={handleChangeItemsPerPage}
              noCreate={noCreate}
            />
      }

      <div className="mb-3 overflow-hidden block-table-filters">
        {
          React.cloneElement(children[0], {
            filters: searchParams,
            onSubmit: handleSubmit
          })
        }

        <div id={id} className="w-100 mw-100 overflow-auto scroll-custom mb-3">
          {
            loading
              ?
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <Spinner />
                </div>
              :
                (
                  <Table>
                    <TableHeader
                      headers={tableHeaders}
                      order={order} 
                      onChangeOrder={handleChangeOrder}
                    />
                    <tbody>
                      {
                        React.cloneElement(children[1], {
                          items,
                          deletingIds,
                          isCategoriesDisplayed,
                          isDescriptionDisplayed,
                          onDelete: handleDelete,
                          onSelectedForDeleting: handleSelectedForDeleting,
                        })
                      }
                    </tbody>
                  </Table>
                )
          }
        </div>
      </div>
      <PaginationWithSearch
        view={urlsPagination} 
        filters={searchParams}  
      />
    </div>
  );
};

List.defaultProps = {
  deletingIdsPairs: [],
  categoriesDisplayedPairs: [],
  descriptionDisplayedPairs: [],
  noCreate: false,
};

export default withRouter(List);