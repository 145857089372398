import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { productService } from '../service';
import MediaObject from '../../MediaObject/components/MediaObject';
import Tag from '../../components/Tag/Tag';
import Spinner from '../../components/Spinner/Spinner';

import * as productHelpers from '../helpers';
import * as productConstants from '../constants';
import * as tagConstants from '../../components/Tag/constants';
import * as categoryConstants from '../../Category/constants';

import '../styles/Show.scss';

const { PATH } = productConstants;
const { PATH: categoryPath } = categoryConstants;

const Show = (props) => {
  const [ product, setProduct ] = useState(productConstants.initialProduct);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const { history, location, match } = props;

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      productService._delete(match.params.id).then(() => {
        setProduct(productConstants.initialProduct);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }     
      }).catch((e) => {
        //TODO: setProduct initial ?
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);
      });
    }
  };

  const renderSingleLinks = (type, item) => {
    if (item && !!item.value && !!item.label) {
      return <Link to={`/${type}/show/${encodeURIComponent(item.value)}`}>{item.label}</Link>;
    }
  };

  const renderLinksCategories = (categories) => {
    let levelFamily = 0;
    return categories.map((cat) => {
      levelFamily++;
      return cat.map((c) => {
        return (
          <div className='d-block' key={`${c.value}-${levelFamily}`}>
            <span>
              { `${'-'.repeat(levelFamily)}> `}
              <Link to={`${categoryPath}show/${encodeURIComponent(c.value)}`}>
                {c.label}
              </Link>
            </span>
          </div>
        );
      });
    });
  };

  const renderMultipleLinks = (type, items) => {
    return items.map((item, index) => {
      return (
        <div className='d-block' key={`${type}-${item.id}-${index}`}>
          <Link to={`/${type}/show/${encodeURIComponent(item.value)}`}>{item.label}</Link>
        </div>
      );
    })
  };

  useEffect(() => {
    setLoading(true);
    productService.getById(match.params.id).then(async (newProduct) => {
      newProduct = await productHelpers.parseProductIn(newProduct);
      //TODO: utile de deepMerge ? avant oui car pas tous les fields, maintenant a voir
      //setProduct(newProduct);
      //setProduct(deepMerge(productConstants.initialProduct, newProduct));
      setProduct(newProduct);
      setLoading(false);
    }).catch((e) => {
      //TODO: setProduct initial ?
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    })
  }, [match]);

  if (loading) {
    return (
      <div className="d-flex flex-column h-100">
        <Spinner className="d-flex align-items-center h-100 justify-content-center" />
      </div>
    );
  }

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!product ? '#' + product.id : ''
  };
  const paramsForEdit = {
    ...paramsForBack,
    pathname: `${PATH}edit/${encodeURIComponent(product["id"])}`
  };
  
  //TODO: brands/categories/labo

  let picturesBlock = <div className="mw-3rem"><Tag style={tagConstants.QUATERNARY} text="Non" /></div>;
  if (!!product['productFiles'] && product['productFiles'].length > 0) {
    picturesBlock = product['productFiles'].map((picture, index) => {
      return <MediaObject key={`media-object-${picture.name}-${index}`} logo={picture.mediaObject} />;
    });
  }

  let createdAt = undefined;
  if (!isNaN(Date.parse(product.createdAt))) {
    const createdAtDate = new Date(product.createdAt);
    createdAt = createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAt = undefined;
  if (!isNaN(Date.parse(product.updatedAt))) {
    const updatedAtDate = new Date(product.updatedAt);
    updatedAt = updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }

  return (
    <div className="show-product d-flex flex-column pb-3">
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}
      {product && (
        <table className="table table-responsive table-striped table-hover">
          <thead>
            <tr>
              <th>Champ</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Nom</th>
              <td>{product["name"]}</td>
            </tr>
            <tr>
              <th scope="row">Laboratoire</th>
              <td>{renderSingleLinks('laboratories', product["laboratory"])}</td>
            </tr>
            <tr>
              <th scope="row">Marques</th>
              <td>{renderSingleLinks('brands', product["brand"])}</td>
            </tr>
            <tr>
              <th scope="row">Gammes</th>
              <td>{renderSingleLinks('product_lines', product["productLine"])}</td>
            </tr>
            <tr>
              <th scope="row">Catalogues</th>
              <td>
                {renderMultipleLinks('catalogs', product["catalogs"])}
              </td>
            </tr>
            <tr>
              <th scope="row">Classifications</th>
              <td>
                {renderMultipleLinks('classifications', product["classifications"])}
              </td>
            </tr>
            <tr>
              <th scope="row">Catégories</th>
              <td>
                {renderLinksCategories(product["categories"])}
              </td>
            </tr>
            <tr>
              <th scope="row">Images</th>
              <td>{ picturesBlock }</td>
            </tr>
            {/* <tr>
              <th scope="row">productFeatures</th>
              <td>
                {renderLinks(
                  "product_features",
                  product["productFeatures"]
                )}
              </td>
            </tr> */}
            <tr>
              <th scope="row">EAN13</th>
              <td>{product["EAN13"]}</td>
            </tr>
            <tr>
              <th scope="row">ACL13</th>
              <td>{product["ACL13"]}</td>
            </tr>
            <tr>
              <th scope="row">CIP7</th>
              <td>{product["CIP7"]}</td>
            </tr>
            <tr>
              <th scope="row">Description courte</th>
              <td>
                <div dangerouslySetInnerHTML={{
                  __html: product["shortDescription"]
                }}></div>
              </td>
            </tr>
            <tr>
              <th scope="row">Description</th>
              <td>
                <div dangerouslySetInnerHTML={{
                  __html: product["description"]
                }}></div>
              </td>
            </tr>
            <tr>
              <th scope="row">Date de création</th>
              <td>{ createdAt }</td>
            </tr>
            <tr>
              <th scope="row">Date de modification</th>
              <td>{ updatedAt }</td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="d-flex justify-content-between mb-3 pb-3">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        {product && (
          <Link to={paramsForEdit}>
            <button className="btn btn-warning">Editer</button>
          </Link>
        )}
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Show;