export const generateTotalPagination = (itemsPerPage, searchParamsPage, total) => {
  const from = itemsPerPage * (!!searchParamsPage ? (searchParamsPage - 1) : 0);
  let to = itemsPerPage * (!!searchParamsPage ? searchParamsPage : 1);
  if (to > total) {
    to = total;
  }

  return (
    <p className="mt-2 mb-2">
      Résultats : <i>{ from } - { to }</i> sur <b>{ total }</b>
    </p>
  );
};