import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//https://codepen.io/alexreardon/project/editor/ZyNMPo

import BlockContainer from '../../../components/BlockContainer/BlockContainer';
import Button from '../../../components/Button/Button';
import MediaObject from '../../../MediaObject/components/MediaObject';

const FormImages = (props) => {
  const { productFiles, onChangeNameField } = props;

  const handleDeleteFile = (filePosition) => {
    let newValuesFiles = !productFiles ? [] : productFiles;
    newValuesFiles = newValuesFiles.filter((file) => file.position !== filePosition).map((file) => {
      if (file.position > filePosition) {
        file.position -= 1;
      }
      return file;
    }).sort((a, b) => {
      if (a.position > b.position) return 1;
      if (b.position > a.position) return -1;

      return 0;
    });

    onChangeNameField('productFiles', newValuesFiles);
  };

  const handleChangeFiles = (event) => {
    const newValuesFiles = !productFiles ? [] : productFiles;

    let lastPosition = 1;
    if (newValuesFiles.length > 0) {
      lastPosition = Math.max.apply(Math, newValuesFiles.map(function(o) { return o.position; })) + 1;
    }

    for (let x = 0; x < event.target.files.length; x++) {
      newValuesFiles.push({
        position: (lastPosition + x),
        mediaObject: event.target.files[x],
        cover: null,
      });
    }

    onChangeNameField('productFiles', newValuesFiles);
  };

  const handleCoverProductFile = (e) => {
    const position = e.target.name.split('-')[e.target.name.split('-').length - 1];
    let newValuesFiles = !productFiles ? [] : productFiles;
    newValuesFiles = newValuesFiles.map((file) => {
      file.cover = null;
      if (file.position === parseInt(position, 10)) {
        file.cover = true;
      }
      return file;
    });

    onChangeNameField('productFiles', newValuesFiles);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let newValuesFiles = !productFiles ? [] : productFiles;
    newValuesFiles = newValuesFiles.map((file) => {
      if (file.position === result.source.index) {
        file.position = result.destination.index;
      } else if (file.position === result.destination.index) {
        file.position = result.source.index;
      }
      return file;
    }).sort((a, b) => {
      if (a.position > b.position) return 1;
      if (b.position > a.position) return -1;

      return 0;
    });

    onChangeNameField('productFiles', newValuesFiles);
  };

  const grid = productFiles.length;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    textAlign: 'center',

    background: isDragging ? "lightgreen" : "whitesmoke",

    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#033435" : "#6EC04B",
    padding: grid,
  });

  const picturesBlock = productFiles && (
    productFiles.map((file, index) => {
      if (file.mediaObject instanceof File) {
        return (
          <Draggable key={`product-file-draggable-${index}`} draggableId={file.position.toString()} index={file.position}>
            {(provided, snapshot) => (
              <div 
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <img 
                  key={`product-picture-${file.mediaObject.name}-${index}`}
                  className="mx-auto my-auto img-fluid"
                  alt='product-file'
                  src={URL.createObjectURL(file.mediaObject)}
                />
                <p className="position-img">Position {file.position}</p>
                <div>
                  <input type="radio" id="cover" name={`cover-${file.position}`} checked={file.cover === null ? false : file.cover} onChange={handleCoverProductFile} />
                  <label htmlFor="cover" className="ms-1">Cover</label>
                </div>
                <div>
                  <Button className="btn-error btn-delete" onClick={() => handleDeleteFile(file.position)}>Supprimer</Button>
                </div>
              </div>
            )}
          </Draggable>
        );
      }
      return (
        <Draggable key={`product-file-draggable-${index}`} draggableId={file.position.toString()} index={file.position}>
          {(provided, snapshot) => (
            <div 
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <MediaObject key={`product-picture-${file.name}-${index}`} logo={file.mediaObject} />
              <p className="position-img">Position {file.position}</p>
              <div>
                <input type="radio" id="cover"  name={`cover-${file.position}`} checked={file.cover === null ? false : file.cover} onChange={handleCoverProductFile} />
                <label htmlFor="cover" className="ms-1">Cover</label>
              </div>
              <div>
                <Button className="btn-error btn-delete" onClick={() => handleDeleteFile(file.position)}>Supprimer</Button>
              </div>
            </div>
          )}
        </Draggable>
      );
    })
  );

  return (
    <BlockContainer title="Images produit">
      <div className="form-group">
        <label
          htmlFor="product_brand"
          className="form-control-label"
        >
          Images
        </label>
        <input
          type="file"
          id='product_productFiles'
          name="productFiles"
          className="form-control mb-3"
          //value={values.logo}
          onChange={handleChangeFiles}
          multiple
        />
        {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
        <p style={{color: 'red'}}>Vous pouvez drag/drop chaque image pour choisir son ordre de position. Attention, quand vous droppez, l'image draggé remplace celle sur laquelle vous droppez.</p>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
              { picturesBlock }
              {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </BlockContainer>
  );
};

export default FormImages;