import React from 'react';

import IconSort from '../../icons/IconSort';
import IconSortUp from '../../icons/IconSortUp';
import IconSortDown from '../../icons/IconSortDown';

const TableHeader = (props) => {
  const { headers, order, onChangeOrder } = props;

  const thsBlock = [];
  for (const header of headers) {
    let th = {};
    const colSpan = !!header.colSpan ? header.colSpan : 1;
    const classNameTh = !!header.className ? header.className : '';
    th = (
      <th key={header.field} className={classNameTh} colSpan={colSpan}>{ header.translation }</th>
    );
    if (!!header.sortable) {
      let sortingIcon = <IconSort />;
      let direction = 'asc';
      if (!!order[header.field]) {
        sortingIcon = <IconSortDown />;
        if (order[header.field] === 'asc') {
          sortingIcon = <IconSortUp />;
          direction = 'desc';
        }
      }
      th = (
        <th key={header.field} className={ `sortable ${classNameTh}` } onClick={() => onChangeOrder(header.field, direction)}>
          <span>{header.translation} { sortingIcon }</span>
        </th>
      );
    }
    thsBlock.push(th);
  }

  return (
    <thead>
      <tr>
        { thsBlock }
      </tr>
    </thead>
  );
};

export default TableHeader;