export const initialCatalog = {
  name: ''
};

export const TABLE_HEADERS = [
  {
    field: 'id',
    sortable: false,
    translation: 'Id',
  },
  {
    field: 'name',
    sortable: true,
    translation: 'Nom',
  },
  {
    field: 'actions',
    sortable: false,
    translation: 'Actions',
    colSpan: 3
  },
];

export const API_PATH = "api/catalogs";
export const PATH = "/catalogs/";
