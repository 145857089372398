import React from 'react';

import './styles/Table.scss';

const Table = (props) => {
  const {
    children
  } = props;

  return (
    <table className="table table-responsive table-striped table-hover">
      { children }
    </table>
  );
}

export default Table;