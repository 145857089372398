export const initialCategory = {
  name: '',
  parent: '',
  classification: '',
};

export const TABLE_HEADERS = [
  {
    field: 'id',
    sortable: false,
    translation: 'Id',
  },
  {
    field: 'name',
    sortable: true,
    translation: 'Nom',
  },
  {
    field: 'parent',
    sortable: false,
    translation: 'Catégorie parente',
  },
  {
    field: 'classification',
    sortable: false,
    translation: 'Classification',
  },
  {
    field: 'actions',
    sortable: false,
    translation: 'Actions',
    colSpan: 3
  },
];

export const API_PATH = "api/categories";
export const PATH = "/categories/";