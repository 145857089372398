// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => {},
};

const IconCheck = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-check', className),
    onClick,
  };

  return (
    <svg viewBox="0 0 13.969 10.97" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" {...newProps}>
      <path d="M-.008 5.463l1.119-1.307 3.952 3.34L12.93-.011l1.044.944-8.911 10.049-5.071-5.519z" />
    </svg>
  );
};

IconCheck.defaultProps = {
  className: 'icon-check',
  onClick: () => {},
};

export default IconCheck;
