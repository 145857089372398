import qs from 'qs';

import { API_HOST } from "../config/config";
import * as productLineConstants from './constants';

import { authHeader, userService } from '../User/service';

const { API_PATH } = productLineConstants;

const getAll = (page = API_PATH, params = {}, options = {}) => {
  const requestOptions = {
    method: 'GET',
    headers: new Headers({ ...authHeader() }),
  };
  if (Object.keys(options).length > 0) {
    Object.assign(requestOptions, options);
  }
  let searchParams = '';
  if (Object.keys(params).length > 0) {
    const newParams = {...params};
    if (!!newParams.laboratory && !!newParams.laboratory.value) {
      newParams.laboratory = newParams.laboratory.value;
    }
    if (!!newParams.brand && !!newParams.brand.value) {
      newParams.brand = newParams.brand.value;
    }
    searchParams = qs.stringify(newParams, { addQueryPrefix: true });
  }

  return fetch(`${API_HOST}${page}${searchParams}`, requestOptions).then(userService.handleResponse);
};

const getById = (id) => {
  const requestOptions = {
    method: 'GET',
    headers: new Headers({ ...authHeader() }),
  };

  return fetch(`${API_HOST}${API_PATH}/${id}`, requestOptions).then(userService.handleResponse);
};

// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = (id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: new Headers({ ...authHeader() }),
  };

  return fetch(`${API_HOST}${API_PATH}/${id}`, requestOptions).then(userService.handleResponse);
};

const create = (values) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...authHeader(),
    }),
    body: JSON.stringify(values)
  };

  return fetch(`${API_HOST}${API_PATH}`, requestOptions).then(userService.handleResponse);
};

const update = (id, values) => {
  const requestOptions = {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...authHeader(),
    }),
    body: JSON.stringify(values)
  };

  return fetch(`${API_HOST}${API_PATH}/${id}`, requestOptions).then(userService.handleResponse);
};

export const productLineService = {
  getAll,
  getById,
  create,
  update,
  _delete
};