import { mediaObjectService } from '../MediaObject/service';

export const parseCatalogsIn = (catalogs) => {
  return catalogs.map((c) => ({
    value: c.id,
    label: c.name,
  }));
};

export const parseClassificationsIn = (classifications) => {
  return classifications.map((c) => ({
    value: c.id,
    label: c.name,
    catalog: c.catalog.id,
  }));
};

export const parseCategoriesIn = (categories) => {
  return categories.map((cat, index) => {
    return cat.map((c) => {
      return { label: c.name, value: c.id, classification: !!c.classification.id && c.classification.id, categories: c.categories };
    });
  });
};

export const parseProductIn = (newProduct) => {
  if (!!newProduct.laboratory) {
    newProduct.laboratory = { value: newProduct.laboratory.id, label: newProduct.laboratory.name };
  } else {
    newProduct.laboratory = '';
  }
  if (!!newProduct.brand) {
    newProduct.brand = { value: newProduct.brand.id, label: newProduct.brand.name };
  } else {
    newProduct.brand = '';
  }
  if (!!newProduct.productLine) {
    newProduct.productLine = { value: newProduct.productLine.id, label: newProduct.productLine.name };
  } else {
    newProduct.productLine = '';
  }
  if (!!newProduct.catalogs) {
    newProduct.catalogs = parseCatalogsIn(newProduct.catalogs);
  }
  if (!!newProduct.classifications) {
    newProduct.classifications = parseClassificationsIn(newProduct.classifications);
  }
  if (!!newProduct.categories) {
    newProduct.categories = parseCategoriesIn(newProduct.categories);
  }
  if (newProduct.urlVideos.length === 0) {
    newProduct.urlVideos = [ '' ];
  }
  return newProduct;
}

export const parseCatalogsOut = (catalogs) => {
  return catalogs.map((c) => (
    c.value
  ));
};

export const parseClassificationsOut = (classifications) => {
  return classifications.map((c) => (
    c.value
  ));
};

export const parseCategoriesOut = (categories) => {
  return categories.map((cat) => {
    return cat.map((c) => {
      return c.value;
    });
  });
};

export const cleanCategoriesOut = (categories) => {
  return categories.filter(c => c.length > 0);
};

export const parseProductOut = async (payload) => {
  //TODO: try/catch ? a tester quand erreur
  if (payload.laboratory) {
    payload.laboratory = payload.laboratory.value;
  }
  if (payload.brand) {
    payload.brand = payload.brand.value;
  }
  if (payload.productLine) {
    payload.productLine = payload.productLine.value;
  } else {
    payload.productLine = null;
  }
  if (payload.catalogs) {
    payload.catalogs = parseCatalogsOut(payload.catalogs);
  }
  if (payload.classifications) {
    payload.classifications = parseClassificationsOut(payload.classifications);
  }
  if (payload.categories) {
    payload.categories = parseCategoriesOut(payload.categories);
    payload.categories = cleanCategoriesOut(payload.categories);
  }
  const filesToUpload = payload.productFiles.filter((file) => { return (file.mediaObject instanceof File); });
  const filesAlreadyUploaded = payload.productFiles
    .filter((file) => { return !(file.mediaObject instanceof File); })
    .map((file) => ({ 
      ...file,
      mediaObject: file.mediaObject.id,
    }));

  for (let i = 0; i < filesToUpload.length; i++) {
    const uploadedFile = await mediaObjectService.createMediaObject();
    await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, filesToUpload[i].mediaObject);
    const fileToUpload = {
      ...filesToUpload[i],
      mediaObject: uploadedFile.id,
    };
    filesAlreadyUploaded.push(fileToUpload);
  }
  payload.productFiles = filesAlreadyUploaded.sort((a, b) => {
    if (a.position > b.position) return 1;
    if (b.position > a.position) return -1;

    return 0;
  });

  if (payload.weight) {
    payload.weight = {
      value: parseFloat(payload.weight.value),
      unit: payload.weight.unit
    };
  }
  if (payload.height) {
    payload.height = {
      value: parseFloat(payload.height.value),
      unit: payload.height.unit
    };
  }
  if (payload.width) {
    payload.width = {
      value: parseFloat(payload.width.value),
      unit: payload.width.unit
    };
  }
  if (payload.depth) {
     payload.depth = {
      value: parseFloat(payload.depth.value),
      unit: payload.depth.unit
    };
  }

  //TODO: clean urlVideos si each urlVideo has length === 0 + use regex url youtube ?
  payload.urlVideos = payload.urlVideos.filter((urlVideo) => urlVideo.length > 0);

  return payload;
};