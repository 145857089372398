// @flow
import React, { type Node } from 'react';
import classnames from 'classnames';

import './styles/BlockContainer.scss';

type Props = {
  className: string,
  children: Node,
  title: string,
  wrapperClassName: string,
};

const BlockContainer = (props: Props) => {
  const { children, className, wrapperClassName, title } = props;

  const blocClassName = classnames({
    'block-container': true,
    [className]: !!className,
  });

  const wrapperBlocClassName = classnames({
    'block-container-wrapper': true,
    [wrapperClassName]: !!wrapperClassName,
  });

  return (
    <div className={blocClassName}>
      {!!title && (
        <div className="block-container-header">
          <span className="block-container-title">{title}</span>
        </div>
      )}
      <div className={wrapperBlocClassName}>
        {children}
      </div>
    </div>
  );
};

BlockContainer.defaultProps = {
  className: 'block-container',
  wrapperClassName: 'block-container-wrapper',
  title: '',
};

export default BlockContainer;
