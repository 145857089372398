import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import InputSearchMultiple from './InputSearchMultiple';

import { categoryService } from '../../Category/service';
import * as categoryConstants from '../../Category/constants';
import * as categoryHelpers from '../../Category/helpers';

const { API_PATH } = categoryConstants;

const InputSearchMultipleCategories = (props) => {
  const { classifications, className, values, label, onRemove } = props;
  const [ categories, setCategories ] = useState([]);
  const [ selected, setSelected ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ families, setFamilies ] = useState([]);

  const categoriesSuggests = categories.map((category) => {
    return {
      label: category.label,
      value: category.value,
      classification: category.classification,
    };
  });

  const handleFocus = () => {
    if (categories.length === 0 && Object.keys(selected).length === 0) {
      if (!!classifications && classifications.length > 0) {
        const filters = {
          classification: classifications.map((c) => c.value),
        };
        setLoading(true);
        categoryService.getAll(API_PATH, filters).then((newCategories) => {
          const cleanedCategories = categoryHelpers.parseCategoriesInForSelects(newCategories && newCategories["hydra:member"]);
          setCategories(cleanedCategories);
          setLoading(false);
        });
      }
    }
    if (categories.length === 0 && !!selected && !!selected.value) {
      categoriesSuggests.push({
        label: selected.label,
        value: selected.value,
      });
    }
  };

  const handleSearchChange = (field, value) => {
    const { onChange } = props;
    const filters = {
      name: value,
    };

    if (!!classifications && classifications.length > 0) {
      Object.assign(filters, { classification: classifications.map((c) => c.value) });
    }

    //TODO: add index and pass by handleChangeCategoriesTest Form ?
    onChange(field, value);

    if (value.length > 2) {
      setLoading(true);
      categoryService.getAll(API_PATH, filters).then((newCategories) => {
        const cleanedCategories = categoryHelpers.parseCategoriesInForSelects(newCategories && newCategories["hydra:member"]);
        setCategories(cleanedCategories);
        setLoading(false);
      });
    } /* else {
      setCategories([]);
    } */
  };

  const handleSelect = (field, value, index) => {
    const { onSelect } = props;
    let newFamilies = [...families];
    let selectedCat = categories.filter((category) => category.value === value.value);
    if (selectedCat.length === 0 && typeof newFamilies[index - 1] !== 'undefined') {
      selectedCat = newFamilies[index - 1].filter((category) => category.value === value.value);
    }
    if (selectedCat.length > 0 && selectedCat[0].categories.length > 0) {
      if (typeof newFamilies[index] !== 'undefined') {
        const found = newFamilies[index].some(r => {
          return selectedCat[0].categories.includes(r)
        });
        if (!found) {
          newFamilies[index] = newFamilies[index].concat(selectedCat[0].categories);
        }
      } else {
        const found = newFamilies.some(r => selectedCat[0].categories.includes(r))
        if (!found) {
          newFamilies.push(selectedCat[0].categories);
        }
      }
      setFamilies(newFamilies);
    }
    setSelected(value);
    onSelect(field, value, index);
  };

  const getChildrenIds = (categories, classificationId, res = []) => {
    categories.forEach(category => {
      const categoryClassificationId = category.classification;
      if (categoryClassificationId === classificationId) {
        res.push(category.value);
      }
      if (category.categories) {
        getChildrenIds(category.categories, classificationId, res);
      }
    });
    return res;
  };

  const handleRemove = (field, value, index) => {
    let valueToRemove = categories.filter((c) => c.value === value.value);
    if (index > 0) {
      valueToRemove = families[index].filter((child) => child.value === value.value);
    }
    if (valueToRemove.length === 0 && typeof families[index] !== 'undefined') {
      valueToRemove = families[index].filter((child) => child.value === value.value);
    }

    if (valueToRemove.length > 0) {
      let idsToRemove = [ valueToRemove[0].value ];
      let idsFamiliesToRemove = [];
      if (!!valueToRemove[0].categories && valueToRemove[0].categories.length > 0) {
        const classificationId = valueToRemove[0].classification;
        idsFamiliesToRemove = getChildrenIds(valueToRemove[0].categories, classificationId);
        idsToRemove = idsToRemove.concat(idsFamiliesToRemove);
      }
      onRemove(idsToRemove);

      const newFamilies = families.map((fam) => {
        return fam.filter((c) => {
          return idsFamiliesToRemove.indexOf(c.value) === -1;
        });
      });
      setFamilies(newFamilies);
    }
  };

  const renderFamilies = (families) => {
    return families
      .filter((fam) => fam.length > 0)
      .map((fam, i) => {
        const suggests = fam.map((f) => {
          const classificationId = f.classification;
          return {
            label: f.label,
            value: f.value,
            classification: classificationId,
          };
        });
        //TODO: onChange for children categories ? not passing through API, but only search into array families+index
        return (
          <InputSearchMultiple
            key={`input-search-mutliple-children-categories-${i}`}
            field="categories"
            label={`Catégories enfante ${i + 1}`}
            closeOnSelect
            values={values[i + 1]}
            suggests={suggests}
            /*onChange={handleSearchChange}*/
            onSelect={(field, value) => handleSelect(field, value, i + 1)}
            /*onFocus={handleFocus}
            className={selectClassName}*/
            onRemove={(field, value) => handleRemove(field, value, i)}
          />
        )
      })
  };

  const getFamilies = () => {
    let newFamilies = [];
    for (let i = 0; i < values.length; i++) {
      for (let x = 0; x < values[i].length; x++) {
        if (typeof newFamilies[i] === 'undefined') {
          newFamilies[i] = [];
        }

        if (Array.isArray(values[i][x].categories) && values[i][x].categories.length > 0) {
          const newFamily = categoryHelpers.parseCategoriesInForSelects(values[i][x].categories);
          newFamilies[i].push(...newFamily);
        }
      }
    }
    return newFamilies;
  };

  useEffect(() => {
    let controller = new AbortController();
    let mounted = true;

    if (mounted) {
      setLoading(true);
      if (values.length > 0) {
        const newValues = values.map((v) => categoryHelpers.parseCategoriesInForSelects(v));
        setSelected(newValues[0]);
        const newFamilies = getFamilies();
        setFamilies(newFamilies)
      }
      const filters = {};

      if (!!classifications && classifications.length > 0) {
        Object.assign(filters, { classification: classifications.map((c) => c.value) });
        Object.assign(filters, { parent: "false" });
      }
      categoryService.getAll(API_PATH, filters, {
        signal: controller.signal
      }).then((newCategories) => {
        const cleanedCategories = categoryHelpers.parseCategoriesInForSelects(newCategories && newCategories["hydra:member"]);
        setCategories(cleanedCategories);
        setLoading(false);
      });
    }

    return () => {
      mounted = false;
      controller?.abort();
    };
  }, [classifications])
  // DON'T add "values" into array dependencies useEffect, let array like this,
  // cause doesnt work here (if u choose new value in cat parente, no children added in categorie enfant 1)

  const familiesBlock = renderFamilies(families);
  const selectClassName = classNames({
    'input-search-multiple-categories': true,
    [className]: !!className,
  });

  return (
    <>
      <InputSearchMultiple
        field="categories"
        label={!!label ? label : "Catégories parente"}
        closeOnSelect
        values={values[0]}
        suggests={categoriesSuggests}
        onChange={handleSearchChange}
        onSelect={(field, value) => handleSelect(field, value, 0)}
        onFocus={handleFocus}
        className={selectClassName}
        onRemove={(field, value) => handleRemove(field, value, 0)}
        loading={loading}
      />
      { familiesBlock }
    </>
  );
}

export default InputSearchMultipleCategories;