import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import InputSearch from './InputSearch';

import { catalogService } from '../../Catalog/service';
import * as catalogConstants from '../../Catalog/constants';

const { API_PATH } = catalogConstants;

const InputSearchCatalog = (props) => {
  const { className, field, value } = props;
  //const [ suggests, setSuggests ] = useState([]);
  const [ catalogs, setCatalogs ] = useState([]);
  const [ selected, setSelected ] = useState({});
  const [ loading, setLoading ] = useState(false);

  const catalogsSuggests = catalogs.map((catalog) => {
    return {
      label: catalog.name,
      value: catalog.id,
    };
  });

  const handleFocus = () => {
    if (catalogs.length === 0 && catalogsSuggests.length === 0 && !!selected && !!selected.label && !!selected.value) {
      catalogsSuggests.push({
        label: selected.label,
        value: selected.value
      });
    }
  };

  const handleSearchChange = (field, value) => {
    const { onChange } = props;
    const filters = {
      name: value
    };

    onChange(field, value);

    if (value.length > 2) {
      setLoading(true);
      catalogService.getAll(API_PATH, filters).then((newCatalogs) => {
        setCatalogs(newCatalogs && newCatalogs["hydra:member"]);
        setLoading(false);
      });
    } /* else {
      setCatalogs([]);
    } */
  };

  const handleClear = (field) => {
    const { onClear } = props;

    onClear(field);
    //setCatalogs([]);
    setLoading(true);
    catalogService.getAll().then((newCatalogs) => {
      setCatalogs(newCatalogs && newCatalogs["hydra:member"]);
      setLoading(false);
    });
  };

  const handleSelect = (field, value) => {
    const { onSelect } = props;

    setSelected(value);
    onSelect(field, value);
  };

  const selectClassName = classNames({
    'input-search-catalog': true,
    [className]: !!className,
  });

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();

    if (mounted) {
      setLoading(true);
      catalogService.getAll(API_PATH, {}, {
        signal: controller.signal
      }).then((newCatalogs) => {
        setCatalogs(newCatalogs && newCatalogs["hydra:member"]);
        setLoading(false);
      }).catch(e => {
        console.log('Error InputSearchCatalog :', e)
      });;
    }

    return () => {
      mounted = false;
      controller?.abort();
    }
  }, []);

  //TODO: onRemove ?

  return (
    <InputSearch
      field={!!field ? field : "catalog"}
      label="Catalogue"
      closeOnSelect
      value={value}
      suggests={catalogsSuggests}
      onClear={handleClear}
      onChange={handleSearchChange}
      onSelect={handleSelect}
      onFocus={handleFocus}
      className={selectClassName}
      loading={loading}
    />
  );
};

export default InputSearchCatalog;