import React from 'react';
import './App.scss';

import RouteApp from './RouteApp';

const App = () => (
  <>
    <RouteApp />
  </>
);

export default App;
