import React, { useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

//import { mediaObjectService } from '../../MediaObject/service';

//https://www.tiny.cloud/docs/integrations/react/#usingthetinymcereactcomponentasacontrolledcomponent
const TextEditor = (props) => {
  const { initialValue, field, height, withImage, onChange, onFocus } = props;
  const [value, setValue] = useState(initialValue ?? '');
  const editorRef = useRef(null);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(field, newValue);
  };

  const handleFocus = (newValue) => {
    onFocus();
  };

 /*  useEffect(() => {
    console.log('useEffect initialValue', initialValue)
    setValue(initialValue ?? '');
  }, [initialValue]); */

  const editorParamsForImages = {
    file_picker_types: 'image',
    file_picker_callback: function (cb, value, meta) {
      var input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      /*
        Note: In modern browsers input[type="file"] is functional without
        even adding it to the DOM, but that might not be the case in some older
        or quirky browsers like IE, so you might want to add it to the DOM
        just in case, and visually hide it. And do not forget do remove it
        once you do not need it anymore.
      */

      input.onchange = function () {
        var file = this.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          /*
            Note: Now we need to register the blob in TinyMCEs image blob
            registry. In the next release this part hopefully won't be
            necessary, as we are looking to handle it internally.
          */
          var id = 'blobid' + (new Date()).getTime();
          var blobCache = editorRef.current.editorUpload.blobCache;
          var base64 = reader.result.split(',')[1];
          var blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          /* call the callback and populate the Title field with the file name */
          cb(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      };

      input.click();
    },
    images_upload_handler: async (blobInfo, success, failure, progress) => {
      try {
        return success('data:image/png;base64, ' + blobInfo.base64());
        /* const uploadedFile = await mediaObjectService.createMediaObject();
        await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, blobInfo.blob());
        const img = await mediaObjectService.getById(uploadedFile.id);

        return success(img.url); */
      } catch (e) {
        failure(e && e.message ? e.message : e);
      }
    },
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar: 'undo redo | formatselect | image | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help',
  };

  let editorParams = {
    height: height,
    menubar: false,
    plugins: [
      'advlist autolink lists link charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar: 'undo redo | formatselect | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  };

  if (withImage) {
    editorParams = {
      ...editorParams,
      ...editorParamsForImages,
    }
  }
 
  return (
    <Editor
      //onInit={(evt, editor) => editorRef.current = editor}
      //initialValue={initialValue}
      value={value}
      apiKey="3zyefcvx0bw602an6m73msrhlbyzqkxw38m4ah9rrqouu32s"
      onEditorChange={(newValue) => handleChange(newValue)}
      onFocus={handleFocus}
      init={editorParams}
      onInit={(evt, editor) => editorRef.current = editor}
    />
  );
};

TextEditor.defaultProps = {
  height: 400,
}

export default TextEditor;