// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconCatalog = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-catalog', className),
    onClick,
  };

  return (
    <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...newProps}>
      <g>
        <path d="m122.3 16.3h-48.3c-4.1 0-7.9 2.1-10 5.6-2.1-3.5-5.9-5.6-10-5.6h-48.2c-3.2 0-5.8 2.5-5.8 5.7v88c0 1 .8 1.7 1.8 1.8h124.5c1 0 1.8-.8 1.8-1.8v-88c-.1-3.2-2.7-5.7-5.8-5.7zm-48.3 3.5h48.3c1.2 0 2.2 1 2.3 2.2v71.2h-50.6c-3.1 0-6.1 1.2-8.3 3.4v-68.6c.1-4.6 3.7-8.2 8.3-8.2zm-68.2 0h48.2c4.6 0 8.2 3.7 8.3 8.2v68.6c-2.2-2.2-5.2-3.4-8.3-3.4h-50.5v-71.2c0-1.2 1-2.2 2.3-2.2zm-2.3 76.9h50.5c4.6 0 8.2 3.7 8.3 8.3v3.3h-58.8zm121 11.5h-58.7v-3.2c0-4.6 3.7-8.2 8.3-8.2h50.5v11.4z"/>
        <path d="m9.8 54.5h46.2c1 0 1.7-.8 1.8-1.8v-26.7c0-1-.8-1.7-1.8-1.8h-46.2c-1 .1-1.8.8-1.8 1.8v26.8c0 .9.8 1.7 1.8 1.7zm1.7-3.5v-1.6l2.7-2.8 6.1-6.4 11.5 8.7c.6.5 1.5.5 2.1 0l11.5-8.7 8.8 9.2v1.6zm42.7-23.2v16.6l-7.3-7.7c-.6-.6-1.6-.7-2.3-.2l-11.7 8.8-11.7-8.8c-.7-.5-1.7-.5-2.3.2l-7.3 7.7v-16.6z"/>
        <path d="m32.8 40.3c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5-5.5 2.5-5.5 5.5c.1 3.1 2.5 5.5 5.5 5.5zm0-7.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2c.1-1.1 1-1.9 2-2z"/>
        <path d="m72 54.5h46.2c1 0 1.7-.8 1.8-1.8v-26.7c0-1-.8-1.7-1.8-1.8h-46.2c-1 0-1.7.8-1.8 1.8v26.8c.1.9.9 1.7 1.8 1.7zm1.8-3.5v-1.6l8.8-9.2 11.5 8.7c.6.5 1.5.5 2.1 0l11.5-8.7 8.8 9.2v1.6zm42.7-23.2v16.6l-7.3-7.7c-.6-.6-1.6-.7-2.3-.2l-11.7 8.8-11.7-8.8c-.7-.5-1.7-.4-2.3.2l-7.3 7.7v-16.6z"/>
        <path d="m95.1 40.3c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5-5.5 2.5-5.5 5.5c0 3.1 2.5 5.5 5.5 5.5zm0-7.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2c0-1.1.9-1.9 2-2z"/>
        <path d="m9.8 62.5h46.2c1 0 1.8-.8 1.8-1.8s-.8-1.7-1.8-1.7h-46.2c-1 0-1.8.8-1.8 1.8s.8 1.7 1.8 1.7z"/>
        <path d="m14.2 67.8c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h37.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z"/>
        <path d="m9.8 80h46.2c1 0 1.8-.8 1.8-1.8s-.8-1.7-1.8-1.7h-46.2c-1 0-1.8.8-1.8 1.8s.8 1.7 1.8 1.7z"/>
        <path d="m51.6 85.2h-37.4c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h37.4c1 0 1.8-.8 1.8-1.8s-.9-1.8-1.8-1.8z"/>
        <path d="m72 62.5h46.3c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8h-46.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8z"/>
        <path d="m76.4 67.8c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h37.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z"/>
        <path d="m72 80h46.3c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8h-46.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8z"/>
        <path d="m113.8 85.2h-37.4c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h37.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z"/>
      </g>
    </svg>
  );
};

IconCatalog.defaultProps = {
  className: 'icon-catalog',
  onClick: () => {},
};

export default IconCatalog;
