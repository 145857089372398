import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { productLineService } from '../service';
import { mediaObjectService } from '../../MediaObject/service';
import * as productLineConstants from '../constants';

import Form from './Form';

const { PATH } = productLineConstants;

const Edit = (props) => {
  const [ productLine, setProductLine ] = useState(productLineConstants.initialProductLine);
  const [ error, setError ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const { history, location, match } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newProductLine = {...productLine};

    newProductLine[target.name] = target.value;

    setProductLine(newProductLine);
  };

  const handleChangeNameField = (field, value) => {
    const newProductLine = {...productLine};

    newProductLine[field] = value;

    setProductLine(newProductLine);
  };

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      productLineService._delete(match.params.id).then(() => {
        setError('');
        setProductLine(productLineConstants.initialProductLine);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        setError(!!e && !!e.message ? e.message : e);
        setSuccess(false);
        setLoading(false);
      });
    }
  };

  const handleEdit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (productLine.logo instanceof File) {
        const uploadedFile = await mediaObjectService.createMediaObject();
        await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, productLine.logo);
        productLine.logo = uploadedFile.id;
      } else if (!!productLine.logo) {
        productLine.logo = productLine.logo.id;
      }
      if (!!productLine.brand && !!productLine.brand.value) {
        productLine.brand = productLine.brand.value;
      }
      const newProductLine = await productLineService.update(match.params.id, productLine);
      setSuccess(true);
      setError('');
     if (!!newProductLine.logo && !!newProductLine.logo.id) {
        const logo = await mediaObjectService.getById(newProductLine.logo.id);
        newProductLine.logo = logo;
      }
      if (!!newProductLine.brand && !!newProductLine.brand.name && !!newProductLine.brand.id) {
        newProductLine.brand = { value: newProductLine.brand.id, label: newProductLine.brand.name };
      }
      setProductLine(newProductLine);
      setLoading(false);
    } catch (e) {
      setError(!!e && !!e.message ? e.message : e);
      setSuccess(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadDatas = async () => {
      try {
        setLoading(true);
        const newProductLine = await productLineService.getById(match.params.id);

        if (!!newProductLine.logo && !!newProductLine.logo.id) {
          const logo = await mediaObjectService.getById(newProductLine.logo.id);
          newProductLine.logo = logo;
        }

        if (!!newProductLine.brand && !!newProductLine.brand.name && !!newProductLine.brand.id) {
          newProductLine.brand = { value: newProductLine.brand.id, label: newProductLine.brand.name };
        }

        setError('');
        setProductLine(newProductLine);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);  
      }
    };

    loadDatas();
  }, [match]);

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!productLine ? '#' + productLine.id : ''
  };

  return (
    <div className="d-flex flex-column">
      {success && (
        <div className="alert alert-success" role="status">
          Gamme mis à jour.
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      {productLine && (
        <Form
          onSubmit={handleEdit}
          values={productLine}
          onChange={handleChange}
          onChangeNameField={handleChangeNameField}
          className="form-product-line"
          loading={loading}
        />
      )}
      <div className="d-flex justify-content-between pb-5">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Edit;