import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import InputSearch from './InputSearch';

import { classificationService } from '../../Classification/service';
import * as classificationConstants from '../../Classification/constants';

const { API_PATH } = classificationConstants;

const InputSearchClassification = (props) => {
  const { catalog, className, field, value } = props;
  //const [ suggests, setSuggests ] = useState([]);
  const [ classifications, setClassifications ] = useState([]);
  const [ selected, setSelected ] = useState({});
  const [ loading, setLoading ] = useState(false);

  const classificationsSuggests = classifications.map((classification) => {
    return {
      label: classification.name,
      value: classification.id,
    };
  });

  const handleFocus = () => {
    if (classifications.length === 0 && Object.keys(selected).length === 0) {
      if (!!catalog && !!catalog.value) {
        const filters = { catalog: catalog.value };
        setLoading(true);
        classificationService.getAll(API_PATH, filters).then((newClassifications) => {
          setClassifications(newClassifications && newClassifications["hydra:member"]);
          setLoading(false);
        });
      }
    }
    if (classifications.length === 0 && classificationsSuggests.length === 0 && !!selected && !!selected.label && !!selected.value) {
      classificationsSuggests.push({
        label: selected.label,
        value: selected.value
      });
    }
  };

  const handleSearchChange = (field, value) => {
    const { catalog, onChange } = props;
    const filters = {
      name: value
    };

    if (typeof catalog === 'object') {
      Object.assign(filters, { catalog: catalog.value });
    }

    onChange(field, value);

    if (value.length > 2) {
      setLoading(true);
      classificationService.getAll(API_PATH, filters).then((newClassifications) => {
        setClassifications(newClassifications && newClassifications["hydra:member"]);
        setLoading(false);
      });
    } /* else {
      setClassifications([]);
    } */
  };

  const handleClear = (field) => {
    const { onClear } = props;

    onClear(field);
    //setClassifications([]);
    setLoading(true);
    classificationService.getAll().then((newClassifications) => {
      setClassifications(newClassifications && newClassifications["hydra:member"]);
      setLoading(false);
    });
  };

  const handleSelect = (field, value) => {
    const { onSelect } = props;

    setSelected(value);
    onSelect(field, value);
  };

  const selectClassName = classNames({
    'input-search-classification': true,
    [className]: !!className,
  });

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();

    if (mounted) {
      setLoading(true);
      const filters = {};

      if (typeof catalog === 'object') {
        Object.assign(filters, { catalog: catalog.value });
      }
      
      classificationService.getAll(API_PATH, filters, {
        signal: controller.signal
      }).then((newClassifications) => {
        setClassifications(newClassifications && newClassifications["hydra:member"]);
        setLoading(false);
      }).catch(e => {
        console.log('Error InputSearchClassification :', e)
      });;
    }

    return () => {
      mounted = false;
      controller?.abort();
    }
  }, [catalog]);

  //TODO: onRemove ?

  return (
    <InputSearch
      field={!!field ? field : "classification"}
      label="Classification"
      closeOnSelect
      value={value}
      suggests={classificationsSuggests}
      onClear={handleClear}
      onChange={handleSearchChange}
      onSelect={handleSelect}
      onFocus={handleFocus}
      className={selectClassName}
      loading={loading}
    />
  );
};

export default InputSearchClassification;