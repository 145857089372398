export const initialBanner = {
  name: '',
  bannerFiles: [],
};

export const TABLE_HEADERS_BANNERS = [
  {
    field: 'id',
    sortable: false,
    translation: 'Id',
  },
  {
    field: 'name',
    sortable: true,
    translation: 'Nom',
  },
    {
    field: 'files',
    sortable: false,
    translation: 'Images',
    className: 'text-center',
  },
  {
    field: 'actions',
    sortable: false,
    translation: 'Actions',
    colSpan: 3
  },
];

export const API_PATH = "api/banners";
export const PATH = "/templates/";
export const PATH_BANNER = "/templates/banners/";