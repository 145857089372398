import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import brandRoutes from './routes/brand';
import catalogRoutes from './routes/catalog';
import categoryRoutes from './routes/category';
import classificationRoutes from './routes/classification';
import laboratoryRoutes from './routes/laboratory';
import productRoutes from './routes/product';
import productLinesRoutes from './routes/productline';
import templatesRoutes from './routes/template';

import Layout from './Layout';
import HomePage from './Home/HomePage';
import LoginPage from './User/components/Login/LoginPage';

import { ConnectedUserProvider, ConnectedUserContext } from "./User/hooks/useConnectedUser";

const RouteApp = (props) => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <ConnectedUserProvider>
        {
          <ConnectedUserContext.Consumer>
            {([connectedUser]) => {
              if (connectedUser === null) {
                return (<Route path={path} component={LoginPage} />)
              }
              return (
                <Layout>
                  <Switch>
                    {brandRoutes}
                    {catalogRoutes}
                    {categoryRoutes}
                    {classificationRoutes}
                    {laboratoryRoutes}
                    {productRoutes}
                    { productLinesRoutes }
                    { templatesRoutes }
                    <Route exact path="/home" component={HomePage} />
                    <Route exact path="/">
                      <Redirect to="/home" /> 
                    </Route>
                    <Route render={() => <div className="not-found display-1">Page non trouvé</div>} />
                  </Switch>
                </Layout>
              )
            }}
          </ConnectedUserContext.Consumer>
        }
      </ConnectedUserProvider>
    <Route render={() => <div className="not-found">Page non trouvé</div>} />
  </Switch>
  );
};
  
export default RouteApp;