// @flow
import React, { type Node } from 'react';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';

import FormField from './FormField';
import IconCross from '../../icons/IconCross';
import IconSearch from '../../icons/IconSearch';
import Spinner from '../Spinner/Spinner';

import './styles/InputSearch.scss';

type Props = {
  className: string,
  closeOnSelect: boolean,
  disabled: boolean,
  errorLabel: string,
  field: string,
  label: string,
  name: string,
  placeholder: string,
  readonly: boolean,
  suggests: [],
  value: string | number,
  onChange: (field: string, value: string) => void,
  onClear: (field: string) => void,
  onFocus: () => void,
  onKeyPress: (event: Event) => void,
  //onSelect: (field: string, value: Option) => void,
  onSelect: (field: string, value: any) => void,
  validator: (field: string, value: string) => boolean,
};

type State = {
  isOpen: boolean,
};

export class InputSearch extends FormField<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  handleSelectValue = (value: Option): void => {
    const { field, onSelect, closeOnSelect } = this.props;
    onSelect(field, value);

    if (closeOnSelect) this.setState({ isOpen: false });
  };

  handleClickOutside = (): void => {
    this.setState({ isOpen: false });
  };

  handleFocus = (): void => {
    const { value, suggests, onFocus } = this.props;
    if (!!value || suggests.length > 0) this.setState({ isOpen: true });
    onFocus();
  };

  handleChange = (event: SyntheticInputEvent<EventTarget>): void => {
    const { field, onChange } = this.props;
    event.preventDefault();
    const { value: newValue } = event.target;
    onChange(field, newValue);
    this.setState({ isOpen: true });
  };

  clearValue = (): void => {
    const { field, onChange, onClear } = this.props;
    onChange(field, '');
    onClear(field);
    this.setState({ isOpen: false });
  };

  renderSuggests = (): Node | null => {
    const { suggests } = this.props;

    if (suggests.length === 0) {
      return null;
    }

    return (
      <div className="input-search-suggests scroll-custom">
        {suggests.map((suggest) => {
          const { value, label } = suggest;
          return (
            <div
              key={value}
              className="input-search-suggests-item"
              onClick={() => this.handleSelectValue(suggest)}
            >
              {label}
            </div>
          );
        })}
      </div>
    );
  };

  renderField = (): Node => {
    const {
      field,
      //label,
      name,
      //placeholder,
      readonly,
      value,
    } = this.props;

    //const placeholderText = placeholder || label;
    const newName = name || field;

    return (
      <div className="input-search-value">
        <input
          className="form-field-input"
          name={newName}
          //placeholder={placeholderText}
          type="text"
          value={value}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyPress={this.handleKeyPress}
          readOnly={readonly}
          autoComplete="off"
        />

        {value.length === 0 && (<IconSearch />)}
        {value.length > 0 && (<IconCross onClick={this.clearValue} />)}
      </div>
    );
  };

  render = (): Node => {
    const { isOpen } = this.state;
    const { className, label, loading, disabled, suggests, validator, field, value } = this.props;

    const isValid = validator(field, value);

    const inputClassName = classNames({
      'form-group': true,
      'form-field': true,
      'form-input-search': true,
      'is-disabled': disabled,
      'with-label': !!label,
      'is-error': !isValid,
      'is-open': isOpen && suggests.length > 0,
      [className]: !!className,
    });

    return (
      <div className={inputClassName} key='input-search'>
        <div className="input-search-wrapper">
          <div className="form-field-label">{label}</div>

          {loading && ( <div className="input-search-value"><Spinner /></div>)}

          {!loading && !disabled && this.renderField()}

          {!!disabled && this.renderDisabled()}

          {!disabled && isOpen && this.renderSuggests()}
        </div>

        <div className="form-field-label-container">
          {!isValid && this.renderError()}
        </div>
      </div>
    );
  };
}

InputSearch.defaultProps = {
  className: 'form-input-search',
  closeOnSelect: true,
  disabled: false,
  errorLabel: '',
  field: '',
  label: '',
  name: '',
  loading: false,
  placeholder: '',
  readonly: false,
  suggests: [],
  value: '',
  onChange: () => {},
  onClear: () => {},
  onFocus: () => {},
  onKeyPress: () => {},
  onSelect: () => {},
  validator: () => true,
};

export default onClickOutside(InputSearch);