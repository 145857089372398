import React from 'react';

import Button from '../../components/Button/Button';
import InputSearchMultipleBrands from '../../components/Input/InputSearchMultipleBrands';
import Spinner from '../../components/Spinner/Spinner';
import { default as FormComponent } from '../../components/Form/Form';

import '../styles/Form.scss';

const Form = (props) => {
  const {
    //brands,
    className,
    loading,
    values,
    onChange,
    onChangeNameField,
    onSubmit
  } = props;

  const handleDeleteLogo = () => {
    onChangeNameField('logo', null);
  };

  const handleChangeLogo = (event) => {
    onChangeNameField('logo', event.target.files[0]);
  };

  const handleInputSearchesRemove = (field, value) => {
    const valuesSelected = !!values[field] ? values[field] : [];
    const filteredValues = valuesSelected.filter((valueSelected) => valueSelected.value !== value.value);

    onChangeNameField(field, filteredValues);
  };

  const handleChangeBrands = (field, value) => {
    const newBrands = [...values.brands];
    if (typeof value === 'object') {
      newBrands.push(value);
      onChangeNameField(field, newBrands)
    }
  };
  
  const handleRemoveBrands = () => {
    onChangeNameField('brands', [])
  };

  let createdAtDisplay = undefined;
  if (!isNaN(Date.parse(values.createdAt))) {
    const createdAtDate = new Date(values.createdAt);
    createdAtDisplay = 'Laboratoire créé le : ' + createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAtDisplay = undefined;
  if (!isNaN(Date.parse(values.updatedAt))) {
    const updatedAtDate = new Date(values.updatedAt);
    updatedAtDisplay = 'Mis à jour le : ' + updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }

  return (
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-md-10 col-sm-12">
          <FormComponent onSubmit={onSubmit} className={className}>
            {
              loading
                ?
                  <Spinner className="d-flex align-items-center h-100 justify-content-center" />
                :
                  <>
                    <div className="form-group">
                      <label
                        htmlFor="laboratory_name"
                        className="form-control-label"
                      >
                        Nom
                      </label>
                      <input
                        type="text"
                        required
                        id='laboratory_name'
                        name="name"
                        className="form-control"
                        value={values.name}
                        onChange={onChange}
                      />
                      {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="phone_name"
                        className="form-control-label"
                      >
                        Téléphone
                      </label>
                      <input
                        type="text"
                        id='phone_name'
                        name="phone"
                        className="form-control"
                        value={values.phone}
                        onChange={onChange}
                      />
                      {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="website_name"
                        className="form-control-label"
                      >
                        Site web
                      </label>
                      <input
                        type="text"
                        id='website_name'
                        name="website"
                        className="form-control"
                        value={values.website}
                        onChange={onChange}
                      />
                      {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="address_name"
                        className="form-control-label"
                      >
                        Adresse
                      </label>
                      <input
                        type="text"
                        id='address_name'
                        name="address"
                        className="form-control"
                        value={values.address}
                        onChange={onChange}
                        disabled
                      />
                      {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
                    </div>

                    <InputSearchMultipleBrands
                      values={values.brands}
                      onChange={handleChangeBrands}
                      onSelect={handleChangeBrands}
                      onClear={handleRemoveBrands}
                      onRemove={handleInputSearchesRemove}
                      disabled
                    />

                    <div className="form-group">
                      <label
                        htmlFor="laboratory_logo"
                        className="form-control-label"
                      >
                        Image
                      </label>
                      <input
                        type="file"
                        id='laboratory_logo'
                        name="logo"
                        className="form-control"
                        //value={values.logo}
                        onChange={handleChangeLogo}
                      />
                      {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
                      {(values.logo instanceof File || (!!values.logo && !!values.logo.url)) && (
                        <div className="img-thumbnail mt-3 text-center">
                          <img className="mx-auto my-auto img-fluid img-thumbnail mb-3" alt='product_line-logo' src={
                            (values.logo instanceof File
                              ? URL.createObjectURL(values.logo)
                              : (
                                !!values.logo && !!values.logo.url 
                                  ? values.logo.url
                                  : ''
                                )
                              )
                          } />
                          <div>
                            <Button className="btn-error btn-delete mb-3" onClick={() => handleDeleteLogo()}>Supprimer</Button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-12 mb-3">
                      <div>{ createdAtDisplay }</div>
                      <div>{ updatedAtDisplay }</div>
                    </div>

                    <Button type="submit" className="btn-secondary" onClick={onSubmit}>
                      Enregistrer
                    </Button>
                  </>
            }
          </FormComponent>
        </div>
      </div>
    </div>
  );
};

export default Form;