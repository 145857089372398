import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { productService } from '../service';
import * as productConstants from '../constants';
import * as productHelpers from '../helpers';

import Form from './Form/Form';

const { PATH } = productConstants;

const Edit = (props) => {
  const [ product, setProduct ] = useState(productConstants.initialProduct);
  const [ error, setError ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const { history, location, match } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newProduct = {...product};

    newProduct[target.name] = target.value;

    setProduct(newProduct);
  };

  const handleChangeNameField = (field, value) => {
    const newProduct = {...product};

    newProduct[field] = value;

    setProduct(newProduct);
  };

  const handleChangeProduct = (newProduct) => {
    setProduct(newProduct);
  };

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      productService._delete(match.params.id).then(() => {
        setProduct(productConstants.initialProduct);
        setError('');
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);
      });
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = await productHelpers.parseProductOut({...product});
    productService.update(match.params.id, payload).then((newProduct) => {
      setSuccess(true);
      setError('');
      newProduct = productHelpers.parseProductIn(newProduct);
      setProduct(newProduct);
      setLoading(false);
    }).catch((e) => {
      //TODO: set initial prod ?
      setError(!!e && !!e.message ? e.message : e);
      setSuccess(false);
      setLoading(false);
    });
  };

  useEffect(() => {
    const loadDatas = async () => {
      try {
        setLoading(true);
        let newProduct = await productService.getById(match.params.id);
        newProduct = await productHelpers.parseProductIn(newProduct);
        //TODO: utile de deepMerge ? avant oui car pas tous les fields, maintenant a voir
        //setProduct(deepMerge(productConstants.initialProduct, newProduct));
        setError('');
        setProduct(newProduct);
        setLoading(false);
      } catch (e) {
        setProduct(productConstants.initialProduct);
        setError(!!e && !!e.message ? e.message : e);
        setSuccess(false);
        setLoading(false);  
      }
    };
    
    loadDatas();
  }, [match]);

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!product ? '#' + product.id : ''
  };

  return (
    <div className="d-flex flex-column">
      {success && (
        <div className="alert alert-success" role="status">
          Produit mis à jour.
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      {product && (
        <Form
          onSubmit={handleEdit}
          values={product}
          onChange={handleChange}
          className="form-product"
          onChangeNameField={handleChangeNameField}
          onChangeProduct={handleChangeProduct}
          loading={loading}
        />
      )}
      <div className="d-flex justify-content-between pb-5">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Edit;