import React, { useState } from 'react';

import ExpansionPanelFilters from '../../../components/ExpansionPanel/ExpansionPanelFilters';
import InputSearchBrand from '../../../components/Input/InputSearchBrand';
import InputSearchLaboratory from '../../../components/Input/InputSearchLaboratory';

const Filters = (props) => {
  const { filters, onSubmit } = props;
  const [ name, setName ] = useState(!!filters.name ? filters.name : '');
  const [ laboratory, setLaboratory ] = useState(!!filters.laboratory ? filters.laboratory : '');
  const [ brand, setBrand ] = useState(!!filters.brand ? filters.brand : '');

   const handleChangeBrand = (field, value) => {
    setBrand(value);
  };

  const handleRemoveBrand = () => {
    setBrand('');
  };

  const handleChangeLaboratory = (field, value) => {
    setLaboratory(value);
  };

  const handleRemoveLaboratory = () => {
    setLaboratory('');
  };

  const handleSubmit = () => {
    const filters = {};
    if (name !== '') {
      Object.assign(filters, { name: name.trim() });
    }
    if (laboratory !== '') {
      Object.assign(filters, { laboratory: laboratory });
    }
    if (brand !== '') {
      Object.assign(filters, { brand: brand });
    }

    onSubmit(filters);
  };

  const handleClear = () => {
    if (name !== '') setName('');
    if (laboratory !== '') handleRemoveLaboratory();
    if (brand !== '') handleRemoveBrand();

    onSubmit({});
  };

  return (
    <ExpansionPanelFilters 
      title='Filtres'
      active={false}
      onSubmit={handleSubmit}
      onClear={handleClear}
    >
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <InputSearchLaboratory
            //value={laboratory}
            value={typeof laboratory === 'string' ? laboratory : (laboratory && laboratory.label)}
            onChange={handleChangeLaboratory}
            onSelect={handleChangeLaboratory}
            onClear={handleRemoveLaboratory}
            field='laboratory'
          />
        </div>

        <div className="col-xs-12 col-md-6">
          <InputSearchBrand
            value={typeof brand === 'string' ? brand : (brand && brand.label)}
            onChange={handleChangeBrand}
            onSelect={handleChangeBrand}
            onClear={handleRemoveBrand}
            field='brand'
            laboratory={laboratory}
          />
        </div>

        <div className="col-xs-12 col-md-6">
          <div className="form-group">
            <label
              htmlFor="productLine_name"
              className="form-control-label"
            >
              Nom
            </label>
            <input
              type="text"
              id='productLine_name'
              name="name"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
          </div>
        </div>
      </div>
    </ExpansionPanelFilters>
  );
};

export default Filters;