import { trackPromise} from 'react-promise-tracker';

import { API_HOST } from "../config/config";
//https://github.com/cornflourblue/react-hooks-redux-registration-login-example/blob/master/src/_services/user.service.js

import Storage from '../storage';
//import * as userConstants from './constants';

//const { PATH } = userConstants;

//TODO: handle options for controller.abort in each function below ?

export const authHeader = () => {
  // return authorization header with jwt token
  let session = JSON.parse(localStorage.getItem('session'));

  if (session && session.token) {
    return { 'Authorization': 'Bearer ' + session.token };
  } else {
    return {};
  }
};

const login = (username, password) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };

  return fetch(`${API_HOST}authentication_token`, requestOptions)
    .then(handleResponse)
    .then(data => {
      //TODO: remove lines below (expires), use expires from API
      let date = new Date();
      date.setHours(date.getHours() + 1);
      //date.setSeconds( date.getSeconds() + 10 );

      const newData = { 
        ...data,
        expires: date.getTime(),
      };
      
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      Storage.set('session', newData);

      return newData.user;
    });
}

const logout = () => {
  // remove user from local storage to log user out
  Storage.remove('session');
}

const refreshToken = () => {
  const session = Storage.get('session');
  const { refresh_token } = session;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh_token })
  };

  return fetch(`${API_HOST}refresh_token`, requestOptions)
    .then(handleResponse)
    .then(data => {
      let date = new Date();
      date.setHours(date.getHours() + 1);
      //date.setSeconds( date.getSeconds() + 10 );

      const newSession = {
        ...session,
        ...data,
        expires: date.getTime(),
      };

      Storage.set('session', newSession);
    });  
};

/* const getAll = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${API_HOST}${PATH}`, requestOptions).then(handleResponse);
}; 

const getById = (id) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${API_HOST}${PATH}/${id}`, requestOptions).then(handleResponse);
}; 

const register = (user) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(`${API_HOST}${PATH}/register`, requestOptions).then(handleResponse);
};

const update = (user) => {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(`${API_HOST}${PATH}/${user.id}`, requestOptions).then(handleResponse);;
};

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${API_HOST}${PATH}/${id}`, requestOptions).then(handleResponse);
};  */

const handleResponse = (response) => {
  return response.text().then(text => {
    try {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        if (response.status === 401) {
          const session = Storage.get('session');
          if (!!session) {
            const { refresh_token } = session;
            if (!!refresh_token) {
              trackPromise(userService.refreshToken());
            } else {
              userService.logout();
              if (window.location.pathname !== '/') {
                window.location.reload(true);
              }
            }
          }
        }  else if (response.status === 404) {
          return Promise.reject({ message: 'Page non trouvé.'});
        } else if (response.status === 500) {
          return Promise.reject({ message: 'Erreur 500.'});
        }

        const error = (data && data.message && { message: data.message }) || (data && data.title && { message: data.title }) || { message: data["hydra:description"] } || { message: response.statusText };
        return Promise.reject(error);
      }

      return data;
    } catch (e) {
      //TODO: send e ?
      console.log('ERROR handleResponse', e)
      return Promise.reject('Error');
    }
  });
}

export const userService = {
  login,
  logout,
  /*register,
  getAll,
  getById,
  update,
  delete: _delete,*/
  handleResponse,
  refreshToken,
};