import React from 'react';

import Spinner from '../Spinner/Spinner';

import './styles/Modal.scss';

//TODO: create Modal.Header/Footer/Body, s'inspirer de react-bootstrap Modal
const Modal = (props) => {
  const { 
    children,
    loading,
    showHeader,
    showFooter,
    title,
    closeButtonHeader,
    onClose,
    onSave
  } = props;

  return (
    <div className="modal fade show d-flex justify-content-center" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          {
            showHeader && (
              <div className="modal-header">
                { title && (<h5 className="modal-title">{ title }</h5>) }
                {
                  closeButtonHeader && onClose && (
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  )
                }
              </div>
            )
          }
          <div className="modal-body d-flex justify-content-center">
            { children }
          </div>
          {
            showFooter && (
              <div className="modal-footer">
                { onSave && !loading && (<button type="button" className="btn btn-primary" onClick={onSave}>Enregistrer</button>) }
                { loading && onSave && (<button type="button" className="btn btn-primary"><Spinner /></button>) }
                { onClose && (<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>Fermer</button>) }
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  loading: false,
};

export default Modal;