import React from 'react';

import BlockContainer from '../../../components/BlockContainer/BlockContainer';
import InputSearchMultipleCatalog from '../../../components/Input/InputSearchMultipleCatalog';
import InputSearchMultipleClassification from '../../../components/Input/InputSearchMultipleClassification';
import InputSearchMultipleCategories from '../../../components/Input/InputSearchMultipleCategories';

const FormCategories = (props) => {
  const { 
    catalogs,
    categories,
    classifications,
    onChangeNameField,
    onRemoveCatalogs,
    onRemoveClassifications,
  } = props;

  const handleChangeCatalogs = (field, value) => {
    const newCatalogs = [...catalogs];
    if (typeof value === 'object') {
      newCatalogs.push(value);
      onChangeNameField(field, newCatalogs)
    }
  };

  const handleClearCatalogs = () => {
    onChangeNameField('catalogs', []);
  };

  const handleChangeClassifications = (field, value) => {
    const newClassifications = [...classifications];
    if (typeof value === 'object') {
      newClassifications.push(value);
      onChangeNameField(field, newClassifications)
    }
  };

  const handleClearClassifications = () => {
    onChangeNameField('classifications', []);
  };

  const handleRemoveSelectedCategories = (idsToRemove) => {
    let newCategories = [...categories];

    newCategories = newCategories.map((cat) => {
      return cat.filter((c) => {
        return idsToRemove.indexOf(c.value) === -1;
      });
    });

    onChangeNameField('categories', newCategories)
  };

  const handleChangeCategories = (field, value) => {
    const newCategories = [...categories];
    if (typeof value === 'object') {
      newCategories.push(value);
      onChangeNameField(field, newCategories)
    }
  };

  const handleSelectCategories = (field, value, index) => {
    const newCategories = [...categories];
    if (typeof value === 'object') {
      if (typeof newCategories[index] === 'undefined') {
        newCategories[index] = [];
      }
      newCategories[index].push(value);
      onChangeNameField(field, newCategories)
    }
  };

  const handleClearCategories = () => {
    onChangeNameField('categories', [])
  };

  return (
    <BlockContainer title="Catégories produit">
      <InputSearchMultipleCatalog
        //value={typeof values.catalog === 'string' ? values.catalog : (values.catalog && values.catalog.label)}
        values={catalogs}
        onChange={handleChangeCatalogs}
        onSelect={handleChangeCatalogs}
        onClear={handleClearCatalogs}
        onRemove={onRemoveCatalogs}
        field='catalogs'
      />

      {catalogs.length > 0 && (
        <InputSearchMultipleClassification
          //values={typeof values.classification === 'string' ? values.classification : (values.classification && values.classification.label)}
          values={classifications}
          onChange={handleChangeClassifications}
          onSelect={handleChangeClassifications}
          onClear={handleClearClassifications}
          onRemove={onRemoveClassifications}
          field='classifications'
          catalogs={catalogs}
        />
      )}

      {classifications.length > 0 && (
        <InputSearchMultipleCategories
          values={categories}
          onChange={handleChangeCategories}
          onSelect={handleSelectCategories}
          onClear={handleClearCategories}
          onRemove={handleRemoveSelectedCategories}
          label="Catégories parentes"
          classifications={classifications}
        />
      )}
    </BlockContainer>
  );
};

export default FormCategories;