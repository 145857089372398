import React from 'react';
import { Link } from 'react-router-dom';

import ActionsIconTable from '../../../components/ActionsIconTable/ActionsIconTable';
import * as classificationConstants from '../../../Classification/constants';

const { PATH: classificationPath } = classificationConstants;

const CustomList = (props) => {
  const {
    items: categories,
    onDelete,
  } = props;

  const dataBlock = categories && categories.map((item) => {
    let classificationLink = null;
    if (!!item.classification && !!item.classification.name && !!item.classification.id) {
      classificationLink = <Link to={`${classificationPath}show/${item.classification.id}`}>{item.classification.name}</Link>;
    }

    let parentCategory = null;
    if (!!item.parent && !!item.parent.name && !!item.parent.id) {
      parentCategory = <Link to={`show/${item.parent.id}`}>{item.parent.name}</Link>;
    }

    return (
      <tr key={'category-' + item["id"]} id={item["id"]}>
        <th scope="row">
          <Link to={`show/${encodeURIComponent(item["id"])}`}>
            {item["id"]}
          </Link>
        </th>
        <td>{item["name"]}</td>
        <td>{ parentCategory }</td>
        <td>{ classificationLink }</td>
        <ActionsIconTable
          item={item}
          onDelete={onDelete}
        />
      </tr>
    );
  });

  return (
    <>
      {
        dataBlock.length === 0
          ?
            (<tr>
              <td colSpan={10}>
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <span>Pas de résultat.</span>
                </div>
              </td>
            </tr>)
          :
            dataBlock
      }
    </>
  );
};

export default CustomList;