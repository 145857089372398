import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import InputSearch from './InputSearch';

import { laboratoryService } from '../../Laboratory/service';
import * as laboratoryConstants from '../../Laboratory/constants';

const { API_PATH } = laboratoryConstants;

//TODO: service fetch abort ?

const InputSearchLaboratory = (props) => {
  const { className, field, value } = props;
  //const [ suggests, setSuggests ] = useState([]);
  const [ laboratories, setLaboratories ] = useState([]);
  const [ selected, setSelected ] = useState({});
  const [ loading, setLoading ] = useState(false);

  const laboratoriesSuggests = laboratories.map((laboratory) => {
    return {
      label: laboratory.name,
      value: laboratory.id,
    };
  });

  const handleFocus = () => {
    if (laboratories.length === 0 && laboratoriesSuggests.length === 0 && !!selected && !!selected.label && !!selected.value) {
      laboratoriesSuggests.push({
        label: selected.label,
        value: selected.value
      });
    }
  };

  const handleSearchChange = (field, value) => {
    const { onChange } = props;
    const filters = { name: value };

    onChange(field, value);

    if (value.length > 2) {
      setLoading(true);
      laboratoryService.getAll(API_PATH, filters).then((newLaboratories) => {
        setLaboratories(newLaboratories && newLaboratories["hydra:member"]);
        setLoading(false);
      });
    } /* else {
      setLaboratories([]);
    } */
  };

  const handleClear = (field) => {
    const { onClear } = props;

    onClear(field);
    //setLaboratories([]);
    setLoading(true);
    laboratoryService.getAll().then((newLaboratories) => {
      setLaboratories(newLaboratories && newLaboratories["hydra:member"]);
      setLoading(false);
    });
  };

  const handleSelect = (field, value) => {
    const { onSelect } = props;

    setSelected(value);
    onSelect(field, value);
  };

  const selectClassName = classNames({
    'input-search-laboratory': true,
    [className]: !!className,
  });

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();

    if (mounted) {
      setLoading(true);
      laboratoryService.getAll(API_PATH, {}, {
        signal: controller.signal
      }).then((newLaboratories) => {
        setLaboratories(newLaboratories && newLaboratories["hydra:member"]);
        setLoading(false);
      }).catch(e => {
        console.log('Error InputSearchMultipleCategories :', e)
      });
    }

    return () => {
      mounted = false;
      controller?.abort();
    }
  }, []);

  //TODO: onRemove ?

  return (
    <InputSearch
      field={!!field ? field : "laboratory"}
      label="Laboratoire"
      closeOnSelect
      value={value}
      suggests={laboratoriesSuggests}
      onClear={handleClear}
      onChange={handleSearchChange}
      onSelect={handleSelect}
      onFocus={handleFocus}
      className={selectClassName}
      loading={loading}
    />
  );
};

export default InputSearchLaboratory;