import React from 'react';
import { Link } from 'react-router-dom';

import ActionsIconTable from '../../../components/ActionsIconTable/ActionsIconTable';

const CustomList = (props) => {
  const {
    items: catalogs,
    onDelete,
  } = props;

  const dataBlock = catalogs && catalogs.map((item) => {
    return (
      <tr key={'catalog-' + item["id"]} id={item.id}>
        <th scope="row">
          <Link to={`show/${encodeURIComponent(item["id"])}`}>
            {item["id"]}
          </Link>
        </th>
        <td>{item["name"]}</td>
        <ActionsIconTable
          item={item}
          onDelete={onDelete}
        />
      </tr>
    )
  });

  return (
    <>
      {
        dataBlock.length === 0
          ?
            (<tr>
              <td colSpan={10}>
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <span>Pas de résultat.</span>
                </div>
              </td>
            </tr>)
          :
            dataBlock
      }
    </>
  );
};

export default CustomList;