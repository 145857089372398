import React, { useState } from 'react';

import ExpansionPanelFilters from '../../../components/ExpansionPanel/ExpansionPanelFilters';
import InputSearchMultipleCatalog from '../../../components/Input/InputSearchMultipleCatalog';

import '../../styles/Filters.scss';

const Filters = (props) => {
  const { filters, onSubmit } = props;
  const [ name, setName ] = useState(!!filters.name ? filters.name : '');
  const [ catalogs, setCatalogs ] = useState(!!filters.catalogs ? filters.catalogs : []);

  const handleChangeCatalogs = (field, value) => {
    const newCatalogs = [...catalogs];

    if (typeof value === 'object') {
      newCatalogs.push(value);
      setCatalogs(newCatalogs)
    }
  };

  const handleClearCatalogs = () => {
    setCatalogs([]);
  };

  const handleRemoveCatalogs = (field, value) => {
    let newCatalogs = [ ...catalogs ];

    newCatalogs = newCatalogs.filter((catalog) => catalog.value !== value.value);

    setCatalogs(newCatalogs);
  };


  const handleSubmit = () => {
    const filters = {};
    if (name !== '') {
      Object.assign(filters, { name: name.trim() });
    }
    if (catalogs.length > 0) {
      Object.assign(filters, { catalogs });
    }

    onSubmit(filters);
  };

  const handleClear = () => {
    if (name !== '') setName('');
    if (catalogs.length > 0) setCatalogs([]);

    onSubmit({});
  };

  return (
    <ExpansionPanelFilters 
      title='Filtres'
      active={false}
      onSubmit={handleSubmit}
      onClear={handleClear}
      className="filters-classifications"
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <label
                htmlFor="classification_name"
                className="form-control-label"
              >
                Nom
              </label>
              <input
                type="text"
                id='classification_name'
                name="name"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
            </div>
          </div>
        
          <div className="col-xs-12 col-md-6">
            <InputSearchMultipleCatalog
              values={catalogs}
              onChange={handleChangeCatalogs}
              onSelect={handleChangeCatalogs}
              onClear={handleClearCatalogs}
              onRemove={handleRemoveCatalogs}
              field='catalogs'
            />
          </div>
        </div>
      </div>
    </ExpansionPanelFilters>
  );
};

export default Filters;