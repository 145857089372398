import React from "react";
import { Route } from "react-router-dom";

import Page from "../Template/Page.jsx";
import { default as CreateBanner } from "../Template/Banner/components/Create.jsx";
import { default as EditBanner } from "../Template/Banner/components/Edit.jsx";
import { default as ShowBanner } from "../Template/Banner/components/Show.jsx";

const routes = [
  <Route path="/templates/" component={Page} exact strict key="page" />,
  <Route path="/templates/banners/create" component={CreateBanner} exact strict key="create" />,
  <Route path="/templates/banners/edit/:id" component={EditBanner} exact key="edit" />,
  <Route path="/templates/banners/show/:id" component={ShowBanner} exact key="edit" />,
];

export default routes;
