import React from 'react';
import { Link } from 'react-router-dom';

import ActionsIconTable from '../../../components/ActionsIconTable/ActionsIconTable';
import * as catalogConstants from '../../../Catalog/constants';

const { PATH: catalogPath } = catalogConstants;

const CustomList = (props) => {
  const {
    items: classifications,
    onDelete,
  } = props;

  const dataBlock = classifications && classifications.map((item) => {
    let catalogLink = null;
    if (!!item.catalog && !!item.catalog.name && !!item.catalog.id) {
      catalogLink = <Link to={`${catalogPath}show/${item.catalog.id}`}>{item.catalog.name}</Link>;
    }

    return (
      <tr key={'classification-' + item["id"]} id={item.id}>
        <th scope="row">
          <Link to={`show/${encodeURIComponent(item["id"])}`}>
            {item["id"]}
          </Link>
        </th>
        <td>{item["name"]}</td>
        <td>{ catalogLink }</td>
        <ActionsIconTable
          item={item}
          onDelete={onDelete}
        />
      </tr>
    )
  });

  return (
    <>
      {
        dataBlock.length === 0
          ?
            (<tr>
              <td colSpan={10}>
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <span>Pas de résultat.</span>
                </div>
              </td>
            </tr>)
          :
            dataBlock
      }
    </>
  );
};

export default CustomList;