// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconSearch = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-search', className),
    onClick,
  };

  return (
    <svg viewBox="0 0 8.4666665 8.4666669" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" {...newProps}>
      <path fill="#000" d="M3.1739665.26561685c-1.60424345 0-2.9104147 1.30565991-2.9104167 2.90989984.000002 1.60424996 1.30617325 2.91041663 2.9104167 2.91041663.70749794 0 1.3563613-.25436512 1.861385-.6759276l2.71456305 2.71507953c.24999976.25000002.62517072-.12517067.3751707-.3751707L5.41000573 5.03483477c.4203102-.50466519.6738596-1.15298854.67386067-1.85931808C6.08386428 1.57127676 4.77820984.26561685 3.1739665.26561685zm0 .52916666c1.31826077.00001 2.38073165 1.06247322 2.38073324 2.38073318-.0000016 1.31827004-1.06247247 2.38124997-2.38073324 2.38124997-1.31826075 0-2.38124841-1.06297993-2.38125003-2.38124997C.7927181 1.85725673 1.85570575.79479351 3.1739665.79478351z" />
    </svg>
  );
};

IconSearch.defaultProps = {
  className: 'icon-search',
  onClick: () => {},
};

export default IconSearch;
