import qs from 'qs';

import { API_HOST } from "../config/config";
import * as laboratoriesConstants from './constants';

import { authHeader, userService } from '../User/service';

const { API_PATH } = laboratoriesConstants;

const getAll = (page = API_PATH, params = {}, options = {}) => {
  const requestOptions = {
    method: 'GET',
    headers: new Headers({ ...authHeader() }),
  };
  if (Object.keys(options).length > 0) {
    Object.assign(requestOptions, options);
  }
  let searchParams = '';
  if (Object.keys(params).length > 0) {
    searchParams = qs.stringify(params, { addQueryPrefix: true });
  }

  return fetch(`${API_HOST}${page}${searchParams}`, requestOptions).then(userService.handleResponse);
};

const getById = (id) => {
  const requestOptions = {
    method: 'GET',
    headers: new Headers({ ...authHeader() }),
  };

  return fetch(`${API_HOST}${API_PATH}/${id}`, requestOptions).then(userService.handleResponse);
};

// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = (id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: new Headers({ ...authHeader() }),
  };

  return fetch(`${API_HOST}${API_PATH}/${id}`, requestOptions).then(userService.handleResponse);
};

const create = (values) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...authHeader(),
    }),
    body: JSON.stringify(values)
  };

  return fetch(`${API_HOST}${API_PATH}`, requestOptions).then(userService.handleResponse);
};

const update = (id, values) => {
  const requestOptions = {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...authHeader(),
    }),
    body: JSON.stringify(values)
  };

  return fetch(`${API_HOST}${API_PATH}/${id}`, requestOptions).then(userService.handleResponse);
};

export const laboratoryService = {
  getAll,
  getById,
  create,
  update,
  _delete
};
