export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const TERTIARY = 'tertiary';
export const QUATERNARY = 'quaternary';

export const TAG_STYLES = [
  PRIMARY,
  SECONDARY,
  TERTIARY,
  QUATERNARY,
];
