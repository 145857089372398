import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { bannerService } from '../service';
import MediaObject from '../../../MediaObject/components/MediaObject';
import Modal from '../../../components/Modal/Modal';
import Spinner from '../../../components/Spinner/Spinner';
import Tag from '../../../components/Tag/Tag';

import * as bannerConstants from '../constants';
import * as tagConstants from '../../../components/Tag/constants';

import '../styles/Show.scss';

const { PATH, PATH_BANNER } = bannerConstants;

const Show = (props) => {
  const [ banner, setBanner ] = useState(bannerConstants.initialBanner);
  const [ error, setError ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const { history, location, match } = props;
  const [ modalPicture, setModalPicture ] = useState({});

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      bannerService._delete(match.params.id).then(() => {
        setError('');
        setBanner(bannerConstants.initialBanner);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        //TODO: setClassification initial ?
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    const loadDatas = async () => {
      try {
        setLoading(true);
        const newBanner = await bannerService.getById(match.params.id);
        setBanner(newBanner);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);  
      }
    };

    loadDatas();
  }, [match]);

  if (loading) {
    return (
      <div className="d-flex flex-column h-100">
        <Spinner className="d-flex align-items-center h-100 justify-content-center" />
      </div>
    );
  }

  let picturesBlock = <div className="mw-3rem"><Tag style={tagConstants.QUATERNARY} text="Non" /></div>;
  if (!!banner['bannerFiles'] && banner['bannerFiles'].length > 0) {
    picturesBlock = banner['bannerFiles'].map((picture, index) => {
      return (
        <div 
          onClick={() => setModalPicture(picture.mediaObject)}
          key={`media-object-${picture.name}-${index}`}
          className="d-inline-flex pb-3 pe-3"
        >
          <MediaObject logo={picture.mediaObject} />
        </div>
      );
    });
  }

  let createdAt = undefined;
  if (!isNaN(Date.parse(banner.createdAt))) {
    const createdAtDate = new Date(banner.createdAt);
    createdAt = createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAt = undefined;
  if (!isNaN(Date.parse(banner.updatedAt))) {
    const updatedAtDate = new Date(banner.updatedAt);
    updatedAt = updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!banner ? '#' + banner.id : ''
  };
  const paramsForEdit = {
    ...paramsForBack,
    pathname: `${PATH_BANNER}edit/${encodeURIComponent(banner["id"])}`
  };

  return (
    <div className="show-template-banner d-flex flex-column pb-3">
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}

      {
        Object.keys(modalPicture).length > 0 && (
          <Modal showFooter onClose={() => setModalPicture({})}>
            <MediaObject logo={modalPicture} />
          </Modal>
        )
      }

      {banner && (
        <table className="table table-responsive table-striped table-hover">
          <thead>
            <tr>
              <th>Champ</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Nom</th>
              <td key="classification_name">{banner["name"]}</td>
            </tr>
            <tr>
              <th scope="row">Images</th>
              <td>{ picturesBlock }</td>
            </tr>
            <tr>
              <th scope="row">Date de création</th>
              <td>{ createdAt }</td>
            </tr>
            <tr>
              <th scope="row">Date de modification</th>
              <td>{ updatedAt }</td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="d-flex justify-content-between mb-3 pb-3">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        {banner && (
          <Link to={paramsForEdit} className="btn btn-warning">
            Editer
          </Link>
        )}
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Show;