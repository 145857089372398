// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';

//import type { Option } from '@shared/Select/models';
import FormField from './FormField';
import InputSearch from './InputSearch';
import InputSearchMultipleList from './InputSearchMultipleList';

type Props = {
  className: string,
  disabled: boolean,
  errorLabel: string,
  field: string,
  label: string,
  loading: boolean,
  name: string,
  placeholder: string,
  readonly: boolean,
  suggests: [],
  values: any[],
  //values: Option[],
  onChange: (field: string, value: string) => void,
  onFocus: () => void,
  onKeyPress: (event: Event) => void,
  //onSelect: (field: string, value: Option) => void,
  onSelect: (field: string, value: any) => void,
  //onRemove: (field: string, value: Option) => void,
  onRemove: (field: string, value: any) => void,
  validator: (field: string, value: string) => boolean,
};

type State = {
  search: string,
};

export class InputSearchMultiple extends FormField<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { search: '' };
  }

  handleSearchChange = (field: string, value: string): void => {
    const { onChange } = this.props;
    this.setState({ search: value });
    onChange(field, value);
  };

  handleSelectValue = (field: string, value: Option): void => {
    const { onSelect, values } = this.props;
    const foundIndex = values.findIndex((selectedValue) => selectedValue.value === value.value);

    if (foundIndex === -1) {
      onSelect(field, value);
    }
  };

  handleRemoveValue = (value: Option): void => {
    const { field, onRemove, values } = this.props;
    const foundIndex = values.findIndex((selectedValue) => selectedValue.value === value.value);

    if (foundIndex !== -1) {
      onRemove(field, value);
    }
  };

  isValid = (): boolean => {
    const { isRequired, values } = this.props;
    if (isRequired) {
      return values.length > 0 || false;
    }

    return true;
  };

  render = (): Node => {
    const { search } = this.state;
    const {
      className,
      closeOnSelect,
      label,
      errorLabel,
      disabled,
      field,
      loading,
      name,
      placeholder,
      readonly,
      suggests,
      values,
    } = this.props;

    const inputClassName = classNames({
      'form-group': true,
      'form-field': true,
      'form-input-search-multiple': true,
      [className]: !!className,
    });

    return (
      <div className={inputClassName}>
        <InputSearch
          label={label}
          errorLabel={errorLabel}
          disabled={disabled}
          field={field}
          name={name}
          placeholder={placeholder}
          readonly={readonly}
          suggests={suggests}
          value={search}
          closeOnSelect={closeOnSelect}
          onChange={this.handleSearchChange}
          onFocus={this.handleFocus}
          onSelect={this.handleSelectValue}
          onKeyPress={this.handleKeyPress}
          validator={this.isValid}
          loading={loading}
        />
        <InputSearchMultipleList
          disabled={disabled}
          onRemove={this.handleRemoveValue}
          readonly={readonly}
          values={values}
        />
      </div>
    );
  };
}

InputSearchMultiple.defaultProps = {
  className: 'form-input-search-multiple',
  disabled: false,
  errorLabel: '',
  field: '',
  label: '',
  loading: false,
  name: '',
  placeholder: '',
  readonly: false,
  suggests: [],
  values: [],
  onChange: () => {},
  onFocus: () => {},
  onKeyPress: () => {},
  onRemove: () => {},
  onSelect: () => {},
  validator: () => true,
};

export default InputSearchMultiple;
