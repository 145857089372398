import React, { useState, useEffect } from 'react';
import Storage from '../../storage';

const ConnectedUserContext = React.createContext([Storage.getConnectedUser(), () => {}]);

const ConnectedUserProvider = (props) => {
  const [connectedUser, setConnectedUser] = useState(Storage.getConnectedUser());

  useEffect(() => {
    const session = Storage.get('session');
    if (connectedUser !== null && !!session && !!session.token) {
      let newSession = {
        ...session,
        user: connectedUser,
        token: session.token,
        expires: session.expires,
      };
      Storage.set('session', newSession);
      setConnectedUser(connectedUser);
    }
  }, [connectedUser]);

  return (
    <ConnectedUserContext.Provider value={[connectedUser, setConnectedUser]}>
      {props.children}
    </ConnectedUserContext.Provider>
  );
}

export { ConnectedUserContext, ConnectedUserProvider };