import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { mediaObjectService } from '../../../MediaObject/service';
import { productService } from '../../service';

import Form from './Form';
import IconSimpleArrow from '../../../icons/IconSimpleArrow';

import * as productConstants from '../../constants';

const { PATH } = productConstants;

const Create = (props) => {
  const [ bcbFiles, setBcbFiles ] = useState(productConstants.initialBcbFiles);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const { history } = props;

  const handleChange = (type, value) => {
    let newBcbFiles = _.cloneDeep(bcbFiles);

    newBcbFiles = newBcbFiles.map((bcbFile) => {
      if (bcbFile.type === type) {
        return value;
      }
      return bcbFile;
    });

    setBcbFiles(newBcbFiles);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let payload = _.cloneDeep(bcbFiles);
      let newBcbFiles = _.cloneDeep(bcbFiles);

      for (let i = 0; i < payload.length; i++) {
        if (payload[i].file instanceof File) {
          let uploadedFile = await mediaObjectService.createMediaObject();
          await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, payload[i].file);
          uploadedFile = {
            ...uploadedFile,
            originalName: payload[i].file.name,
            name: payload[i].file.name,
          };
          await mediaObjectService.updateMediaObject(uploadedFile.id, uploadedFile);
          payload[i].file = uploadedFile.id;
          const newBcbFile = await productService.createBcb(payload[i]);
          newBcbFiles = newBcbFiles.map((bcbFile) => {
            if (bcbFile.type === newBcbFile.type) {
              return newBcbFile;
            }
            return bcbFile;
          });
        } else if (!!payload[i.file] && !!payload[i].file.id) {
          payload[i].file = payload[i].file.id;
        } else {
          payload[i].file = {};
        }
      }

      setBcbFiles(newBcbFiles);
      
      setError('');
      setLoading(false);
      //TODO: redirect?
    } catch (e) {
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    }
  };

  const handleDelete = async (itemId) => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      try {
        setLoading(true);
        await productService.deleteBcb(itemId);

        const newBcbFiles = await productService.getAllBcb();
        let initialBcbFiles = _.cloneDeep(productConstants.initialBcbFiles);

        if (newBcbFiles["hydra:member"].length > 0) {
          const bcbFileEan = newBcbFiles["hydra:member"].find((bcbFile) => bcbFile.type === 'EAN');
          const bcbFileCip = newBcbFiles["hydra:member"].find((bcbFile) => bcbFile.type === 'CIP');
          initialBcbFiles = await initialBcbFiles.map((bcbFile) => {
            if (bcbFile.type === 'EAN' && typeof bcbFileEan !== 'undefined') {
              return bcbFileEan;
            } else if (bcbFile.type === 'CIP' && typeof bcbFileCip !== 'undefined') {
              return bcbFileCip;
            } else {
              return bcbFile;
            }
          });
        }
        setBcbFiles(initialBcbFiles);
        setError('');
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const loadDatas = async () => {
      try {
        setLoading(true);
        const newBcbFiles = await productService.getAllBcb();

        let initialBcbFiles = _.cloneDeep(bcbFiles);
        if (newBcbFiles["hydra:member"].length > 0) {
          const bcbFileEan = newBcbFiles["hydra:member"].find((bcbFile) => bcbFile.type === 'EAN');
          const bcbFileCip = newBcbFiles["hydra:member"].find((bcbFile) => bcbFile.type === 'CIP');
          initialBcbFiles = await initialBcbFiles.map((bcbFile) => {
            if (bcbFile.type === 'EAN' && typeof bcbFileEan !== 'undefined') {
              return bcbFileEan;
            } else if (bcbFile.type === 'CIP' && typeof bcbFileCip !== 'undefined') {
              return bcbFileCip;
            } else {
              return bcbFile;
            }
          });
        }
        setBcbFiles(initialBcbFiles);
        setError('');
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);  
      }
    };

    loadDatas();
  }, []);

  return (
    <div className="d-flex flex-column">
      <div className="go-back" onClick={() => history.push(PATH)}>
        <IconSimpleArrow />
        <span>Retour</span>
      </div>

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}

      <Form
        className="mb-5 form-template-bcb-files"
        onSubmit={handleSubmit}
        values={bcbFiles}
        onChange={handleChange}
        loading={loading}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default Create;