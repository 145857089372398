import React, { useState } from 'react';

import ExpansionPanelFilters from '../../../components/ExpansionPanel/ExpansionPanelFilters';
import InputSearchBrand from '../../../components/Input/InputSearchBrand';
import InputSearchLaboratory from '../../../components/Input/InputSearchLaboratory';
import InputSearchProductLine from '../../../components/Input/InputSearchProductLine';

const Filters = (props) => {
  const { filters, onSubmit } = props;
  const [ name, setName ] = useState(!!filters.name ? filters.name : '');
  const [ cip7, setCip7 ] = useState(!!filters.CIP7 ? filters.CIP7 : '');
  const [ ean13, setEan13 ] = useState(!!filters.EAN13 ? filters.EAN13 : '');
  const [ acl13, setAcl13 ] = useState(!!filters.ACL13 ? filters.ACL13 : '');
  const [ laboratory, setLaboratory ] = useState(!!filters.laboratory ? filters.laboratory : '');
  const [ brand, setBrand ] = useState(!!filters.brand ? filters.brand : '');
  const [ productLine, setProductLine ] = useState(!!filters.productLine ? filters.productLine : '');

  const handleChangeProductLine = (field, value) => {
    setProductLine(value);
  };

  const handleRemoveProductLine = () => {
    setProductLine('');
  };

  const handleChangeBrand = (field, value) => {
    setBrand(value);
  };

  const handleRemoveBrand = () => {
    setBrand('');
  };

  const handleChangeLaboratory = (field, value) => {
    setLaboratory(value);
  };

  const handleRemoveLaboratory = () => {
    setLaboratory('');
  };

  const handleSubmit = () => {
    const filters = {};
    if (name !== '') {
      Object.assign(filters, { name: name.trim() });
    }
    if (cip7 !== '') {
      Object.assign(filters, { CIP7: cip7.trim() });
    }
    if (ean13 !== '') {
      Object.assign(filters, { EAN13: ean13.trim() });
    }
    if (acl13 !== '') {
      Object.assign(filters, { ACL13: acl13.trim() });
    }
    if (laboratory !== '') {
      Object.assign(filters, { laboratory: laboratory });
    }
    if (brand !== '') {
      Object.assign(filters, { brand: brand });
    }
    if (productLine !== '') {
      Object.assign(filters, { productLine: productLine });
    }

    onSubmit(filters);
  };

  const handleClear = () => {
    if (name !== '') setName('');
    if (cip7 !== '') setCip7('');
    if (ean13 !== '') setEan13('');
    if (acl13 !== '') setAcl13('');
    if (laboratory !== '') handleRemoveLaboratory();
    if (brand !== '') handleRemoveBrand();
    if (productLine !== '') handleRemoveProductLine();

    onSubmit({});
  };

  return (
    <ExpansionPanelFilters 
      title='Filtres'
      active={false}
      onSubmit={handleSubmit}
      onClear={handleClear}
    >
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <InputSearchLaboratory
              //value={laboratory}
              value={typeof laboratory === 'string' ? laboratory : (laboratory && laboratory.label)}
              onChange={handleChangeLaboratory}
              onSelect={handleChangeLaboratory}
              onClear={handleRemoveLaboratory}
              field='laboratory'
            />
          </div>

          <div className="col-xs-12 col-md-4">
            <InputSearchBrand
              value={typeof brand === 'string' ? brand : (brand && brand.label)}
              onChange={handleChangeBrand}
              onSelect={handleChangeBrand}
              onClear={handleRemoveBrand}
              field='brand'
              laboratory={laboratory}
            />
          </div>

          <div className="col-xs-12 col-md-4">
            <InputSearchProductLine
              value={typeof productLine === 'string' ? productLine : (productLine && productLine.label)}
              onChange={handleChangeProductLine}
              onSelect={handleChangeProductLine}
              onClear={handleRemoveProductLine}
              field='productLine'
              brand={brand}
              className="col-xs-12 col-md-4"
            />
          </div>
        </div>

      <div className="row">
        <div className="col-xs-12 col-md-3">
          <div className="form-group">
            <label
              htmlFor="product_name"
              className="form-control-label"
            >
              Nom
            </label>
            <input
              type="text"
              id='product_name'
              name="name"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
          </div>
        </div>

        <div className="col-xs-12 col-md-3">
          <div className="form-group">
            <label
              htmlFor="product_cip7"
              className="form-control-label"
            >
              CIP7
            </label>
            <input
              type="text"
              id='product_cip7'
              name="cip7"
              className="form-control"
              value={cip7}
              onChange={(e) => setCip7(e.target.value)}
            />
            {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
          </div>
        </div>

        <div className="col-xs-12 col-md-3">
          <div className="form-group">
            <label
              htmlFor="product_ean13"
              className="form-control-label"
            >
              EAN13
            </label>
            <input
              type="text"
              id='product_ean13'
              name="ean13"
              className="form-control"
              value={ean13}
              onChange={(e) => setEan13(e.target.value)}
            />
            {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
          </div>
        </div>

        <div className="col-xs-12 col-md-3">
          <div className="form-group">
            <label
              htmlFor="product_acl13"
              className="form-control-label"
            >
              ACL13
            </label>
            <input
              type="text"
              id='product_acl13'
              name="acl13"
              className="form-control"
              value={acl13}
              onChange={(e) => setAcl13(e.target.value)}
            />
            {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
          </div>
        </div>
      </div>

    </ExpansionPanelFilters>
  );
};

export default Filters;