import React from 'react';

import LoginForm from './LoginForm';

const LoginPage = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};

export default LoginPage;