import React from 'react';
import { Link } from 'react-router-dom';

import ActionsIconTable from '../../../components/ActionsIconTable/ActionsIconTable';
import MediaObject from '../../../MediaObject/components/MediaObject';

import * as productLinesConstants from '../../../ProductLine/constants';
import * as laboratoryConstants from '../../../Laboratory/constants';

const { PATH: productLinesPath } = productLinesConstants;
const { PATH: laboratoryPath } = laboratoryConstants;

const CustomList = (props) => {
  const {
    items: brands,
    onDelete,
  } = props;

  const dataBlock = brands && brands.map((item, index) => {
    let laboratoryLink = null;
    if (!!item.laboratory && !!item.laboratory.name && !!item.laboratory.id) {
      laboratoryLink = <Link to={`${laboratoryPath}show/${item.laboratory.id}`}>{item.laboratory.name}</Link>;
    }

    let productLineLinks = null;
    if (!!item.productLines && item.productLines.length > 0) {
      productLineLinks = item.productLines.map((productLine, index) => {
        return (
          <div key={`productLine-${productLine.id}-${index}`}>
            <Link to={`${productLinesPath}show/${productLine.id}`}>
              {productLine.name}
            </Link>
          </div>
        );
      });
    }

    return (
      <tr key={'brand-' + item["id"] + '-' + index} id={item.id}>
        <th scope="row">
          <Link to={`show/${encodeURIComponent(item["id"])}`}>
            {item["id"]}
          </Link>
        </th>
        <td>{item["name"]}</td>
        <td>{ laboratoryLink }</td>
        <td>{ productLineLinks }</td>
        <td>
          <div className="center-logo">
            <MediaObject logo={item["logo"]} />
          </div>
        </td>
        <ActionsIconTable
          item={item}
          onDelete={onDelete}
        />
      </tr>
    )
  });

  return (
    <>
      {
        dataBlock.length === 0
          ?
            (<tr>
              <td colSpan={10}>
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <span>Pas de résultat.</span>
                </div>
              </td>
            </tr>)
          :
            dataBlock
      }
    </>
  );
};

export default CustomList;