import React from 'react';

import './styles/Home.scss';

const HomePage = () => {
  return (
    <div className="home display-1">Accueil</div>
  );
};

export default HomePage;