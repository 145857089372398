import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { categoryService } from '../service';
import * as categoryConstants from '../constants';

import Spinner from '../../components/Spinner/Spinner';

const { PATH } = categoryConstants;

const Show = (props) => {
  const [ category, setCategory ] = useState(categoryConstants.initialCategory);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const { history, location, match } = props;

  const renderChildrenLinks = (categories, descendance) => {
    const links = [];
    for (let i = 0; i < categories.length; i++) {
      links.push(
        <div className='d-block' key={`${categories[i].id}-${descendance}`}>
          <span>
            { `${'-'.repeat(descendance)}> `}
            <Link to={`${PATH}show/${encodeURIComponent(categories[i].id)}`}>
              {categories[i].name}
            </Link>
          </span>
        </div>
      );
      if (categories[i].categories.length > 0) {
        links.push(renderChildrenLinks(categories[i].categories, ++descendance));
      }
    }

    return links;
  };


  const renderLinks = (categories) => {
    const links = [];
    let descendance = 1;
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].categories.length > 0) {
        links.push(renderChildrenLinks(categories[i].categories, descendance));
      }
      links.push(
        <Link key={`${categories[i].id}-${descendance}`} className="d-block" to={`${PATH}show/${encodeURIComponent(categories[i].id)}`}>
          {categories[i].name}
        </Link>
      );
    }
    return links.reverse();
  };

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      categoryService._delete(match.params.id).then(() => {
        setError('');
        setCategory(categoryConstants.initialCategory);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        //TODO: setCategory initial ?
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    categoryService.getById(match.params.id).then((newCategory) => {
      setError('');
      setCategory(newCategory);
      setLoading(false);
    }).catch((e) => {
      //TODO: setCategory initial ?
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    });
  }, [match]);

  if (loading) {
    return (
      <div className="d-flex flex-column h-100">
        <Spinner className="d-flex align-items-center h-100 justify-content-center" />
      </div>
    );
  }

  let createdAt = undefined;
  if (!isNaN(Date.parse(category.createdAt))) {
    const createdAtDate = new Date(category.createdAt);
    createdAt = createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAt = undefined;
  if (!isNaN(Date.parse(category.updatedAt))) {
    const updatedAtDate = new Date(category.updatedAt);
    updatedAt = updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!category ? '#' + category.id : ''
  };
  const paramsForEdit = {
    ...paramsForBack,
    pathname: `${PATH}edit/${encodeURIComponent(category["id"])}`
  };

  //TODO: hide parent when classification is not empty ? hide classification when parent is not empty ?

  return (
    <div className="d-flex flex-column pb-3">
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}
      {category && (
        <table className="table table-responsive table-striped table-hover">
          <thead>
            <tr>
              <th>Champ</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Nom</th>
              <td>{category["name"]}</td>
            </tr>
            <tr>
              <th scope="row">Classification</th>
              <td>{!!category["classification"] && !!category["classification"].name && (
                <Link to={`/classifications/show/${category["classification"].id}`}>{ category["classification"].name }</Link>
              )}</td>
            </tr>
            <tr>
              <th scope="row">Parent</th>
              <td>{!!category["parent"] && !!category["parent"].name && (
                <Link to={`${PATH}show/${category["parent"].id}`}>{ category["parent"].name }</Link>
              )}</td>
            </tr>
            <tr>
              <th scope="row">Enfants</th>
              <td>
                { !!category['categories'] && category['categories'].length > 0 && renderLinks(category['categories']) }
              </td>
            </tr>
            <tr>
              <th scope="row">Date de création</th>
              <td>{ createdAt }</td>
            </tr>
            <tr>
              <th scope="row">Date de modification</th>
              <td>{ updatedAt }</td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="d-flex justify-content-between mb-3 pb-3">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        {category && (
          <Link to={paramsForEdit} className="btn btn-warning">
            Editer
          </Link>
        )}
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Show;