// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconCircleCross = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames({ icon: true, 'icon-circle-cross': true, [className]: !!className }),
    onClick,
  };
  return (
    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...newProps}>
      <path fillRule="evenodd" d="M5 0a5 5 0 110 10A5 5 0 015 0zM2.622 2.622l-.029.031a.415.415 0 00.029.556L4.412 5l-1.79 1.791a.415.415 0 000 .587l.031.029a.415.415 0 00.556-.029L5 5.588l1.791 1.79a.415.415 0 00.587 0l.029-.031a.415.415 0 00-.029-.556L5.588 5l1.79-1.791a.415.415 0 000-.587l-.031-.029a.415.415 0 00-.556.029L5 4.412l-1.791-1.79a.415.415 0 00-.587 0z" />
    </svg>
  );
};

IconCircleCross.defaultProps = {
  className: 'icon-circle-cross',
  onClick: () => {},
};

export default IconCircleCross;
