// @flow

import type { DictionaryObject } from '@core/models';

class UrlHelpers {
  static getOrigin(): string {
    return window.location.origin;
  }

  static getCurrentPath(): string {
    return window.location.pathname;
  }

  static isLocalUrl(url: ?string): boolean {
    if (!url) return false;
    const host = UrlHelpers.getOrigin();
    return !!url && (url.startsWith(host) || !url.startsWith('http'));
  }

  static parseQueryStringParameters(path: string): DictionaryObject {
    const query = path.split('?')[1];
    if (!query) return {};
    return query
      .split('&')
      .reduce((params, current) => {
        const [key, value] = current.split('=');
        return {
          ...params,
          [key]: value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '',
        };
      }, {});
  }

  static getUrlWithParams = (location: string, params: DictionaryObject): string => {
    const pureLocation = location && location.length > 0
      ? location.split('?')[0]
      : '';
    const stringifyParams = UrlHelpers.getQueryStringParametersFromObject(params);
    return stringifyParams.length > 0
      ? `${pureLocation}?${stringifyParams}`
      : location;
  }

  static getQueryStringParametersFromObject(params: Object): string {
    return Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');
  }

  static redirectToExternalLink(path: string) {
    window.location.href = path;
  }

  static redirectToExternalLinkInNewTab(path: string) {
    window.open(path, '_blank');
  }

  static stringifyParametersAsQuery(params: DictionaryObject): string {
    return Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  }

  static setLocationHash(hash: string) {
    window.location.hash = hash;
  }
}

export default UrlHelpers;
