// @flow
import React from 'react';
import classNames from 'classnames';

import './styles/Spinner.scss';

type Props = {
  className: string,
  style: string,
};

const styles = [
  'primary',
];

export const Spinner = (props: Props) => {
  const { className, style } = props;

  const spinnerStyle = styles.includes(style) ? style : styles[0];
  const spinnerClassName = classNames('spinner', `spinner-${spinnerStyle}`, className);

  return (
    <div className={spinnerClassName}>
      <span className="spinner-icon" />
    </div>
  );
};

Spinner.defaultProps = {
  className: 'spinner',
  style: 'primary',
};

export default Spinner;
