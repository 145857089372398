// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconWarning = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames({
      icon: true,
      'icon-warning': true,
      [className]: !!className,
    }),
    onClick,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 21 18.031" {...newProps}>
      <path d="M0 18.022L10.5.003 21 18.022H0m19.27-.99L10.5 1.984 1.73 17.032h17.54M10 11.088V7.126h1v3.962h-1m0 1.981h1v1.981h-1v-1.981z" />
    </svg>
  );
};

IconWarning.defaultProps = {
  className: 'icon-warning',
  onClick: () => {},
};

export default IconWarning;
