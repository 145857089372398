import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { brandService } from '../service';
import { mediaObjectService } from '../../MediaObject/service';
import * as brandConstants from '../constants';

import Form from './Form';

const { PATH } = brandConstants;

const Edit = (props) => {
  const [ brand, setBrand ] = useState(brandConstants.initialBrand);
  const [ error, setError ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const { history, match, location } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newBrand = {...brand};

    newBrand[target.name] = target.value;

    setBrand(newBrand);
  };

  const handleChangeNameField = (field, value) => {
    const newBrand = {...brand};

    newBrand[field] = value;

    setBrand(newBrand);
  };


  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      brandService._delete(match.params.id).then(() => {
        setError('');
        setBrand(brandConstants.initialBrand);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        setError(!!e && !!e.message ? e.message : e);
        setSuccess(false);
        setLoading(false);
      });
    }
  };

  const handleEdit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (brand.logo instanceof File) {
        const uploadedFile = await mediaObjectService.createMediaObject();
        await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, brand.logo);
        brand.logo = uploadedFile.id;
      } else if (!!brand.logo) {
        brand.logo = brand.logo.id;
      }
      if (!!brand.laboratory && !!brand.laboratory.value) {
        brand.laboratory = brand.laboratory.value;
      } else {
        delete brand.laboratory;
      }
      if (!!brand.productLines && brand.productLines.length > 0) {
        delete brand.productLines;
      }
      const newBrand = await brandService.update(match.params.id, brand);
      setSuccess(true);
      setError('');
      if (!!newBrand.logo && !!newBrand.logo.id) {
        const logo = await mediaObjectService.getById(newBrand.logo.id);
        newBrand.logo = logo;
      }
      if (!!newBrand.laboratory && !!newBrand.laboratory.name && !!newBrand.laboratory.id) {
        newBrand.laboratory = { label: newBrand.laboratory.name, value: newBrand.laboratory.id };
      }
      setBrand(newBrand);
      setLoading(false);
    } catch (e) {
      setError(!!e && !!e.message ? e.message : e);
      setSuccess(false);
      setLoading(false);
    }
  };

  useEffect(() => {
     const loadDatas = async () => {
      try {
        setLoading(true);
        const newBrand = await brandService.getById(match.params.id);

        if (!!newBrand.logo && !!newBrand.logo.id) {
          const logo = await mediaObjectService.getById(newBrand.logo.id);
          newBrand.logo = logo;
        }

        if (!!newBrand.laboratory && !!newBrand.laboratory.name && !!newBrand.laboratory.id) {
          newBrand.laboratory = { value: newBrand.laboratory.id, label: newBrand.laboratory.name };
        } else {
          newBrand.laboratory = '';
        }

        setError('');
        setBrand(newBrand);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);  
      }
    };

    loadDatas();
  }, [match]);

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!brand ? '#' + brand.id : ''
  };

  return (
    <div className="d-flex flex-column">
      {success && (
        <div className="alert alert-success" role="status">
          Marque mis à jour.
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      {brand && (
        <Form
          className="form-brand"
          onSubmit={handleEdit}
          values={brand}
          onChange={handleChange}
          onChangeNameField={handleChangeNameField}
          loading={loading}
        />
      )}
      <div className="d-flex justify-content-between pb-5">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Edit;