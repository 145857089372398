// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => {},
};

const IconClassification = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-classification', className),
    onClick,
  };

  return (
    <svg enableBackground="new 0 0 64 64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...newProps}>
      <g>
        <path d="m35 19c0-8.822-7.178-16-16-16s-16 7.178-16 16 7.178 16 16 16 16-7.178 16-16zm-30 0c0-7.72 6.28-14 14-14s14 6.28 14 14-6.28 14-14 14-14-6.28-14-14z"/>
        <circle cx="19" cy="19" r="1"/>
        <path d="m31 19c0-6.617-5.383-12-12-12s-12 5.383-12 12 5.383 12 12 12 12-5.383 12-12zm-4-3c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm-16 6c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm5-3c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3z"/>
        <circle cx="11" cy="19" r="1"/>
        <circle cx="27" cy="19" r="1"/>
        <path d="m16.347 36.782c.299.708.493 1.455.586 2.218h4.135c.093-.763.287-1.51.586-2.218-.869.129-1.75.218-2.654.218s-1.785-.089-2.653-.218z"/>
        <circle cx="19" cy="56" r="1"/>
        <path d="m21.199 41h-4.379l-2.723 13.613c-.065.323-.097.653-.097.981v.406c0 2.757 2.243 5 5 5s5-2.243 5-5v-.406c0-.328-.032-.658-.097-.981zm-2.199 18c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z"/>
        <path d="m60 47h-14c-.552 0-1 .448-1 1v12c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-12c0-.552-.448-1-1-1zm-13 6h8v2h-8zm12 6h-12v-2h12zm0-4h-2v-2h2zm0-4h-12v-2h12z"/>
        <path d="m60 25h-14c-.552 0-1 .449-1 1v12c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-12c0-.551-.448-1-1-1zm-5 12h-8v-2h8zm4 0h-2v-2h2zm0-4h-12v-2h12zm0-4h-12v-2h12z"/>
        <path d="m60 3h-14c-.552 0-1 .449-1 1v12c0 .551.448 1 1 1h14c.552 0 1-.449 1-1v-12c0-.551-.448-1-1-1zm-13 6h2v2h-2zm12 6h-12v-2h12zm0-4h-8v-2h8zm0-4h-12v-2h12z"/>
        <path d="m34.878 27.464c-.325.606-.694 1.183-1.083 1.745l3.791 3.791h5.414v-2h-4.586z"/>
        <path d="m27.549 34.809c-.59.32-1.198.611-1.826.864l12.739 19.327h4.538v-2h-3.462z"/>
        <path d="m35.584 12.002c.288.679.519 1.387.723 2.105l3.107-3.107h3.586v-2h-4.414z"/>
      </g>
    </svg>
  );
};

IconClassification.defaultProps = {
  className: 'icon-classification',
  onClick: () => {},
};

export default IconClassification;
