import React, { useState } from 'react';

import { default as GenericList } from '../../../components/List/List';

import { productLineService } from '../../service';

import Filters from './Filters';
import CustomList from './CustomList';
import * as productLineConstants from '../../constants';

import '../../styles/List.scss';

const {
  API_PATH,
  PATH,
  TABLE_HEADERS
} = productLineConstants;

const defaultParams = {
  itemsPerPage: 10,
  page: 1,
  order: {
    name: 'asc',
  }
};

const Page = () => {
  const [ error, setError ] = useState('');

  return (
    <GenericList
      className="product-lines"
      config={
        {
          pathForButtons: PATH,
          PATH: PATH,
          API_PATH: API_PATH,
          service: productLineService,
          tableHeaders: TABLE_HEADERS,
          defaultParams: defaultParams,
        }
      }
      errorPairs={[error, setError]}
    >
      <Filters />
      <CustomList />
    </GenericList>
  );
};

export default Page;