import React, { useEffect, useContext, useState } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { usePromiseTracker } from "react-promise-tracker";

import Storage from './storage';

import IconLogOut from './icons/IconLogOut';
import Menu from './Menu/Menu';
import Spinner from './components/Spinner/Spinner';
import { userService } from './User/service';

import { ConnectedUserContext } from "./User/hooks/useConnectedUser";

const Layout = (props) => {
  const { children, history } = props;
  const session = Storage.get('session');
  const [, setConnectedUser] = useContext(ConnectedUserContext);
  const [ loading, setLoading ] = useState(false);
  const { promiseInProgress } = usePromiseTracker();

  const handleLogout = () => {
    Storage.remove('session');
    setConnectedUser(null);
    history.push('/');
  };

  useEffect(() => {
    const handleSession = async () => {
      if (session !== null) {
        if (session.expires < Date.now()) {
          if (!!session.refresh_token) {
            try {
              setLoading(true);
              await userService.refreshToken();
              setLoading(false);
            } catch (e) {
              setLoading(false);
              Storage.remove('session');
              setConnectedUser(null);
              return history.push('/');
            }
          } else {
            Storage.remove('session');
            setConnectedUser(null);
            return history.push('/');
          }
        }
      }
    };
    
    handleSession();
  }, [session, history, setConnectedUser]);

  //TODO: change field by PATH from many constants ?
  const titles = [
    { '/brands/': 'Liste des marques', },
    { '/brands/show/:id': 'Visualisation marque', },
    { '/brands/edit/:id': 'Édition marque', },
    { '/brands/create': 'Création marque', },

    { '/catalogs/': 'Liste des catalogues', },
    { '/catalogs/show/:id': 'Visualisation catalogue', },
    { '/catalogs/edit/:id': 'Édition catalogue', },
    { '/catalogs/create': 'Création catalogue', },

    { '/categories/': 'Liste des catégories', },
    { '/categories/show/:id': 'Visualisation catégorie', },
    { '/categories/edit/:id': 'Édition catégorie', },
    { '/categories/create': 'Création catégorie', },

    { '/classifications/': 'Liste des classifications', },
    { '/classifications/show/:id': 'Visualisation classification', },
    { '/classifications/edit/:id': 'Édition classification', },
    { '/classifications/create': 'Création classification', },

    { '/laboratories/': 'Liste des laboratoires', },
    { '/laboratories/show/:id': 'Visualisation laboratoire', },
    { '/laboratories/edit/:id': 'Édition laboratoire', },
    { '/laboratories/create': 'Création laboratoire', },

    { '/products/': 'Liste des produits', },
    { '/products/edit/:id': 'Édition produit', },
    { '/products/show/:id': 'Visualisation produit', },
    { '/products/create': 'Création produit', },
    { '/products/imports': 'Liste des imports', },
    
    { '/products/bcb/': 'Liste BCB', },
    { '/products/bcb/create': 'Import BCB', },

    { '/product_lines/': 'Liste des gammes', },
    { '/product_lines/show/:id': 'Visualisation gamme', },
    { '/product_lines/edit/:id': 'Édition gamme', },
    { '/product_lines/create': 'Création gamme', },

    { '/templates/': 'Liste des templates', },
    { '/templates/banners/create': 'Création bandeau', },
    { '/templates/banners/edit/:id': 'Édition bandeau', },
    { '/templates/banners/show/:id': 'Visualisation bandeau', },
  ];

  let title = "Bienvenue";
  for (let i = 0; i < titles.length; i++) {
    const path = Object.keys(titles[i])[0];
    const titleForPath = titles[i][path];
    const match = matchPath(window.location.pathname, {
      path: path,
      exact: true,
      strict: false
    });
    if (match !== null) {
      title = titleForPath;
    }
  }

  return (
    <>
      <Menu />
      <div className="w-100 h-100 overflow-hidden p-0">
        <nav className="navbar navbar-light bg-light p-2">
          <span className="navbar-brand mb-0 h1 text-uppercase">
            { title }
          </span>
          <IconLogOut onClick={handleLogout} />
        </nav>
        <div className="layout fluid-container p-3 overflow-auto scroll-custom">
          { (!!loading || !!promiseInProgress) && <Spinner className="d-flex align-items-center h-100 justify-content-center" />}
          { !loading && !promiseInProgress && children }
        </div>
      </div>
    </>
  );
}

export default withRouter(Layout);
