import React, { useState } from 'react';

import Button from '../../../components/Button/Button';
import MediaObject from '../../../MediaObject/components/MediaObject';
import Modal from '../../../components/Modal/Modal';
import Spinner from '../../../components/Spinner/Spinner';
import { default as FormComponent } from '../../../components/Form/Form';

import '../styles/Form.scss';

const Form = (props) => {
  const { className, loading, values, onChange, onChangeNameField, onSubmit, onError } = props;
  const [ modalPicture, setModalPicture ] = useState({});

  const handleChangeFiles = (event) => {
    const newValuesFiles = !values.bannerFiles ? [] : values.bannerFiles;
    let type = 'desktop';
    let useType = false;

    if (event.target.files.length > 2 || (newValuesFiles.length + event.target.files.length) > 2) {
      onError('Trop de fichiers uploadés. 2 fichiers maximum.');
      return;
    }

    if (newValuesFiles.length > 0) {
      type = newValuesFiles[0].type === 'desktop' ? 'mobile' : 'desktop';
      useType = true;
    }

    for (let x = 0; x < event.target.files.length; x++) {
      let emptyType = x === 0 ? 'desktop' : 'mobile';
      newValuesFiles.push({
        type: useType ? type : emptyType,
        mediaObject: event.target.files[x],
      });
    }

    onError('');
    onChangeNameField('bannerFiles', newValuesFiles);
  }

  const handleChangeType = (fileIdentifier, value) => {
    let newValuesFiles = !values.bannerFiles ? [] : values.bannerFiles;

    newValuesFiles = newValuesFiles.map((file) => {
      file.type = value === 'desktop' ? 'mobile' : 'desktop';
      if (file.mediaObject.id === fileIdentifier || file.mediaObject.name === fileIdentifier) {
        file.type = value;
      }
      return file;
    });

    onChangeNameField('bannerFiles', newValuesFiles);
  };

  const handleDeleteFile = (fileIdentifier) => {
    let newValuesFiles = !values.bannerFiles ? [] : values.bannerFiles;

    newValuesFiles = newValuesFiles.filter((file) =>
      file.mediaObject.name !== fileIdentifier && file.mediaObject.id !== fileIdentifier
    );

    onChangeNameField('bannerFiles', newValuesFiles);
  };

  const picturesBlock = values.bannerFiles && (
    values.bannerFiles.map((file, index) => {
      if (file.mediaObject instanceof File) {
        return (
          <div className="p-3 border mb-3 me-3 d-inline-flex flex-column" key={`product-picture-${file.mediaObject.name}-${index}`}>
            <img
              className="mx-auto my-auto img-fluid"
              alt='product-file'
              src={URL.createObjectURL(file.mediaObject)}
            />
            <div className="delete-banner p-3">
              <Button className="btn-error btn-delete" onClick={() => handleDeleteFile(file.mediaObject.name)}>Supprimer</Button>
            </div>
            <div className="form-check">
              <input 
                className="form-check-input"
                type="radio"
                name={`type-banner-desktop-${index}`}
                value="desktop"
                id={`type-banner-desktop-${index}`}
                checked={file.type === 'desktop'}
                onChange={(event) => handleChangeType(file.mediaObject.name, event.target.value)}
              />
              <label className="form-check-label" htmlFor={`type-banner-desktop-${index}`}>
                Desktop
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name={`type-banner-mobile-${index}`}
                value="mobile"
                id={`type-banner-mobile-${index}`}
                checked={file.type === 'mobile'}
                onChange={(event) => handleChangeType(file.mediaObject.name, event.target.value)}
              />
              <label className="form-check-label" htmlFor={`type-banner-mobile-${index}`}>
                Mobile
              </label>
            </div>
          </div>
        );
      }
      return (
         <div 
          key={`banner-picture-${file.name}-${index}`}
          className="p-3 border mb-3 me-3 d-inline-flex flex-column"
        >
          <div onClick={() => setModalPicture(file.mediaObject)}>
            <MediaObject logo={file.mediaObject} />
          </div>
          <div className="delete-banner p-3">
            <Button className="btn-error btn-delete" onClick={() => handleDeleteFile(file.mediaObject.id)}>Supprimer</Button>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={`type-banner-desktop-${index}`}
              value="desktop"
              id={`type-banner-desktop-${index}`}
              checked={file.type === 'desktop'}
              onChange={(event) => handleChangeType(file.mediaObject.id, event.target.value)}
            />
            <label
              className="form-check-label"
              htmlFor={`type-banner-desktop-${index}`}
            >
              Desktop
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={`type-banner-mobile-${index}`}
              value="mobile"
              id={`type-banner-mobile-${index}`}
              checked={file.type === 'mobile'}
              onChange={(event) => handleChangeType(file.mediaObject.id, event.target.value)} 
            />
            <label
              className="form-check-label"
              htmlFor={`type-banner-mobile-${index}`}
            >
              Mobile
            </label>
          </div>
        </div>
      );
    })
  );

  let createdAtDisplay = undefined;
  if (!isNaN(Date.parse(values.createdAt))) {
    const createdAtDate = new Date(values.createdAt);
    createdAtDisplay = 'Bandeau créé le : ' + createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAtDisplay = undefined;
  if (!isNaN(Date.parse(values.updatedAt))) {
    const updatedAtDate = new Date(values.updatedAt);
    updatedAtDisplay = 'Mis à jour le : ' + updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }
  
  return (
    <>
      {
        Object.keys(modalPicture).length > 0 && (
          <Modal showFooter onClose={() => setModalPicture({})}>
            <MediaObject logo={modalPicture} />
          </Modal>
        )
      }
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-10 col-sm-12">
            <FormComponent onSubmit={onSubmit} className={className}>
              {
                loading
                  ?
                    <Spinner className="d-flex align-items-center h-100 justify-content-center" />
                  :
                    <>
                      <div className="form-group">
                        <label
                          htmlFor="banner_name"
                          className="form-control-label"
                        >
                          Nom
                        </label>
                        <input
                          type="text"
                          required
                          id='banner_name'
                          name="name"
                          className="form-control"
                          value={values.name}
                          onChange={onChange}
                        />
                        {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="banner_files"
                          className="form-control-label"
                        >
                          Images
                        </label>
                        <input
                          type="file"
                          id='banner_files'
                          name="files"
                          className="form-control mb-3"
                          //value={values.logo}
                          onChange={handleChangeFiles}
                          multiple
                        />
                      </div>
                      <div className="d-flex">{picturesBlock}</div>

                      <div className="col-md-12 mb-3">
                        <div>{ createdAtDisplay }</div>
                        <div>{ updatedAtDisplay }</div>
                      </div>

                      <Button type="submit" className="btn-secondary" onClick={onSubmit}>
                        Enregistrer
                      </Button>
                    </>
              }
            </FormComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;