// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconLaboratory = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames({
      icon: true,
      'icon-laboratory': true,
      [className]: !!className,
    }),
    onClick,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" xmlSpace="preserve" {...newProps}>
      <path d="M366.22 13.07c-9.6 4.8-14.41 14.22-15.56 30.54-1.73 28.24 4.61 42.65 20.94 47.06l7.49 2.11V362.7L275.16 606.66c-57.25 134.08-106.04 249.73-108.34 257.22-6.34 19.4-7.68 35.54-4.03 52.44 7.68 36.69 34.19 62.24 73.38 70.5 19.79 4.23 508.1 4.23 527.89 0 59.55-12.68 89.33-67.24 68.58-125.83-1.92-5.76-50.52-120.26-107.58-254.34L621.13 362.89V92.8l7.49-2.11c11.14-2.88 18.83-12.68 20.36-26.13 2.11-16.33.19-34-4.61-41.68-8.26-13.26-2.88-12.87-144.65-12.87-112.76-.01-128.12.38-133.5 3.06zm199.2 219.96v140.42l107 251.07c58.78 137.93 108.15 255.5 109.69 261.26 5.57 21.71-3.46 39.38-23.24 45.33-14.6 4.42-502.92 4.42-517.52 0-19.02-5.57-29.01-23.82-24.01-43.8 1.15-4.42 11.14-29.39 22.09-55.13 11.14-25.93 59.55-139.66 107.77-253l87.6-205.74V92.6h130.62v140.43z" />
      <path d="M385.43 561.71C380.82 567.86 245.97 887.89 245.2 895c-.96 7.88-.96 8.07 6.34 9.99 10.57 2.88 486.59 2.88 497.16 0 7.3-1.92 7.3-2.12 6.34-9.99-.78-7.1-135.44-327.14-140.25-333.29-1.35-1.73-26.7-2.31-114.68-2.31-87.98.01-113.34.58-114.68 2.31zm138.89 89.14c39 24.01 21.9 84.14-24.2 84.14-48.41 0-63.78-64.35-20.75-86.25 14.21-7.11 31.5-6.34 44.95 2.11zm-114.88 92.78c35.15 18.06 24.01 69.35-14.98 69.35-12.68 0-21.52-4.23-29.58-14.22-7.88-9.6-9.22-27.28-3.27-39 8.83-17.47 30.92-24.77 47.83-16.13zm123.71 47.83c6.34 6.92 7.3 9.03 7.3 17.1 0 16.91-10.57 28.05-26.89 28.24-15.37.19-27.09-11.14-27.28-26.13-.38-17.67 12.49-29.39 29.97-27.85 8.26.77 10.56 1.92 16.9 8.64z" />
    </svg>
  );
};

IconLaboratory.defaultProps = {
  className: 'icon-laboratory',
  onClick: () => {},
};

export default IconLaboratory;
