import React from 'react';

import Button from '../../components/Button/Button';
import InputSearchCategory from '../../components/Input/InputSearchCategory';
import InputSearchClassification from '../../components/Input/InputSearchClassification';
import Spinner from '../../components/Spinner/Spinner';

import { default as FormComponent } from '../../components/Form/Form';

const Form = (props) => {
  const { className, loading, values, onChange, onChangeNameField, onSubmit } = props;

  const handleChangeParent = (field, value) => {
    onChangeNameField(field, value)
  };

  const handleRemoveParent = () => {
    onChangeNameField('parent', '')
  };

  const handleChangeClassification = (field, value) => {
    onChangeNameField(field, value)
  };

  const handleRemoveClassification = () => {
    onChangeNameField('classification', '')
  };

  let createdAtDisplay = undefined;
  if (!isNaN(Date.parse(values.createdAt))) {
    const createdAtDate = new Date(values.createdAt);
    createdAtDisplay = 'Catégorie créée le : ' + createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAtDisplay = undefined;
  if (!isNaN(Date.parse(values.updatedAt))) {
    const updatedAtDate = new Date(values.updatedAt);
    updatedAtDisplay = 'Mis à jour le : ' + updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }

  return (
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-md-10 col-sm-12">
          <FormComponent onSubmit={onSubmit} className={className}>
            {
              loading
                ?
                  <Spinner className="d-flex align-items-center h-100 justify-content-center" />
                :
                  <>
                    <div className="form-group">
                      <label
                        htmlFor="category_name"
                        className="form-control-label"
                      >
                        Nom
                      </label>
                      <input
                        type="text"
                        required
                        id='category_name'
                        name="name"
                        className="form-control"
                        value={values.name}
                        onChange={onChange}
                      />
                      {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
                    </div>

                    <InputSearchCategory
                      value={typeof values.parent === 'string' ? values.parent : (values.parent && values.parent.label)}
                      onChange={handleChangeParent}
                      onSelect={handleChangeParent}
                      onClear={handleRemoveParent}
                      field='parent'
                      label="Catégorie parente"
                      categoryId={values.id}
                    />

                    <InputSearchClassification
                      value={typeof values.classification === 'string' ? values.classification : (values.classification && values.classification.label)}
                      onChange={handleChangeClassification}
                      onSelect={handleChangeClassification}
                      onClear={handleRemoveClassification}
                      field='classification'
                    />

                    <div className="col-md-12 mb-3">
                      <div>{ createdAtDisplay }</div>
                      <div>{ updatedAtDisplay }</div>
                    </div>

                    <Button type="submit" className="btn-secondary" onClick={onSubmit}>
                      Enregistrer
                    </Button>
                  </>
            }
          </FormComponent>
        </div>
      </div>
    </div>
  );
};

export default Form;