import React from 'react';

import ExpansionPanelFilters from '../../../components/ExpansionPanel/ExpansionPanelFilters';

const Filters = (props) => {
  //const { filters, onSubmit } = props;
  const { onSubmit } = props;
  //const [ year, setYear ] = useState(!!filters.year ? filters.year : '');

  const handleSubmit = () => {
    const filters = {};
    /* if (year !== '') {
      Object.assign(filters, { name: year.trim() });
    } */

    onSubmit(filters);
  };

  const handleClear = () => {
    //if (year !== '') setYear('');

    onSubmit({});
  };

  return (
    <ExpansionPanelFilters 
      title='Filtres'
      active={false}
      onSubmit={handleSubmit}
      onClear={handleClear}
    >
      {/* <div className="form-group">
        <label
          htmlFor="product_import_name"
          className="form-control-label"
        >
          Année
        </label>
        <input
          type="text"
          id='product_import_year'
          name="year"
          className="form-control"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />
      </div>
     */}
    </ExpansionPanelFilters>
  );
};

export default Filters;