import React, { useState } from 'react';

import { mediaObjectService } from '../../../MediaObject/service';
import { bannerService } from '../service';

import Form from './Form';
import IconSimpleArrow from '../../../icons/IconSimpleArrow';

import * as bannerConstants from '../constants';


const { PATH, PATH_BANNER } = bannerConstants;

const Create = (props) => {
  const [ banner, setBanner ] = useState(bannerConstants.initialBanner);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const { history } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newBanner = {...banner};

    newBanner[target.name] = target.value;

    setBanner(newBanner);
  };

  const handleChangeNameField = (field, value) => {
    const newBanner = {...banner};

    newBanner[field] = value;

    setBanner(newBanner);
  };

  const handleCreate = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const payload = { ...banner };
      const filesToUpload = payload.bannerFiles.filter((file) => { return (file.mediaObject instanceof File); });
      const filesAlreadyUploaded = payload.bannerFiles
        .filter((file) => { return !(file.mediaObject instanceof File); })
        .map((file) => ({ 
          ...file,
          mediaObject: file.mediaObject.id,
        }));

      for (let i = 0; i < filesToUpload.length; i++) {
        const uploadedFile = await mediaObjectService.createMediaObject();
        await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, filesToUpload[i].mediaObject);
        const fileToUpload = {
          ...filesToUpload[i],
          mediaObject: uploadedFile.id,
        };
        filesAlreadyUploaded.push(fileToUpload);
      }
      payload.bannerFiles = filesAlreadyUploaded;
      const newBanner = await bannerService.create(payload);
      setError('');
      setLoading(false);
      if (!!newBanner && !!newBanner.id) {
        history.push(`${PATH_BANNER}edit/${newBanner.id}`);
      }
    } catch (e) {
      //TODO: setClassification(classificationConstants.initialclassification); ?
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="go-back" onClick={() => history.push(PATH)}>
        <IconSimpleArrow />
        <span>Retour</span>
      </div>

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}

      <Form
        className="mb-5 form-template-banner"
        onSubmit={handleCreate}
        values={banner}
        onChange={handleChange}
        onChangeNameField={handleChangeNameField}
        loading={loading}
        onError={setError}
      />
    </div>
  );
};

export default Create;