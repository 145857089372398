import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { laboratoryService } from '../service';
import { mediaObjectService } from '../../MediaObject/service';
import * as laboratoryConstants from '../constants';

import Form from './Form';

const { PATH } = laboratoryConstants;

const Edit = (props) => {
  const [ laboratory, setLaboratory ] = useState(laboratoryConstants.initialLaboratory);
  const [ error, setError ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const { history, location, match } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newLab = {...laboratory};

    newLab[target.name] = target.value;

    setLaboratory(newLab);
  };

  const handleChangeNameField = (field, value) => {
    const newLaboratory = {...laboratory};

    newLaboratory[field] = value;

    setLaboratory(newLaboratory);
  };

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      laboratoryService._delete(match.params.id).then(() => {
        setLaboratory(laboratoryConstants.initialLaboratory);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        setError(!!e && !!e.message ? e.message : e);
        setSuccess(false);
        setLoading(false);
      })
    }
  };

  const handleEdit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const payload = { ...laboratory };
      payload.brands = payload.brands.map((brand) => brand.value);
       if (payload.logo instanceof File) {
        const uploadedFile = await mediaObjectService.createMediaObject();
        await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, payload.logo);
        payload.logo = uploadedFile.id;
      } else if (!!payload.logo) {
        payload.logo = payload.logo.id;
      }
      if (payload.addresses === null) {
        delete payload.addresses;
      }
      const newLaboratory = await laboratoryService.update(match.params.id, payload);
      const brandsLabDatas = newLaboratory.brands.map((brandLab) => {
        return { label: brandLab.name, value: brandLab.id };
      });
      newLaboratory.brands = brandsLabDatas;
      if (!!newLaboratory.logo && !!newLaboratory.logo.id) {
        const logo = await mediaObjectService.getById(newLaboratory.logo.id);
        newLaboratory.logo = logo;
      }
      setSuccess(true);
      setError('');
      setLaboratory(newLaboratory);
      setLoading(false);
    } catch (e) {
      //TODO: set cat initial ?
      setError(!!e && !!e.message ? e.message : e);
      setSuccess(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadDatas = async () => {
      try {
        setLoading(true);
        setError('');
        const newLaboratory = await laboratoryService.getById(match.params.id);
        const brandsLabDatas = await newLaboratory.brands.map((brandLab) => {
          return { label: brandLab.name, value: brandLab.id };
        });
        newLaboratory.brands = brandsLabDatas;
        if (!!newLaboratory.logo && !!newLaboratory.logo.id) {
          const logo = await mediaObjectService.getById(newLaboratory.logo.id);
          newLaboratory.logo = logo;
        }
        setLaboratory(newLaboratory);
        setLoading(false);
      } catch (e) {
        setLaboratory(laboratoryConstants.initialLaboratory);
        setError(!!e && !!e.message ? e.message : e);
        setSuccess(false);
        setLoading(false);  
      }
    };
    
    loadDatas();
  }, [match]);

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!laboratory ? '#' + laboratory.id : ''
  };

  return (
    <div className="d-flex flex-column">
      {success && (
        <div className="alert alert-success" role="status">
          Laboratoire mis à jour.
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      {laboratory && (
        <Form
          className="form-laboratory"
          onSubmit={handleEdit}
          values={laboratory}
          onChange={handleChange} 
          onChangeNameField={handleChangeNameField}
          loading={loading}
        />
      )}
      <div className="d-flex justify-content-between pb-5">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Edit;