import React, { useState } from 'react';

import Storage from '../storage';

import IconAddressBook from '../icons/IconAddressBook';
import IconBurgerMenu from '../icons/IconBurgerMenu';
import IconBrand from '../icons/IconBrand';
import IconClassification from '../icons/IconClassification';
import IconHome from '../icons/IconHome';
import IconProducts from '../icons/IconProducts';
import IconProductLine from '../icons/IconProductLine';
import IconCatalog from '../icons/IconCatalog';
import IconLaboratory from '../icons/IconLaboratory';
import IconTemplate from '../icons/IconTemplate';
import UrlHelpers from '../helpers/UrlHelpers';
import './styles/Menu.scss';

const Menu = () => {
  const isMenuOpen = Storage.get('menuOpen') === null ? true : Storage.get('menuOpen');
  const [ isExpanded, setIsExpanded ] = useState(isMenuOpen);

  const toggleNavBar = () => {
    setIsExpanded(!isExpanded);
    Storage.set('menuOpen', !isExpanded);
  }

  //TODO: use PATH from constants
  return (
    <div className={`menu d-flex flex-column flex-shrink-0 h-100 overflow-auto scroll-custom ${isExpanded ? 'is-expanded' : 'is-not-expanded'}`}>
      <div className={`${isExpanded ? 'd-flex' : 'd-block'} menu-header w-100 justify-content-between align-items-center mb-3 p-3 mb-md-0 me-md-auto text-decoration-none`}>
        {isExpanded && (
          <>
            <img className="me-3" src='/logo_200x200.png' alt="logo_PIM" />
            <span className="fs-5">Product Information Management</span>
          </>
        )}
        <IconBurgerMenu onClick={toggleNavBar} />
      </div>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item" title="Accueil">
          <a href="/home" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/home') && 'active'}`} aria-current="page">
            <IconHome />
            {isExpanded && 'Accueil' }
          </a>
        </li>
        <li className="nav-item" title="Catalogue">
          <a href="/catalogs/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/catalogs') && 'active'}`} aria-current="page">
            <IconAddressBook />
            {isExpanded && 'Catalogue' }
          </a>
        </li>
        <li className="nav-item" title="Catégories">
          <a href="/categories/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/categories') && 'active'}`} aria-current="page">
            <IconCatalog />
            {isExpanded && 'Catégories' }
          </a>
        </li>
        <li className="nav-item" title="Classifications">
          <a href="/classifications/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/classifications') && 'active'}`} aria-current="page">
            <IconClassification />
            {isExpanded && 'Classifications' }
          </a>
        </li>
        <li className="nav-item" title="Gammes">
          <a href="/product_lines/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/product_lines') && 'active'}`} aria-current="page">
            <IconProductLine />
            {isExpanded && 'Gammes' }
          </a>
        </li>
        <li className="nav-item" title="Marques">
          <a href="/brands/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/brands') && 'active'}`} aria-current="page">
            <IconBrand />
            {isExpanded && 'Marques' }
          </a>
        </li>
        <li className="nav-item" title="Laboratoires">
          <a href="/laboratories/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/laboratories') && 'active'}`} aria-current="page">
            <IconLaboratory />
            {isExpanded && 'Laboratoires' }
          </a>
        </li>
        <li className="nav-item" title="Produits">
          <a href="/products/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/products') && 'active'}`}>
            <IconProducts />
            {isExpanded && 'Produits' }
          </a>
        </li>
        <li className="nav-item" title="Templates">
          <a href="/templates/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/templates') && 'active'}`}>
            <IconTemplate />
            {isExpanded && 'Templates' }
          </a>
        </li>
        {/* 
        <li className="nav-item">
          <a href="/article_types/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/article_types') && 'active'}`} aria-current="page">
            <IconAddressBook />
            {isExpanded && 'Types d\'article' }
          </a>
        </li>
        <li className="nav-item">
          <a href="/general_features/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/general_features') && 'active'}`} aria-current="page">
            <IconAddressBook />
            {isExpanded && 'Caractéristiques générales' }
          </a>
        </li>
        <li className="nav-item">
          <a href="/general_feature_types/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/general_feature_types') && 'active'}`} aria-current="page">
            <IconAddressBook />
            {isExpanded && 'Type de caractéristiques générales' }
          </a>
        </li>
        <li className="nav-item">
          <a href="/option_types/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/option_types') && 'active'}`} aria-current="page">
            <IconAddressBook />
            {isExpanded && 'Types d\'option' }
          </a>
        </li> 
        <li className="nav-item">
          <a href="/product_articles/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/product_articles') && 'active'}`} aria-current="page">
            <IconAddressBook />
            {isExpanded && 'Articles produit' }
          </a>
        </li>
        <li className="nav-item">
          <a href="/product_features/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/product_features') && 'active'}`} aria-current="page">
            <IconAddressBook />
            {isExpanded && 'Caractéristiques produit' }
          </a>
        </li>
        <li className="nav-item">
          <a href="/product_types/" className={`nav-link ${UrlHelpers.getCurrentPath().startsWith('/product_types') && 'active'}`} aria-current="page">
            <IconAddressBook />
            {isExpanded && 'Type de produit' }
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default Menu;