// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className?: string,
  onClick: () => void,
};

const IconChevron = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-chevron', className),
    onClick,
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.414 14.828" {...newProps}>
      <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1.414 13.414l6-6-6-6" />
    </svg>
  );
};

IconChevron.defaultProps = {
  className: 'icon-chevron',
  onClick: () => {},
};

export default IconChevron;
