import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { categoryService } from '../service';
import * as categoryConstants from '../constants';

import Form from './Form';

const { PATH } = categoryConstants;

const Edit = (props) => {
  const [ category, setCategory ] = useState(categoryConstants.initialCategory);
  const [ error, setError ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const { history, location, match } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newCategory = {...category};

    newCategory[target.name] = target.value;

    setCategory(newCategory);
  };

  const handleChangeNameField = (field, value) => {
    const newCategory = {...category};

    newCategory[field] = value;

    setCategory(newCategory);
  };

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      categoryService._delete(match.params.id).then(() => {
        setCategory(categoryConstants.initialCategory);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        setError(!!e && !!e.message ? e.message : e);
        setSuccess(false);
        setLoading(false);
      })
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!!category.parent && !!category.parent.value) {
      category.parent = category.parent.value;
    } else {
      delete category.parent;
    }
    if (category.categories) {
      delete category.categories;
    }
    if (!!category.classification && !!category.classification.value) {
      category.classification = category.classification.value;
    } else {
      delete category.classification;
    }
    categoryService.update(match.params.id, category).then((newCategory) => {
      if (!!newCategory.parent) {
        newCategory.parent = { value: newCategory.parent.id, label: newCategory.parent.name };
      }
      if (!!newCategory.classification && !!newCategory.classification.name && !!newCategory.classification.id) {
        newCategory.classification = { label: newCategory.classification.name, value: newCategory.classification.id };
      } else {
        category.classification = '';
      }
      setSuccess(true);
      setError('');
      setCategory(newCategory);
      setLoading(false);
    }).catch((e) => {
      //TODO: set cat initial ?
      setError(!!e && !!e.message ? e.message : e);
      setSuccess(false);
      setLoading(false);
    })
  };

  useEffect(() => {
    setLoading(true);
    categoryService.getById(match.params.id).then((cat) => {
      if (!!cat.parent) {
        cat.parent = { value: cat.parent.id, label: cat.parent.name };
      } else {
        delete cat.parent;
      }
      if (!!cat.classification && !!cat.classification.name && !!cat.classification.id) {
        cat.classification = { value: cat.classification.id, label: cat.classification.name };
      } else {
        cat.classification = '';
      }
      setCategory(cat);
      setError('');
      setLoading(false);
    }).catch((e) => {
      //TODO: set cat initial ?
      setError(!!e && !!e.message ? e.message : e);
      setSuccess(false);
      setLoading(false);
    })
  }, [match]);

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!category ? '#' + category.id : ''
  };

  return (
    <div className="d-flex flex-column">
      {success && (
        <div className="alert alert-success" role="status">
          Catégorie mis à jour.
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      {category && (
        <Form
          onSubmit={handleEdit}
          values={category}
          onChange={handleChange}
          onChangeNameField={handleChangeNameField}
          loading={loading}
        />
      )}
      <div className="d-flex justify-content-between pb-5">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Edit;