import React from 'react';
import classNames from 'classnames';

import Button from '../Button/Button';
import ExpansionPanel from './ExpansionPanel';

import './styles/ExpansionPanelFilters.scss';

const ExpansionPanelFilter = (props) => {
  const { active, className, title, onClear, onSubmit } = props;

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClear();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit();
  };

  const renderFormFilterContent = () => {
    const { children } = props;

    return (
      <div className="container">
        <div className="form-filter-content">
          {children}
        </div>
        <div className="form-filter-actions">
          <Button className="btn-secondary" type="submit">
            Filtrer
          </Button>
          <Button className="btn-error" onClick={handleClear}>
            Effacer
          </Button>
        </div>
      </div>
    );
  };

  const expansionPanelFilterClassName = classNames({
    'expansion-panel-filter': true,
    [className]: !!className,
  });

  return (
    <ExpansionPanel
      active={active}
      title={title}
      className={expansionPanelFilterClassName}
    >
      <form onSubmit={handleSubmit}>
        {renderFormFilterContent()}
      </form>
    </ExpansionPanel>
  );
}

ExpansionPanelFilter.defaultProps = {
  children: <div />,
  className: 'expansion-panel-filter',
  onClear: () => {},
  onSubmit: () => {},
};

export default ExpansionPanelFilter;
