import React, { useEffect, useRef, useState } from 'react';

import BlockContainer from '../../../components/BlockContainer/BlockContainer';

const FormSizes = (props) => {
  const { weight, height, depth, width, onChangeNameField } = props;

  const [ isWeightDropdownActive, setIsWeightDropdownActive ] = useState(false);
  const [ isHeightDropdownActive, setIsHeightDropdownActive ] = useState(false);
  const [ isDepthDropdownActive, setIsDepthDropdownActive ] = useState(false);
  const [ isWidthDropdownActive, setIsWidthDropdownActive ] = useState(false);
  const refWeight = useRef(null);
  const refHeight = useRef(null);
  const refDepth = useRef(null);
  const refWidth = useRef(null);

  const handleClickOutside = (event) => {
    if (refWeight.current && !refWeight.current.contains(event.target)) {
      setIsWeightDropdownActive(false);
    }
    if (refHeight.current && !refHeight.current.contains(event.target)) {
      setIsHeightDropdownActive(false);
    }
    if (refDepth.current && !refDepth.current.contains(event.target)) {
      setIsDepthDropdownActive(false);
    }
    if (refWidth.current && !refWidth.current.contains(event.target)) {
      setIsWidthDropdownActive(false);
    }
  };

  const handleChangeUnit = (field, value) => {
    const newValues = {...props[field]};

    newValues.unit = value;

    onChangeNameField(field, newValues)
  }

  const handleChangeMeasures = (field, value) => {
    const newValues = {...props[field]};

    newValues.value = value;

    onChangeNameField(field, newValues)
  }

  useEffect(() => {
    const handler = (e) => {
      if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener("focusin", handler);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener("focusin", handler);
      document.removeEventListener('click', handleClickOutside, true);
    }
  }, []);

  return (
    <BlockContainer title="Mesures produit">
      <div className="form-group">
        <label
          htmlFor="weight"
          className="form-control-label"
          style={{zIndex: 9}}
        >
          Poids
        </label>
        <div className="input-group">
          <input
            type="number"
            step="0.01"
            id='weight'
            name="weight"
            className="form-control"
            value={!!weight && weight.value}
            onChange={(e) => handleChangeMeasures('weight', e.target.value)}
          />
          <div className="input-group-append">
            <div 
              className="dropdown-toggle"
              ref={refWeight}
              onClick={() => setIsWeightDropdownActive(!isWeightDropdownActive)}>{!!weight && !!weight.unit ? weight.unit : 'Unité'}
            </div>
            <div className={`dropdown-menu ${isWeightDropdownActive && 'd-block'}`}>
              <span className="dropdown-item" onClick={() => handleChangeUnit('weight', 'kg')}>Kg</span>
              <span className="dropdown-item" onClick={() => handleChangeUnit('weight', 'gr')}>Gr</span>
            </div>
            </div>
          </div>
        {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
      </div>

      <div className="form-group">
        <label
          htmlFor="height"
          className="form-control-label"
          style={{zIndex: 9}}
        >
          Hauteur
        </label>
        <div className="input-group">
          <input
            type="number"
            step="0.01"
            id='height'
            name="height"
            className="form-control"
            value={!!height && height.value}
            onChange={(e) => handleChangeMeasures('height', e.target.value)}
          />
          <div className="input-group-append">
            <div 
              className="dropdown-toggle"
              ref={refHeight}
              onClick={() => setIsHeightDropdownActive(!isHeightDropdownActive)}>{!!height && !!height.unit ? height.unit : 'Unité'}
            </div>
            <div className={`dropdown-menu ${isHeightDropdownActive && 'd-block'}`}>
              <span className="dropdown-item" onClick={() => handleChangeUnit('height', 'cm')}>Cm</span>
              <span className="dropdown-item" onClick={() => handleChangeUnit('height', 'mm')}>Mm</span>
            </div>
            </div>
          </div>
        {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
      </div>

      <div className="form-group">
        <label
          htmlFor="depth"
          className="form-control-label"
          style={{zIndex: 9}}
        >
          Longueur
        </label>
        <div className="input-group">
          <input
            type="number"
            step="0.01"
            id='depth'
            name="depth"
            className="form-control"
            value={!!depth && depth.value}
            onChange={(e) => handleChangeMeasures('depth', e.target.value)}
          />
          <div className="input-group-append">
            <div 
              className="dropdown-toggle"
              ref={refDepth}
              onClick={() => setIsDepthDropdownActive(!isDepthDropdownActive)}>{!!depth && !!depth.unit ? depth.unit : 'Unité'}
            </div>
            <div className={`dropdown-menu ${isDepthDropdownActive && 'd-block'}`}>
              <span className="dropdown-item" onClick={() => handleChangeUnit('depth', 'cm')}>Cm</span>
              <span className="dropdown-item" onClick={() => handleChangeUnit('depth', 'mm')}>Mm</span>
            </div>
            </div>
          </div>
        {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
      </div>

      <div className="form-group">
        <label
          htmlFor="width"
          className="form-control-label"
          style={{zIndex: 9}}
        >
          Largeur
        </label>
        <div className="input-group">
          <input
            type="number"
            step="0.01"
            id='width'
            name="width"
            className="form-control"
            value={!!width && width.value}
            onChange={(e) => handleChangeMeasures('width', e.target.value)}
          />
          <div className="input-group-append">
            <div 
              className="dropdown-toggle"
              ref={refWidth}
              onClick={() => setIsWidthDropdownActive(!isWidthDropdownActive)}>{!!width && !!width.unit ? width.unit : 'Unité'}
            </div>
            <div className={`dropdown-menu ${isWidthDropdownActive && 'd-block'}`}>
              <span className="dropdown-item" onClick={() => handleChangeUnit('width', 'cm')}>Cm</span>
              <span className="dropdown-item" onClick={() => handleChangeUnit('width', 'mm')}>Mm</span>
            </div>
            </div>
          </div>
        {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
      </div>
    </BlockContainer>
  );
};

export default FormSizes;