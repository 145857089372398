import React, { useState } from 'react';

import { default as GenericList } from '../../../components/List/List';

import { productService } from '../../service';

import Filters from './Filters';
import CustomList from './CustomList';
import * as productConstants from '../../constants';

import '../../styles/BCB.scss';

const {
  API_PATH_LOGS,
  PATH_BCB,
  TABLE_HEADERS_BCB,
} = productConstants;

const defaultParams = {
  itemsPerPage: 10,
  page: 1,
  order: {
    //name: 'asc',
  }
};

const Page = () => {
  const [ error, setError ] = useState('');

  return (
    <GenericList
      className="products-bcb"
      config={
        {
          pathForButtons: '',
          PATH: PATH_BCB,
          API_PATH: API_PATH_LOGS,
          service: productService,
          tableHeaders: TABLE_HEADERS_BCB,
          defaultParams: defaultParams,
        }
      }
      errorPairs={[error, setError]}
      noCreate={true}
    >
      <Filters />
      <CustomList />
    </GenericList>
  );
};

export default Page;