import React, { useState } from 'react';

import IconSimpleArrow from '../../icons/IconSimpleArrow';

import { categoryService } from '../service';
import * as categoryConstants from '../constants';

import Form from './Form';

const { PATH } = categoryConstants;

const Create = (props) => {
  const [ category, setCategory ] = useState(categoryConstants.initialCategory);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const { history } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newCategory = {...category};

    newCategory[target.name] = target.value;

    setCategory(newCategory);
  };

  const handleChangeNameField = (field, value) => {
    const newCategory = {...category};

    newCategory[field] = value;

    setCategory(newCategory);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!!category.parent && !!category.parent.value) {
      category.parent = category.parent.value;
    } else {
      delete category.parent;
    }
    if (!!category.classification && !!category.classification.value) {
      category.classification = category.classification.value;
    } else {
      delete category.classification;
    }
    categoryService.create(category).then((newCategory) => {
      //TODO: add success msg ?
      setError('');
      setLoading(false);
      if (!!newCategory && !!newCategory.id) {
        history.push(`${PATH}edit/${newCategory.id}`);
      }
    }).catch((e) => {
      //TODO: set initial cat ?
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    });
  };

  return (
    <div className="d-flex flex-column">
      <div className="go-back" onClick={() => history.push(PATH)}>
        <IconSimpleArrow />
        <span>Retour</span>
      </div>

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}

      <Form
        className="mb-5"
        onSubmit={handleCreate}
        values={category}
        onChange={handleChange}
        onChangeNameField={handleChangeNameField}
        loading={loading}
      />
    </div>
  );
};

export default Create;