import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { ConnectedUserContext } from "../../hooks/useConnectedUser";

import Button from '../../../components/Button/Button';
import Spinner from '../../../components/Spinner/Spinner';
import { userService } from '../../service';

import './styles/LoginForm.scss';

//https://bootsnipp.com/snippets/dldxB

const LoginForm = (props) => {
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const [, setConnectedUser] = useContext(ConnectedUserContext);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username && password) {
      // get return url from location state or default to home page
      //const { from } = props.location.state || { from: { pathname: "/home" } };
      setLoading(true);
      userService.login(username, password).then((user) => {
        setError('');
        setConnectedUser(user);
        if (loading === true) {
          setLoading(false);
        }
        props.history.push('/home');
      }).catch((e) => {
        setConnectedUser(null);
        setError(!!e && e.message ? e.message : e);
        setLoading(false);
      });
    }
  }

  return (
    <div className="login-form">
    <div className="wrapper fadeInDown">
      <div id="formContent">
      
        <div className="fadeIn first">
          <i className="fa fa-users fa-3x m-3 mb-0"></i>
        </div>

        {loading ? (
          <div className="m-3">
            <Spinner className="d-flex align-items-center h-100 justify-content-center" />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              id="username"
              className="fadeIn second"
              name="username"
              placeholder="Nom d'utilisateur"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              id="password"
              className="fadeIn third"
              name="login"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button type="submit" className="fadeIn fourth" onClick={handleSubmit}>Se connecter</Button> 
          </form>
        )}
        
        {error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
            {error}
          </div>
        )}
    
      </div>
    </div>
    </div>
  );
};

export default withRouter(LoginForm);