import React, { useState } from 'react';
import classNames from 'classnames';

import InputSearchMultiple from './InputSearchMultiple';

import { brandService } from '../../Brand/service';
import * as brandConstants from '../../Brand/constants';

const { API_PATH } = brandConstants;

const InputSearchMultipleBrands = (props) => {
  const { className, disabled, values, onRemove } = props;
  //const [ suggests, setSuggests ] = useState([]);
  const [ brands, setBrands ] = useState([]);
  const [ selected, setSelected ] = useState({});
  const [ loading, setLoading ] = useState(false);

  const brandsSuggests = brands.map((brand) => {
    return {
      label: brand.name,
      value: brand.id,
    };
  });

  const handleFocus = () => {
    if (brands.length === 0 && !!selected) {
      brandsSuggests.push({
        label: selected.label,
        value: selected.value
      });
    }
  };

  const handleSearchChange = (field, value) => {
    const { labo, onChange } = props;
    const filters = { name: value };

    if (typeof labo === 'object') {
      Object.assign(filters,
        { 'laboratory.id': labo.value }
      ); 
    }

    onChange(field, value);

    if (value.length > 2) {
      setLoading(true);
      brandService.getAll(API_PATH, filters).then((newBrands) => {
        setBrands(newBrands && newBrands["hydra:member"]);
        setLoading(false);
      });
    } else {
      setBrands([]);
    }
    //TODO: display msg for telling users, to choose a lab first ?
  };

  const handleClear = (field) => {
    const { onClear } = props;

    onClear(field);
    setBrands([]);
  };

  const handleSelect = (field, value) => {
    const { onSelect } = props;

    setSelected(value);
    onSelect(field, value);
  };

  const selectClassName = classNames({
    'input-search-multiple-brands': true,
    [className]: !!className,
  });

  return (
    <InputSearchMultiple
      field="brands"
      label="Marques"
      closeOnSelect
      values={values}
      suggests={brandsSuggests}
      onClear={handleClear}
      onChange={handleSearchChange}
      onSelect={handleSelect}
      onFocus={handleFocus}
      className={selectClassName}
      onRemove={onRemove}
      disabled={disabled}
      loading={loading}
    />
  );
};

export default InputSearchMultipleBrands;