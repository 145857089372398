import React from 'react';

import BlockContainer from '../../../components/BlockContainer/BlockContainer';
import InputSearchLaboratory from '../../../components/Input/InputSearchLaboratory';
import InputSearchBrand from '../../../components/Input/InputSearchBrand';
import InputSearchProductLine from '../../../components/Input/InputSearchProductLine';

const FormInformations = (props) => {
  const {
    name,
    createdAt,
    updatedAt,
    laboratory,
    brand,
    productLine,
    EAN13,
    ACL13,
    CIP7,
    onChange,
    onChangeNameField,
    onRemoveBrand,
    onRemoveLaboratory,
  } = props;

  const handleChangeLaboratory = (field, value) => {
    onChangeNameField(field, value)
  };

  const handleChangeBrand = (field, value) => {
    onChangeNameField(field, value)
  };

  const handleChangeProductLine = (field, value) => {
    onChangeNameField(field, value)
  };

  const handleRemoveProductLine = () => {
    onChangeNameField('productLine', '');
  };

  let createdAtDisplay = undefined;
  if (!isNaN(Date.parse(createdAt))) {
    const createdAtDate = new Date(createdAt);
    createdAtDisplay = 'Produit créé le : ' + createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAtDisplay = undefined;
  if (!isNaN(Date.parse(updatedAt))) {
    const updatedAtDate = new Date(updatedAt);
    updatedAtDisplay = 'Mis à jour le : ' + updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }

  return (
    <BlockContainer title="Informations produit">
      <div className="form-group">
        <label
          htmlFor="product_name"
          className="form-control-label"
        >
          Nom
        </label>
        <input
          type="text"
          required
          id='product_name'
          name="name"
          className="form-control"
          value={name}
          onChange={onChange}
        />
        {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
      </div>

      <InputSearchLaboratory
        value={typeof laboratory === 'string' ? laboratory : (laboratory && laboratory.label)}
        onChange={handleChangeLaboratory}
        onSelect={handleChangeLaboratory}
        onClear={onRemoveLaboratory}
      />

      {typeof laboratory === 'object' && Object.keys(laboratory).length > 0 && (
        <InputSearchBrand
          value={typeof brand === 'string' ? brand : (brand && brand.label)}
          onChange={handleChangeBrand}
          onSelect={handleChangeBrand}
          onClear={onRemoveBrand}
          field='brand'
          laboratory={laboratory}
        />
      )}
    
      {typeof brand === 'object' && Object.keys(brand).length > 0 && (
        //TODO: check fieldname productLine on Product
        <InputSearchProductLine
          value={typeof productLine === 'string' ? productLine : (productLine && productLine.label)}
          onChange={handleChangeProductLine}
          onSelect={handleChangeProductLine}
          onClear={handleRemoveProductLine}
          field='productLine'
          brand={brand}
        />
      )}


      <div className="form-row">
        <div className="form-group col-md-4">
          <label
            htmlFor="product_ean_13"
            className="form-control-label"
          >
            Ean 13
          </label>
          <input
            type="text"
            required
            id='product_ean_13'
            name="EAN13"
            className="form-control"
            value={EAN13}
            onChange={onChange}
          />
          {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
        </div>

        <div className="form-group col-md-4">
          <label
            htmlFor="product_acl_13"
            className="form-control-label"
          >
            Acl 13
          </label>
          <input
            type="text"
            id='product_acl_13'
            name="ACL13"
            className="form-control"
            value={ACL13}
            onChange={onChange}
          />
          {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
        </div>

        <div className="form-group col-md-4">
          <label
            htmlFor="product_cip_7"
            className="form-control-label"
          >
            CIP 7
          </label>
          <input
            type="text"
            id='product_cip_7'
            name="CIP7"
            className="form-control"
            value={CIP7}
            onChange={onChange}
          />
          {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
        </div>
      </div>

      <div className="col-md-12 mb-3">
        <div>{ createdAtDisplay }</div>
        <div>{ updatedAtDisplay }</div>
      </div>
    </BlockContainer>
  );
};

export default FormInformations;