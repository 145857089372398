import React from 'react';
import classNames from 'classnames';

const Tab = (props) => {
  const { title, selected } = props;

  const handleClick = (event: MouseEvent): void => {
    const { id, onClick } = props;
    event.preventDefault();
    onClick(id);
  };

   const className = classNames({
      'nav-link': true,
      'active': selected,
    });

  return (
    <li className="nav-item" onClick={handleClick}>
      <span className={className}>
        { title }
      </span>
    </li>
  );
}

export default Tab;