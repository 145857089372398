import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import InputSearch from './InputSearch';

import { brandService } from '../../Brand/service';
import * as brandConstants from '../../Brand/constants';

const { API_PATH } = brandConstants;

//TODO: fetch abort ?

const InputSearchBrand = (props) => {
  const { className, field, laboratory, value } = props;
  //const [ suggests, setSuggests ] = useState([]);
  const [ brands, setBrands ] = useState([]);
  const [ selected, setSelected ] = useState({});
  const [ loading, setLoading ] = useState(false);

  const brandsSuggests = brands.map((brand) => {
    return {
      label: brand.name,
      value: brand.id,
    };
  });

  const handleFocus = () => {
    if (brands.length === 0 && Object.keys(selected).length === 0) {
      if (!!laboratory && !!laboratory.value) {
        const filters = { laboratory: laboratory.value };

        setLoading(true);
        brandService.getAll(API_PATH, filters).then((newBrands) => {
          setBrands(newBrands && newBrands["hydra:member"]);
          setLoading(false);
        });
      }
    }
    if (brands.length === 0 && !!selected && !!selected.label && !!selected.value) {
      brandsSuggests.push({
        label: selected.label,
        value: selected.value
      });
    }
  };

  const handleSearchChange = (field, value) => {
    const { laboratory, onChange } = props;
    const filters = { name: value };

    if (typeof laboratory === 'object') {
      Object.assign(filters,
        { laboratory: laboratory.value }
      );
    }

    onChange(field, value);

    if (value.length > 2) {
      setLoading(true);
      brandService.getAll(API_PATH, filters).then((newBrands) => {
        setBrands(newBrands && newBrands["hydra:member"]);
        setLoading(false);
      });
    } else {
      setBrands([]);
    }
  };

  const handleClear = (field) => {
    const { onClear } = props;

    onClear(field);
    setSelected({});
    setBrands([]);
  };

  const handleSelect = (field, value) => {
    const { onSelect } = props;

    setSelected(value);
    onSelect(field, value);
  };

  const selectClassName = classNames({
    'input-search-brand': true,
    [className]: !!className,
  });

  useEffect(() => {
    if (!!laboratory && !!laboratory.value) {
      const filters = { laboratory: laboratory.value };

      setLoading(true);
      brandService.getAll(API_PATH, filters).then((newBrands) => {
        setBrands(newBrands && newBrands["hydra:member"]);
        setLoading(false);
      });
    }
  }, [laboratory]);

  //TODO: onRemove ?

  return (
    <InputSearch
      field={!!field ? field : "brand"}
      label="Marque"
      closeOnSelect
      value={value}
      suggests={brandsSuggests}
      onClear={handleClear}
      onChange={handleSearchChange}
      onSelect={handleSelect}
      onFocus={handleFocus}
      className={selectClassName}
      loading={loading}
    />
  );
};

export default InputSearchBrand;