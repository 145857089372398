import React from 'react';
import { Link } from 'react-router-dom';

import ActionsIconTable from '../../../../components/ActionsIconTable/ActionsIconTable';
import MediaObject from '../../../../MediaObject/components/MediaObject';
import Tag from '../../../../components/Tag/Tag';

import * as bannerConstants from '../../constants';
import * as tagConstants from '../../../../components/Tag/constants';

const { PATH_BANNER } = bannerConstants;

const CustomList = (props) => {
  const {
    items: banners,
    onDelete,
    setModalPicture,
  } = props;

  const dataBlock = banners && banners.map((item) => {
    let picturesBlock = <div className="center-tag"><div className="mw-3rem"><Tag style={tagConstants.QUATERNARY} text="Non" /></div></div>;
    if (!!item['bannerFiles'] && item['bannerFiles'].length > 0) {
      picturesBlock = item['bannerFiles'].map((picture, index) => {
        return (
          <div
            onClick={() => setModalPicture(picture.mediaObject)}
            key={`media-object-${picture.name}-${index}`}
            className="pb-3"
          >
            <MediaObject logo={picture.mediaObject} />
          </div>
        );
      });
      picturesBlock = <div className="pictures overflow-div scroll-custom">{picturesBlock}</div>
    }

    return (
      <tr key={'banner-' + item["id"]} id={item.id}>
        <th scope="row">
          <Link to={`${PATH_BANNER}show/${encodeURIComponent(item["id"])}`}>
            {item["id"]}
          </Link>
        </th>
        <td>{ item["name"] }</td>
        <td>{ picturesBlock }</td>
        <ActionsIconTable
          item={item}
          path={PATH_BANNER}
          onDelete={onDelete}
        />
      </tr>
    );
  });

  return (
    <>
      {
        dataBlock.length === 0
          ?
            (<tr>
              <td colSpan={10}>
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <span>Pas de résultat.</span>
                </div>
              </td>
            </tr>)
          :
            dataBlock
      }
    </>
  );
};

export default CustomList;