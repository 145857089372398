export const parseCategoriesInForSelects = (categories) => {
  return categories.map((cat) => {
    const classificationId = !!cat.classification && !!cat.classification.id ? cat.classification.id : cat.classification;
    return {
      label: cat.name || cat.label,
      value: cat.id || cat.value,
      categories: Array.isArray(cat.categories) && parseCategoriesInForSelects(cat.categories),
      classification: classificationId,
    };
  });
};