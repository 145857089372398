import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { brandService } from '../service';
import * as brandConstants from '../constants';
import * as laboratoryConstants from '../../Laboratory/constants';

import Spinner from '../../components/Spinner/Spinner';
import MediaObject from '../../MediaObject/components/MediaObject';

const { PATH } = brandConstants;
const { PATH: laboratoryPath } = laboratoryConstants;

const Show = (props) => {
  const [ brand, setBrand ] = useState(brandConstants.initialBrand);
  const [ error, setError ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const { history, location, match } = props;

  const renderLinks = (type, items) => {
    return items.map((item) => {
      return (
        <div key={type + '-' + item.id}>
          <Link to={`/${type}/show/${encodeURIComponent(item.id)}`}>{item.name}</Link>
        </div>
      );
    })
  };

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      brandService._delete(match.params.id).then(() => {
        setError('');
        setBrand(brandConstants.initialBrand);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        //TODO: setBrand ?
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    const loadDatas = async () => {
      try {
        setLoading(true);
        const newBrand = await brandService.getById(match.params.id);
        setBrand(newBrand);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);  
      }
    };

    loadDatas();
  }, [match]);

  if (loading) {
    return (
      <div className="d-flex flex-column h-100">
        <Spinner className="d-flex align-items-center h-100 justify-content-center" />
      </div>
    );
  }

  let laboratoryLink = null;
  if (!!brand.laboratory && !!brand.laboratory.name) {
    laboratoryLink = <Link to={`${laboratoryPath}show/${brand.laboratory.id}`}>{brand.laboratory.name}</Link>
  }

  let createdAt = undefined;
  if (!isNaN(Date.parse(brand.createdAt))) {
    const createdAtDate = new Date(brand.createdAt);
    createdAt = createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAt = undefined;
  if (!isNaN(Date.parse(brand.updatedAt))) {
    const updatedAtDate = new Date(brand.updatedAt);
    updatedAt = updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!brand ? '#' + brand.id : ''
  };
  const paramsForEdit = {
    ...paramsForBack,
    pathname: `${PATH}edit/${encodeURIComponent(brand["id"])}`
  };

  return (
    <div className="d-flex flex-column pb-3">
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}
      {brand && (
        <table className="table table-responsive table-striped table-hover">
          <thead>
            <tr>
              <th>Champ</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Nom</th>
              <td key="brand_name">{brand["name"]}</td>
            </tr>
            <tr>
              <th scope="row">Laboratoire</th>
              <td key="brand_name">{ laboratoryLink }</td>
            </tr>
            <tr>
              <th scope="row">Image</th>
              <td key="brand_logo">
                <MediaObject logo={brand["logo"]} />
              </td>
            </tr>
            <tr>
              <th scope="row">Gammes</th>
              <td key="brand_name">{renderLinks('product_lines', brand["productLines"])}</td>
            </tr>
            <tr>
              <th scope="row">Date de création</th>
              <td>{ createdAt }</td>
            </tr>
            <tr>
              <th scope="row">Date de modification</th>
              <td>{ updatedAt }</td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="d-flex justify-content-between mb-3 pb-3">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        {brand && (
          <Link to={paramsForEdit} className="btn btn-warning">
            Editer
          </Link>
        )}
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Show;