// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconLogOut = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-log-out', className),
    onClick,
  };

  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" {...newProps}>
      <path d="M3 3h8V1H3a2 2 0 00-2 2v14a2 2 0 002 2h8v-2H3z" />
      <path d="M19 10l-6-5v4H5v2h8v4l6-5z" />
    </svg>
  );
};

IconLogOut.defaultProps = {
  className: 'icon-log-out',
  onClick: () => {},
};

export default IconLogOut;
