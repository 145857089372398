// @flow
import React, { useState } from 'react';
import classNames from 'classnames';

import IconChevron from '../../icons/IconChevron';

import './styles/ExpansionPanel.scss';

/* type Props = {
  active: boolean,
  className: string,
  children: Node,
  title: string,
  icon: Node,
};

type State = {
  active: boolean,
}; */

const ExpansionPanel = (props) => {
  const { className, children, icon, title } = props;
  const [ active, setActive ] = useState(props.active);

  const handleClick = () => {
    setActive(!active);
  };

  const expansionPanelClassName = classNames({
    'expansion-panel': true,
    active,
    [className]: !!className,
  });

  return (
    <div className={expansionPanelClassName}>
      <div className="expansion-panel-title" onClick={handleClick}>
        <div className="expansion-panel-title-text">
          {title}
        </div>
        <div className="expansion-panel-title-icon">
          {icon}
        </div>
      </div>
      {active && (
        <div className="expansion-panel-content scroll-custom">
          {children}
        </div>
      )}
    </div>
  );
}

ExpansionPanel.defaultProps = {
  active: true,
  className: 'expansion-panel',
  icon: <IconChevron />,
  children: <div />,
};

export default ExpansionPanel;
