import React from 'react';

const CustomList = (props) => {
  const {
    items: logs,
  } = props;

  const dataBlock = logs && logs.map((item) => (
    <tr key={'laboratory-' + item["id"]} id={item.id}>
      <th scope="row">
        { item["id"] }
      </th>
      <td>{item["productName"]}</td>
      <td>{item["productEAN"]}</td>
      <td>{item["productCIP"]}</td>
    </tr>
  ));

  return (
    <>
      {
        dataBlock.length === 0
          ?
            (<tr>
              <td colSpan={10}>
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <span>Pas de résultat.</span>
                </div>
              </td>
            </tr>)
          :
            dataBlock
      }
    </>
  );
};

export default CustomList;