import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import InputSearchMultiple from './InputSearchMultiple';

import { classificationService } from '../../Classification/service';
import * as classificationConstants from '../../Classification/constants';

const { API_PATH } = classificationConstants;

const InputSearchMultipleClassification = (props) => {
  const { catalogs, className, field, values, onRemove } = props;
  //const [ suggests, setSuggests ] = useState([]);
  const [ classifications, setClassifications ] = useState([]);
  const [ selected, setSelected ] = useState({});
  const [ loading, setLoading ] = useState(false);

  const classificationsSuggests = classifications.map((classification) => {
    return {
      label: classification.name,
      value: classification.id,
      catalog: classification.catalog.id
    };
  });

  const handleFocus = () => {
    if (classifications.length === 0 && Object.keys(selected).length === 0) {
      if (!!catalogs && catalogs.length > 0) {
        const filters = {
          catalog: catalogs.map((c) => c.value)
        };
        setLoading(true);
        classificationService.getAll(API_PATH, filters).then((newClassifications) => {
          setClassifications(newClassifications && newClassifications["hydra:member"]);
          setLoading(false);
        });
      }
    }
    if (classifications.length === 0 && classificationsSuggests.length === 0 && !!selected && !!selected.label && !!selected.value) {
      classificationsSuggests.push({
        label: selected.label,
        value: selected.value,
        //catalog: selected.catalog,
      });
    }
  };

  const handleSearchChange = (field, value) => {
    const { catalogs, onChange } = props;
    const filters = {
      name: value
    };

    if (!!catalogs && catalogs.length > 0) {
      Object.assign(filters, { catalog: catalogs.map((c) => c.value) });
    }

    onChange(field, value);

    if (value.length > 2) {
      setLoading(true);
      classificationService.getAll(API_PATH, filters).then((newClassifications) => {
        setClassifications(newClassifications && newClassifications["hydra:member"]);
        setLoading(false);
      });
    } /* else {
      setClassifications([]);
    } */
  };

  const handleClear = (field) => {
    const { onClear } = props;

    onClear(field);
    //setClassifications([]);
    setLoading(true);
    classificationService.getAll().then((newClassifications) => {
      setClassifications(newClassifications && newClassifications["hydra:member"]);
      setLoading(false);
    });
  };

  const handleSelect = (field, value) => {
    const { onSelect } = props;

    setSelected(value);
    onSelect(field, value);
  };

  const selectClassName = classNames({
    'input-search-classification': true,
    [className]: !!className,
  });

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();

    if (mounted) {
      setLoading(true);
      const filters = {};

      if (!!catalogs && catalogs.length > 0) {
        Object.assign(filters,  { catalog: catalogs.map((c) => c.value) });
      }
      
      classificationService.getAll(API_PATH, filters, {
        signal: controller.signal
      }).then((newClassifications) => {
        setClassifications(newClassifications && newClassifications["hydra:member"]);
        setLoading(false);
      });
    }

    return () => {
      mounted = false;
      controller?.abort();
    }
  }, [catalogs]);

  //TODO: onRemove ?

  return (
    <InputSearchMultiple
      field={!!field ? field : "classification"}
      label="Classification"
      closeOnSelect
      values={values}
      suggests={classificationsSuggests}
      onClear={handleClear}
      onChange={handleSearchChange}
      onSelect={handleSelect}
      onFocus={handleFocus}
      className={selectClassName}
      loading={loading}
      onRemove={onRemove}
    />
  );
};

export default InputSearchMultipleClassification;