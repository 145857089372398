import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { mediaObjectService } from '../service';
import Spinner from '../../components/Spinner/Spinner';
import Tag from '../../components/Tag/Tag';

import * as tagConstants from '../../components/Tag/constants';

import '../styles/MediaObject.scss';

const MediaObject = (props) => {
  const { logo, className } = props;
  let id = undefined;
  if (typeof logo !== 'undefined' && logo !== null) {
    id = logo.id;
  }
  const [ url, setUrl ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');

  useEffect(() => {
    if (Number.isInteger(id)) {
      setLoading(true);
      mediaObjectService.getById(id).then((logoData) => {
        setUrl(logoData.url);
        setError('');
        setLoading(false);
      }).catch((e) => {
        setUrl('');
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);
      });
    }

    return () => {
      setUrl('');
      setError('');
      setLoading(false);
    };
  }, [id]);

  if (loading) {
    return (
      <Spinner />
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
        {error}
      </div>
    );
  }

  if (url === '') {
    return (
      <div className="mw-3rem">
        <Tag style={tagConstants.QUATERNARY} text="Non" />
      </div>
    );
  }
  const mediaClassName = classnames({
    'media-object': true,
    'mx-auto': true,
    'my-auto': true,
    'img-fluid': true,
    [className]: !!className,
  });

  return (
    <img src={url} alt="logo" className={mediaClassName} />
  );
}

export default MediaObject;