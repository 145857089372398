export const initialProductLine = {
  name: '',
  logo: {},
  laboratory: '',
  brand: '',
};

export const TABLE_HEADERS = [
  {
    field: 'id',
    sortable: false,
    translation: 'Id',
  },
  {
    field: 'name',
    sortable: true,
    translation: 'Nom',
  },
  {
    field: 'brand',
    sortable: false,
    translation: 'Marque',
  },
  {
    field: 'logo',
    sortable: false,
    translation: 'Image',
    className: 'text-center',
  },
  {
    field: 'actions',
    sortable: false,
    translation: 'Actions',
    colSpan: 3
  },
];

export const API_PATH = "api/product_lines";
export const PATH = "/product_lines/";