import React from 'react';
import { Link } from 'react-router-dom';

import ActionsIconTable from '../../../components/ActionsIconTable/ActionsIconTable';
import MediaObject from '../../../MediaObject/components/MediaObject';

import * as brandConstants from '../../../Brand/constants';

const { PATH: brandPath } = brandConstants;

const CustomList = (props) => {
  const {
    items: productLines,
    onDelete,
  } = props;

  const dataBlock = productLines && productLines.map((item) => {
    let brandLink = null;
    if (!!item.brand && !!item.brand.name && !!item.brand.id) {
      brandLink = <Link to={`${brandPath}show/${item.brand.id}`}>{item.brand.name}</Link>;
    }

    return (
      <tr key={item["id"]} id={item.id}>
        <th scope="row">
          <Link to={`show/${encodeURIComponent(item["id"])}`}>
            {item["id"]}
          </Link>
        </th>
        <td>{item["name"]}</td>
        <td>{ brandLink }</td>
        <td>
          <div className="center-logo">
            <MediaObject logo={item["logo"]} />
          </div>
        </td>
        <ActionsIconTable
          item={item}
          onDelete={onDelete}
        />
      </tr>
    );
  });

  return (
    <>
      {
        dataBlock.length === 0
          ?
            (<tr>
              <td colSpan={10}>
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <span>Pas de résultat.</span>
                </div>
              </td>
            </tr>)
          :
            dataBlock
      }
    </>
  );
};

export default CustomList;