// @flow
import React, { type Node, Component } from 'react';
import classNames from 'classnames';

import IconCircleExclamationMark from '../../icons/IconCircleExclamationMark';
//import * as translationsHelpers from '@translations/helpers';

import './styles/FormField.scss';

type Props = {
  className: string,
  disabled: boolean,
  errorLabel: string,
  field: string,
  isOptional: boolean,
  label: string,
  name: string,
  placeholder: string,
  readonly: boolean,
  value: string,
  onBlur: (field: string, value: string) => void,
  onChange: (field: string, value: string) => void,
  onFocus: () => void,
  onKeyPress: (event: Event) => void,
  validator: (field: string, value: string) => boolean,
};

type State = {};

//const { translate } = translationsHelpers;

export class FormField extends Component<Props, State> {
  handleChange = (event: SyntheticInputEvent<EventTarget>): void => {
    const { field, onChange } = this.props;
    event.preventDefault();
    const { value: newValue } = event.target;
    onChange(field, newValue);
  };

  handleBlur = (event: SyntheticInputEvent<EventTarget>): void => {
    const { field, onBlur } = this.props;
    event.preventDefault();
    const { value: newValue } = event.target;
    onBlur(field, newValue);
  };

  handleFocus = (): void => {
    const { onFocus } = this.props;
    onFocus();
  };

  handleKeyPress = (event: Event): void => {
    const { onKeyPress } = this.props;
    onKeyPress(event);
  };

  renderDisabled = (): Node => {
    const { label, isOptional, value } = this.props;

    const labelText = isOptional
      ? `${label} (Optionnel)`
      : label;

    return (
      <span className="form-field-input">{value || labelText}</span>
    );
  };

  renderField = (): Node => {
    const {
      field,
      isOptional,
      label,
      name,
      placeholder,
      readonly,
      value,
    } = this.props;

    const newPlaceholder = placeholder || label;
    const newName = name || field;
    const placeholderText = isOptional
      ? `${newPlaceholder} (Optionnel)`
      : newPlaceholder;

    return (
      <input
        className="form-field-input"
        name={newName}
        placeholder={placeholderText}
        type="text"
        value={value}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onKeyPress={this.handleKeyPress}
        readOnly={readonly}
        autoComplete="off"
      />
    );
  };

  renderError = (): Node => {
    const { errorLabel } = this.props;

    return (
      <p className="form-field-label-error">{errorLabel}</p>
    );
  };

  render = (): Node => {
    const {
      className,
      disabled,
      field,
      isOptional,
      label,
      value,
      validator,
    } = this.props;

    const isValid = validator(field, value);

    const inputClassName = classNames({
      'form-field': true,
      'is-disabled': disabled,
      'is-error': !isValid,
      'with-label': !!label,
      [className]: !!className,
    });

    const labelText = isOptional
      ? `${label} (Optionnel)`
      : label;

    return (
      <div className={inputClassName}>
        <div className="form-field-border">
          <div className="form-field-label">{labelText}</div>
          {!disabled && this.renderField()}

          {!!disabled && this.renderDisabled()}

          {!isValid && (
            <IconCircleExclamationMark className="form-field-icon error" />
          )}
        </div>

        <div className="form-field-label-container">
          {!isValid && this.renderError()}
        </div>
      </div>
    );
  };
}

FormField.defaultProps = {
  className: 'form-field',
  disabled: false,
  errorLabel: '',
  isOptional: false,
  field: '',
  label: '',
  name: '',
  placeholder: '',
  readonly: false,
  value: '',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyPress: () => {},
  validator: () => true,
};

export default FormField;
