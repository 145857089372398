import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { catalogService } from '../service';
import * as catalogConstants from '../constants';

import Form from './Form';

const { PATH } = catalogConstants;

const Edit = (props) => {
  const [ catalog, setCatalog ] = useState(catalogConstants.initialCatalog);
  const [ error, setError ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const { history, location, match } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newCatalog = {...catalog};

    newCatalog[target.name] = target.value;

    setCatalog(newCatalog);
  };

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      catalogService._delete(match.params.id).then(() => {
        setError('');
        setCatalog(catalogConstants.initialCatalog);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        setError(!!e && !!e.message ? e.message : e);
        setSuccess(false);
        setLoading(false);
      });
    }
  };

  const handleEdit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const newCatalog = await catalogService.update(match.params.id, catalog);
      setSuccess(true);
      setError('');
      setCatalog(newCatalog);
      setLoading(false);
    } catch (e) {
      setError(!!e && !!e.message ? e.message : e);
      setSuccess(false);
      setLoading(false);
    }
  };

  useEffect(() => {
     const loadDatas = async () => {
      try {
        setLoading(true);
        const newCatalog = await catalogService.getById(match.params.id);

        setError('');
        setCatalog(newCatalog);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);  
      }
    };

    loadDatas();
  }, [match]);

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!catalog ? '#' + catalog.id : ''
  };

  return (
    <div className="d-flex flex-column">
      {success && (
        <div className="alert alert-success" role="status">
          Catalogue mis à jour.
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      {catalog && (
        <Form
          onSubmit={handleEdit}
          values={catalog}
          onChange={handleChange}
          loading={loading}
        />
      )}
      <div className="d-flex justify-content-between pb-5">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Edit;