// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconCircleExclamationMark = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-circle-exclamation-mark', className),
    onClick,
  };

  return (
    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...newProps}>
      <path fillRule="evenodd" d="M5 0a5 5 0 110 10A5 5 0 015 0zm.046 6.61a.718.718 0 00-.527.22.718.718 0 00-.219.526c0 .205.073.382.22.53a.71.71 0 00.526.225.72.72 0 00.535-.224.732.732 0 00.219-.53.718.718 0 00-.22-.527.728.728 0 00-.534-.22zm.658-4.71H4.37l.167 3.886h1.017l.15-3.886z" />
    </svg>
  );
};

IconCircleExclamationMark.defaultProps = {
  className: 'icon-circle-exclamation-mark',
  onClick: () => {},
};

export default IconCircleExclamationMark;
