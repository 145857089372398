
import { API_HOST } from "../config/config";
import * as mediaObjectConstants from './constants';

import { authHeader, userService } from '../User/service';

const { PATH } = mediaObjectConstants;

//TODO: handle options for controller.abort in each function below ?

const getById = (id) => {
  const requestOptions = {
    method: 'GET',
    headers: new Headers({ 
      ...authHeader(),
      'Content-Type': 'application/json',
    }),
  };

  return fetch(`${API_HOST}${PATH}/${id}`, requestOptions).then(userService.handleResponse);
};

const createMediaObject = (file) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      ...authHeader(),
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({})
  };

  return fetch(`${API_HOST}${PATH}`, requestOptions).then(userService.handleResponse);
};

const updateMediaObject = (id, file) => {
  const requestOptions = {
    method: 'PUT',
    headers: new Headers({
      ...authHeader(),
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
    body: JSON.stringify(file)
  };

  return fetch(`${API_HOST}${PATH}/${id}`, requestOptions).then(userService.handleResponse);
};

const uploadMediaObject = (url, file) => {
  const realFile = new File([file], file.name);
  const requestOptions = {
    method: 'PUT',
    body: realFile,
  };

  return fetch(url, requestOptions).then(userService.handleResponse);
};

export const mediaObjectService = {
  createMediaObject,
  updateMediaObject,
  uploadMediaObject,
  getById,
};
