import React, { useState } from 'react';

import IconSimpleArrow from '../../icons/IconSimpleArrow';

import { laboratoryService } from '../service';
import { mediaObjectService } from '../../MediaObject/service';
import * as laboratoryConstants from '../constants';

import Form from './Form';

const { PATH } = laboratoryConstants;

const Create = (props) => {
  const [ laboratory, setLaboratory ] = useState(laboratoryConstants.initialLaboratory);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const { history } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newLaboratory = {...laboratory};

    newLaboratory[target.name] = target.value;

    setLaboratory(newLaboratory);
  };

  const handleChangeNameField = (field, value) => {
    const newLaboratory = {...laboratory};

    newLaboratory[field] = value;

    setLaboratory(newLaboratory);
  };

  const handleCreate = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const payload = { ...laboratory };
      payload.brands = payload.brands.map((brand) => brand.value);
       if (payload.logo instanceof File) {
        const uploadedFile = await mediaObjectService.createMediaObject();
        await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, payload.logo);
        payload.logo = uploadedFile.id;
      } else {
        delete payload.logo;
      }
      laboratoryService.create(payload).then((newLab) => {
        //TODO: add success msg ?
        setError('');
        setLoading(false);
        if (!!newLab && !!newLab.id) {
          history.push(`${PATH}edit/${newLab.id}`);
        }
      });
    } catch (e) {
      //TODO: set initial lab ?
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="go-back" onClick={() => history.push(PATH)}>
        <IconSimpleArrow />
        <span>Retour</span>
      </div>

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}

      <Form
        className="mb-5 form-laboratory"
        onSubmit={handleCreate}
        values={laboratory}
        onChange={handleChange} 
        onChangeNameField={handleChangeNameField}
        loading={loading}
      />
    </div>
  );
};

export default Create;