import React from 'react';
import { Link } from "react-router-dom";
import qs from 'qs';

const PaginationWithSearch = ({ view, filters }) => {
  let {
    "hydra:first": first,
    "hydra:previous": previous,
    "hydra:next": next,
    "hydra:last": last,
    "@id": current,
  } = view;
  let paramsToFirst = {};
  let paramsToPrevious = {};
  let paramsToNext = {};
  let paramsToLast = {};
  let page = 1;

  if (!!view && !!view["hydra:first"]) {
    page = current.split('page=')[1];
    first = '?' + first.split('?')[1];
    let firstSearch = first;
    if (!!first) {
      const firstSearchSplitted = firstSearch.split('?')[1];
      const firstSearchParams = qs.parse(firstSearchSplitted, { ignoreQueryPrefix: true });
      if (!!firstSearchParams.laboratory && !firstSearchParams.laboratory.value) {
        firstSearchParams.laboratory = filters.laboratory;
      }
      if (!!firstSearchParams.brand && !firstSearchParams.brand.value) {
        firstSearchParams.brand = filters.brand;
      }
      if (!!firstSearchParams.productLine && !firstSearchParams.productLine.value) {
        firstSearchParams.productLine = filters.productLine;
      }
      firstSearch = encodeURIComponent(qs.stringify(firstSearchParams, { ignoreQueryPrefix: true }));
      paramsToFirst = {pathname: ".", search: firstSearch };
    }

    let previousSearch = previous;
    if (!!previous) {
      const previousSearchSplitted = previousSearch.split('?')[1];
      const previousSearchParams = qs.parse(previousSearchSplitted, { ignoreQueryPrefix: true });
      if (!!previousSearchParams.laboratory && !previousSearchParams.laboratory.value) {
        previousSearchParams.laboratory = filters.laboratory;
      }
      if (!!previousSearchParams.brand && !previousSearchParams.brand.value) {
        previousSearchParams.brand = filters.brand;
      }
      if (!!previousSearchParams.productLine && !previousSearchParams.productLine.value) {
        previousSearchParams.productLine = filters.productLine;
      }
      previousSearch = encodeURIComponent(qs.stringify(previousSearchParams, { ignoreQueryPrefix: true }));
      paramsToPrevious = {pathname: ".", search: previousSearch };
    }

    let nextSearch = next;
    if (!!next) {
      const nextSearchSplitted = nextSearch.split('?')[1];
      const nextSearchParams = qs.parse(nextSearchSplitted, { ignoreQueryPrefix: true });
      if (!!nextSearchParams.laboratory && !nextSearchParams.laboratory.value) {
        nextSearchParams.laboratory = filters.laboratory;
      }
      if (!!nextSearchParams.brand && !nextSearchParams.brand.value) {
        nextSearchParams.brand = filters.brand;
      }
      if (!!nextSearchParams.productLine && !nextSearchParams.productLine.value) {
        nextSearchParams.productLine = filters.productLine;
      }
      nextSearch = encodeURIComponent(qs.stringify(nextSearchParams, { ignoreQueryPrefix: true }));
      paramsToNext = {pathname: ".", search: nextSearch };
    }

    last = '?' + last.split('?')[1];
    let lastSearch = last;
    if (!!last) {
      const lastSearchSplitted = lastSearch.split('?')[1];
      const lastSearchParams = qs.parse(lastSearchSplitted, { ignoreQueryPrefix: true });
      if (!!lastSearchParams.laboratory && !lastSearchParams.laboratory.value) {
        lastSearchParams.laboratory = filters.laboratory;
      }
      if (!!lastSearchParams.brand && !lastSearchParams.brand.value) {
        lastSearchParams.brand = filters.brand;
      }
      if (!!lastSearchParams.productLine && !lastSearchParams.productLine.value) {
        lastSearchParams.productLine = filters.productLine;
      }
      lastSearch = encodeURIComponent(qs.stringify(lastSearchParams, { ignoreQueryPrefix: true }));
      paramsToLast = {pathname: ".", search: lastSearch };
    }
  }

  return (
    <nav aria-label="Page navigation" className="d-flex justify-content-around">
      <Link
        to={paramsToFirst}
        className={`btn btn-primary${previous ? "" : " disabled"}`}
        replace
      >
        <span aria-hidden="true">&lArr;</span> Début
      </Link>
      <Link
        to={
          !previous || previous === first ? "." : paramsToPrevious
        }
        className={`btn btn-primary${previous ? "" : " disabled"}`}
        replace
      >
        <span aria-hidden="true">&larr;</span> Précédent
      </Link>
      <span className="d-flex align-items-center me-1 ms-1">
        <strong>
          <u>
            { page }
          </u>
        </strong>
      </span>
      <Link
        to={next ? paramsToNext : "#"}
        className={`btn btn-primary${next ? "" : " disabled"}`}
        replace
      >
        Suivant <span aria-hidden="true">&rarr;</span>
      </Link>
      <Link
        to={last ? paramsToLast : "#"}
        className={`btn btn-primary${next ? "" : " disabled"}`}
        replace
      >
        Fin <span aria-hidden="true">&rArr;</span>
      </Link>
    </nav>
  );
}

export default PaginationWithSearch;