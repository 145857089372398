import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { productLineService } from '../service';
import * as productLineConstants from '../constants';
import * as brandConstants from '../../Brand/constants';

import MediaObject from '../../MediaObject/components/MediaObject';
import Spinner from '../../components/Spinner/Spinner';

const { PATH } = productLineConstants;
const { PATH: brandPath } = brandConstants;

const Show = (props) => {
  const [ productLine, setProductLine ] = useState(productLineConstants.initialProductLine);
  const [ error, setError ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const { history, location, match } = props;

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      productLineService._delete(match.params.id).then(() => {
        setError('');
        setProductLine(productLineConstants.initialProductLine);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }     
      }).catch((e) => {
        //TODO: setProductLine ?
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    const loadDatas = async () => {
      try {
        setLoading(true);
        const newProductLine = await productLineService.getById(match.params.id);
        setProductLine(newProductLine);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);  
      }
    };

    loadDatas();
  }, [match]);

  if (loading) {
    return (
      <div className="d-flex flex-column h-100">
        <Spinner className="d-flex align-items-center h-100 justify-content-center" />
      </div>
    );
  }

  let createdAt = undefined;
  if (!isNaN(Date.parse(productLine.createdAt))) {
    const createdAtDate = new Date(productLine.createdAt);
    createdAt = createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAt = undefined;
  if (!isNaN(Date.parse(productLine.updatedAt))) {
    const updatedAtDate = new Date(productLine.updatedAt);
    updatedAt = updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }

  let brandLink = null;
  if (!!productLine.brand && !!productLine.brand.name) {
    brandLink = <Link to={`${brandPath}show/${productLine.brand.id}`}>{productLine.brand.name}</Link>
  }

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!productLine ? '#' + productLine.id : ''
  };
  const paramsForEdit = {
    ...paramsForBack,
    pathname: `${PATH}edit/${encodeURIComponent(productLine["id"])}`
  };

  return (
    <div className="d-flex flex-column pb-3">
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}
      {productLine && (
        <table className="table table-responsive table-striped table-hover">
          <thead>
            <tr>
              <th>Champ</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Nom</th>
              <td key="product_line_name">{productLine["name"]}</td>
            </tr>
            <tr>
              <th scope="row">Marque</th>
              <td key="product_line_name">{brandLink}</td>
            </tr>
            <tr>
              <th scope="row">Produits</th>
              <td key="product_line_name">{productLine["products"]}</td>
            </tr>
            <tr>
              <th scope="row">Logo</th>
              <td key="product_line_logo">
                <MediaObject logo={productLine["logo"]} />
              </td>
            </tr>
            <tr>
              <th scope="row">Date de création</th>
              <td>{ createdAt }</td>
            </tr>
            <tr>
              <th scope="row">Date de modification</th>
              <td>{ updatedAt }</td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="d-flex justify-content-between mb-3 pb-3">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        {productLine && (
          <Link to={paramsForEdit} className="btn btn-warning">
            Editer
          </Link>
        )}
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Show;