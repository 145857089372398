import React from 'react';

import BlockContainer from '../../../components/BlockContainer/BlockContainer';
import ExpansionPanel from '../../../components/ExpansionPanel/ExpansionPanel';
import TextEditor from '../../../components/TextEditor/TextEditor';

const FormInformationsComplementary = (props) => {
  const { advice, caution, composition, notice, onChangeNameField } = props;

  return (
    <BlockContainer title="Infos complémentaires produit">
      <ExpansionPanel title='Conseils' active={false}>
        <TextEditor 
          initialValue={advice}
          onChange={onChangeNameField}
          onFocus={() => {}}
          field="advice"
          withImage
        />
      </ExpansionPanel>
      <ExpansionPanel title='Précautions' active={false}>
        <TextEditor 
          initialValue={caution}
          onChange={onChangeNameField}
          onFocus={() => {}}
          field="caution"
          withImage
        />
      </ExpansionPanel>
      <ExpansionPanel title='Composition' active={false}>
        <TextEditor 
          initialValue={composition}
          onChange={onChangeNameField}
          onFocus={() => {}}
          field="composition"
          withImage
        />
      </ExpansionPanel>
      <ExpansionPanel title='Notice' active={false}>
        <TextEditor 
          initialValue={notice}
          onChange={onChangeNameField}
          onFocus={() => {}}
          field="notice"
          withImage
        />
      </ExpansionPanel>
    </BlockContainer>
  );
};

export default FormInformationsComplementary;