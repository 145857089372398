class Storage {

  get(key) {
    const serialized = localStorage.getItem(key);
    return serialized
      ? JSON.parse(serialized)
      : null;
  }

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

  getConnectedUser() {
    const session = this.get('session');

    const connectedUser = !session ? null : !session.user ? null : session.user;

    return connectedUser;
  }

}

export default new Storage();