import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { laboratoryService } from '../service';
import * as laboratoryConstants from '../constants';

import MediaObject from '../../MediaObject/components/MediaObject';
import Spinner from '../../components/Spinner/Spinner';

const { PATH } = laboratoryConstants;

const Show = (props) => {
  const [ laboratory, setLaboratory ] = useState(laboratoryConstants.initialLaboratory);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const { history, location, match } = props;

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      laboratoryService._delete(match.params.id).then(() => {
        setError('');
        setLaboratory(laboratoryConstants.initialLaboratory);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }     
      }).catch((e) => {
        //TODO: setCategory initial ?
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);
      });
    }
  };

  const renderLinks = (type, items) => {
    return items.map((item) => {
      return (
        <div key={type + '-' + item.id}>
          <Link to={`/${type}/show/${encodeURIComponent(item.id)}`}>{item.name}</Link>
        </div>
      );
    })
  };

  useEffect(() => {
    setLoading(true);
    laboratoryService.getById(match.params.id).then((newLab) => {
      setError('');
      setLaboratory(newLab);
      setLoading(false);
    }).catch((e) => {
      //TODO: setCategory initial ?
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    });
  }, [match]);

  if (loading) {
    return (
      <div className="d-flex flex-column h-100">
        <Spinner className="d-flex align-items-center h-100 justify-content-center" />
      </div>
    );
  }

  let createdAt = undefined;
  if (!isNaN(Date.parse(laboratory.createdAt))) {
    const createdAtDate = new Date(laboratory.createdAt);
    createdAt = createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAt = undefined;
  if (!isNaN(Date.parse(laboratory.updatedAt))) {
    const updatedAtDate = new Date(laboratory.updatedAt);
    updatedAt = updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!laboratory ? '#' + laboratory.id : ''
  };
  const paramsForEdit = {
    ...paramsForBack,
    pathname: `${PATH}edit/${encodeURIComponent(laboratory["id"])}`
  };

  return (
    <div className="d-flex flex-column pb-3">
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}
      {laboratory && (
        <table className="table table-responsive table-striped table-hover">
          <thead>
            <tr>
              <th>Champ</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Nom</th>
              <td>{laboratory["name"]}</td>
            </tr>
            <tr>
              <th scope="row">Téléphone</th>
              <td>{laboratory["phone"]}</td>
            </tr>
            <tr>
              <th scope="row">Site web</th>
              <td>{laboratory["website"]}</td>
            </tr>
            <tr>
              <th scope="row">Adresse</th>
              <td>{laboratory["address"]}</td>
            </tr>
            <tr>
              <th scope="row">Marques</th>
              <td>{renderLinks(
                "brands",
                laboratory["brands"]
              )}</td>
            </tr>
            <tr>
              <th scope="row">Image</th>
              <td key="laboratory_logo">
                <MediaObject logo={laboratory["logo"]} />
              </td>
            </tr>
            <tr>
              <th scope="row">Date de création</th>
              <td>{ createdAt }</td>
            </tr>
            <tr>
              <th scope="row">Date de modification</th>
              <td>{ updatedAt }</td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="d-flex justify-content-between mb-3 pb-3">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        {laboratory && (
          <Link to={paramsForEdit} className="btn btn-warning">
            Editer
          </Link>
        )}
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Show;