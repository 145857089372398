// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconAddressBook = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-address-book', className),
    onClick,
  };

  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" {...newProps}>
      <path d="M15.096 16H2.006C.782 16 0 14.583 0 13.606c0-.1.006-.211.017-.32A.886.886 0 010 13.114V1.496c0-.792 1.122-1.5 2.006-1.5h10.19a.907.907 0 01.642.267.9.9 0 01.261.642L12.95 11.88a.9.9 0 01-.9.882H2.03a2.005 2.005 0 00-.223.844 1.041 1.041 0 00.271.6h12.115V3.082a.9035.9035 0 011.807 0v12.023a.9.9 0 01-.904.895zM1.807 1.85v9.122h9.343l.127-9.181H2.006a.45.45 0 00-.199.059z" />
      <path d="M9.277 3.335H3.588a.467.467 0 100 .931h5.689a.467.467 0 10.074-.931.457.457 0 00-.074 0zM9.277 5.059H3.588a.467.467 0 100 .931h5.689a.467.467 0 10.074-.931.457.457 0 00-.074 0z" />
    </svg>
  );
};

IconAddressBook.defaultProps = {
  className: 'icon-address-book',
  onClick: () => {},
};

export default IconAddressBook;
