import React, { useState } from 'react';

import IconSimpleArrow from '../../icons/IconSimpleArrow';

import { catalogService } from '../service';
import * as catalogConstants from '../constants';

import Form from './Form';

const { PATH } = catalogConstants;

const Create = (props) => {
  const [ catalog, setCatalog ] = useState(catalogConstants.initialCatalog);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const { history } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newCatalog = {...catalog};

    newCatalog[target.name] = target.value;

    setCatalog(newCatalog);
  };

  const handleCreate = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const payload = { ...catalog };
      const newCatalog = await catalogService.create(payload);
      setError('');
      setLoading(false);
      if (!!newCatalog && !!newCatalog.id) {
        history.push(`${PATH}edit/${newCatalog.id}`);
      }
    } catch (e) {
      //TODO: setCatalog(catalogConstants.initialcatalog); ?
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="go-back" onClick={() => history.push(PATH)}>
        <IconSimpleArrow />
        <span>Retour</span>
      </div>

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}

      <Form
        className="mb-5"
        onSubmit={handleCreate}
        values={catalog}
        onChange={handleChange}
        loading={loading}
      />
    </div>
  );
};

export default Create;