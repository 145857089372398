import React from 'react';

import BlockContainer from '../../../components/BlockContainer/BlockContainer';
import ExpansionPanel from '../../../components/ExpansionPanel/ExpansionPanel';
import TextEditor from '../../../components/TextEditor/TextEditor';

const FormDescriptions = (props) => {
  const { shortDescription, description, onChangeNameField } = props;

  return (
    <BlockContainer title="Texte produit">
      <ExpansionPanel title='Description courte' active={false}>
        <TextEditor
          initialValue={shortDescription}
          onChange={onChangeNameField}
          onFocus={() => {}}
          field="shortDescription"
          height={200}
        />
      </ExpansionPanel>

      <ExpansionPanel title='Description' active={false}>
        <TextEditor 
          initialValue={description}
          onChange={onChangeNameField}
          onFocus={() => {}}
          field="description"
          withImage
        />
      </ExpansionPanel>
    </BlockContainer>
  );
};

export default FormDescriptions;