import React from 'react';
import { withRouter } from "react-router";

import Button from '../Button/Button';

import * as globalHelpers from '../../utils/globalHelpers';

const HeaderParamsList = (props) => {
  const {
    history,
    itemsPerPage,
    noCreate,
    page,
    path,
    total,
    onChangeItemsPerPage,
  } = props;

  return (
    <div className="d-flex justify-content-between">
      <div>
        <div>
          <label htmlFor="change_items_per_page">Éléments / page : </label>
          <select
            name="change_items_per_page"
            onChange={onChangeItemsPerPage}
            value={itemsPerPage}
            className="ms-2"
          >
            <option>10</option>
            <option>20</option>
            <option>30</option>
            <option>50</option>
            <option>100</option>
          </select>
        </div>
        { globalHelpers.generateTotalPagination(itemsPerPage, page, total) }
      </div>
      {
        !noCreate && 
          (
            <Button className="btn-secondary" onClick={() => history.push(`${path}create`)}>
              Ajouter
            </Button>
          )
      }
      
    </div>
  );
};

HeaderParamsList.defaultProps = {
  noCreate: false
};

export default withRouter(HeaderParamsList);