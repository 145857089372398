// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';

//import type { Option } from '@shared/Select/models';
import { Tag, tagConstants } from '../Tag';
import IconCircleCross from '../../icons/IconCircleCross';

import './styles/InputSearchMultipleList.scss';

type Props = {
  className: string,
  disabled: boolean,
  readonly: boolean,
  //values: Option[],
  values: any[],
  //onRemove: (value: Option) => void,
  onRemove: (value: any) => void,
};

export const InputSearchMultipleList = (props: Props): Node | null => {
  const {
    disabled,
    className,
    onRemove,
    readonly,
    values,
  } = props;

  const getTagProps = (value: Option): Object => {
    const tagProps = {
      style: tagConstants.SECONDARY,
      text: value.label,
      icon: null,
    };

    if (!readonly && !disabled) {
      tagProps.icon = <IconCircleCross onClick={() => onRemove(value)} />;
    }

    return tagProps;
  };

  const inputSearchMultipleListClassName = classNames({
    'form-input-search-multiple-list': true,
    [className]: !!className,
  });

  if (values.length === 0) {
    return null;
  }

  return (
    <div className={inputSearchMultipleListClassName}>
      {values.map((value, index) => (
        <Tag key={index} {... getTagProps(value)} />
      ))}
    </div>
  );
};

InputSearchMultipleList.defaultProps = {
  className: 'form-input-search-multiple-list',
  disabled: false,
  readonly: false,
  values: [],
  onRemove: () => {},
};

export default InputSearchMultipleList;
