import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import ActionsIconTable from '../../../components/ActionsIconTable/ActionsIconTable';
import MediaObject from '../../../MediaObject/components/MediaObject';
import Tag from '../../../components/Tag/Tag';

import * as productHelpers from '../../helpers';

import * as categoryConstants from '../../../Category/constants';
import * as brandConstants from '../../../Brand/constants';
import * as laboratoryConstants from '../../../Laboratory/constants';
import * as productLineConstants from '../../../ProductLine/constants';
import * as tagConstants from '../../../components/Tag/constants';

const { PATH: categoryPath } = categoryConstants;
const { PATH: brandPath } = brandConstants;
const { PATH: laboratoryPath } = laboratoryConstants;
const { PATH: productLinePath } = productLineConstants;

const CustomList = (props) => {
  const {
    items: products,
    deletingIds,
    isCategoriesDisplayed,
    isDescriptionDisplayed,
    onDelete,
    onSelectedForDeleting,
    setModalPicture,
    setModalCategories,
  } = props;

  const renderLinksCategories = (categories, itemId) => {
    let levelFamily = 0;
    return categories.map((cat) => {
      levelFamily++;
      return cat.map((c) => {
        return (
          <div className='d-block' key={`${itemId}-${c.id}-${levelFamily}`}>
            <span>
              { `${'-'.repeat(levelFamily)}> `}
              <Link to={`${categoryPath}show/${encodeURIComponent(c.id)}`}>
                {c.name}
              </Link>
            </span>
          </div>
        );
      });
    });
  };

  const dataBlock = products && products.map((item) => {
    let shortDescription = (item["shortDescription"].length > 0 && item["shortDescription"].slice(0, 300));
    if (item['shortDescription'].length > 300) {
      shortDescription += '...';
    }

    let picturesBlock = <div className="center-tag"><div className="mw-3rem"><Tag style={tagConstants.QUATERNARY} text="Non" /></div></div>;
    if (!!item['productFiles'] && item['productFiles'].length > 0) {
      picturesBlock = item['productFiles'].map((picture, index) => {
        const classNameProductFile = item['productFiles'].length > 1 && index < item['productFiles'].length ? 'mb-2' : '';
        return (
          <div onClick={() => setModalPicture(picture.mediaObject)} key={`media-object-${picture.name}-${index}`} className={classNameProductFile}>
            <MediaObject logo={picture.mediaObject} />
          </div>
        );
      });
      picturesBlock = <div className="pictures overflow-div scroll-custom">{picturesBlock}</div>
    }

    let laboratoryLink = null;
    if (!!item.laboratory && !!item.laboratory.name && !!item.laboratory.id) {
      laboratoryLink = <Link to={`${laboratoryPath}show/${item.laboratory.id}`}>{item.laboratory.name}</Link>;
    }

    let brandLink = null;
    if (!!item.brand && !!item.brand.name && !!item.brand.id) {
      brandLink = <Link to={`${brandPath}show/${item.brand.id}`}>{item.brand.name}</Link>;
    }

    let productLineLink = null;
    if (!!item.productLine && !!item.productLine.name && !!item.productLine.id) {
      productLineLink = <Link to={`${productLinePath}show/${item.productLine.id}`}>{item.productLine.name}</Link>;
    }

    const isSelectedForDeleting = deletingIds.find((deletingId) => parseInt(deletingId, 10) === item.id);

    return (
      <tr key={'product-' + item["id"]} id={item.id}>
        <th scope="row" className="id">
          <div className="d-flex justify-content-center align-items-center overflow-div">
            <input className="form-check-input" type="checkbox" onChange={onSelectedForDeleting} value={item['id']} checked={!!isSelectedForDeleting} />
          </div>
        </th>
        <td>{ laboratoryLink }</td>
        <td>{ brandLink }</td>
        <td>{ productLineLink }</td>
        <td>{ item["name"] }</td>
        <td>{ item["EAN13"] }</td>
        <td>{ item["ACL13"] }</td>
        <td>{ item["CIP7"] }</td>
        { isCategoriesDisplayed && (
          <td className="categories">
            <div className="block">
              <div className="center-line">
                <span onClick={async () => setModalCategories(await productHelpers.parseProductIn(_.cloneDeep(item)))}>
                  <i className="fa fa-pencil icon-actions" aria-hidden="true" />
                  <i className="sr-only">Editer catégories</i>
                </span>
              </div>
              <div className="overflow-div scroll-custom">
                { renderLinksCategories(item["categories"], item.id) }
              </div>
            </div>
          </td>
        )}
        { isDescriptionDisplayed && (
          <td>
            {item["shortDescription"].length > 0 && (
              <div className="overflow-div description scroll-custom" dangerouslySetInnerHTML={{
                __html: shortDescription
              }}></div>
            )}
          </td>
        )}
        <td>{ picturesBlock }</td>
        <ActionsIconTable
          item={item}
          onDelete={onDelete}
        />
      </tr>
    )
  });

  return (
    <>
      {
        dataBlock.length === 0
          ?
            (<tr>
              <td colSpan={10}>
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <span>Pas de résultat.</span>
                </div>
              </td>
            </tr>)
          :
            dataBlock
      }
    </>
  );
};

export default CustomList;