export const initialLaboratory = {
  name: '',
  phone: '',
  website: '',
  brands: [],
  logo: {},
};

export const TABLE_HEADERS = [
  {
    field: 'id',
    sortable: false,
    translation: 'Id',
  },
  {
    field: 'name',
    sortable: true,
    translation: 'Nom',
  },
  {
    field: 'phone',
    sortable: false,
    translation: 'Téléphone',
  },
  {
    field: 'website',
    sortable: false,
    translation: 'Site web',
  },
  {
    field: 'logo',
    sortable: false,
    translation: 'Image',
    className: 'text-center',
  },
  {
    field: 'actions',
    sortable: false,
    translation: 'Actions',
    colSpan: 3
  },
];

export const API_PATH = "api/laboratories";
export const PATH = "/laboratories/";