// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconHome = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-home', className),
    onClick,
  };

  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" {...newProps}>
      <path d="M12.9 16H9.635a.913.913 0 01-.915-.911V11.27H7.28v3.819a.913.913 0 01-.915.912h-3.27a.913.913 0 01-.915-.912V8.906H.915a.912.912 0 01-.649-1.555L7.352.257a.944.944 0 011.3 0l7.085 7.093a.912.912 0 01-.648 1.555h-1.27v6.184A.913.913 0 0112.9 16zm-2.355-1.824h1.44V7.994a.914.914 0 01.893-.912L8 2.189 3.116 7.082a.914.914 0 01.892.912v6.181H5.45v-3.817a.913.913 0 01.915-.912h3.27a.913.913 0 01.915.912z" />
    </svg>
  );
};

IconHome.defaultProps = {
  className: 'icon-home',
  onClick: () => {},
};

export default IconHome;
