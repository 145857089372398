import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { bannerService } from '../service';
import { mediaObjectService } from '../../../MediaObject/service';

import * as bannerConstants from '../constants';

import Form from './Form';

const { PATH } = bannerConstants;

const Edit = (props) => {
  const [ banner, setBanner ] = useState(bannerConstants.initialBanner);
  const [ error, setError ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const { history, location, match } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newBanner = {...banner};

    newBanner[target.name] = target.value;

    setBanner(newBanner);
  };

  const handleChangeNameField = (field, value) => {
    const newBanner = {...banner};

    newBanner[field] = value;

    setBanner(newBanner);
  };


  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      bannerService._delete(match.params.id).then(() => {
        setError('');
        setBanner(bannerConstants.initialBanner);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        setError(!!e && !!e.message ? e.message : e);
        setSuccess(false);
        setLoading(false);
      });
    }
  };

  const handleEdit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const payload = {...banner};
       const filesToUpload = payload.bannerFiles.filter((file) => { return (file.mediaObject instanceof File); });
      const filesAlreadyUploaded = payload.bannerFiles
        .filter((file) => { return !(file.mediaObject instanceof File); })
        .map((file) => ({ 
          ...file,
          mediaObject: file.mediaObject.id,
        }));

      for (let i = 0; i < filesToUpload.length; i++) {
        const uploadedFile = await mediaObjectService.createMediaObject();
        await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, filesToUpload[i].mediaObject);
        const fileToUpload = {
          ...filesToUpload[i],
          mediaObject: uploadedFile.id,
        };
        filesAlreadyUploaded.push(fileToUpload);
      }
      payload.bannerFiles = filesAlreadyUploaded;
      const newBanner = await bannerService.update(match.params.id, payload);
      setSuccess(true);
      setError('');
      setBanner(newBanner);
      setLoading(false);
    } catch (e) {
      setError(!!e && !!e.message ? e.message : e);
      setSuccess(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadDatas = async () => {
      try {
        setLoading(true);
        const newBanner = await bannerService.getById(match.params.id);

        setError('');
        setBanner(newBanner);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);  
      }
    };

    loadDatas();
  }, [match]);

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!banner ? '#' + banner.id : ''
  };

  return (
    <div className="d-flex flex-column">
      {success && (
        <div className="alert alert-success" role="status">
          Bandeau mis à jour.
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      {banner && (
        <Form
          className="form-template-banner"
          onSubmit={handleEdit}
          values={banner}
          onChange={handleChange}
          onChangeNameField={handleChangeNameField}
          loading={loading}
          onError={setError}
        />
      )}
      <div className="d-flex justify-content-between pb-5">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Edit;