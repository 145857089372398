import React, { Fragment, useEffect, useState } from 'react';
import qs from 'qs';

import { productService } from '../../service';

import Filters from './Filters';
import HeaderParamsList from '../../../components/HeaderParamsList/HeaderParamsList';
import IconCheck from '../../../icons/IconCheck';
import IconWarning from '../../../icons/IconWarning';
import PaginationWithSearch from '../../../components/Pagination/PaginationWithSearch';
import Spinner from '../../../components/Spinner/Spinner';
import Table from '../../../components/Table/Table';
import TableHeader from '../../../components/Table/TableHeader';

import * as productConstants from '../../constants';
import '../../styles/Imports.scss';

const { API_PATH_IMPORTS, PATH_IMPORTS } = productConstants;

const defaultParams = {
  itemsPerPage: 10,
  page: 1,
  order: {
    importDate: 'asc',
  }
};

const Imports = (props) => {
  const { history } = props;
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const [ imports, setImports ] = useState([]);
  const [ isExpandedRows, setIsExpandedRows ] = useState([]);
  const [ urlsPagination, setUrlsPagination ] = useState({});
  const [ total, setTotal ] = useState(0);
  const search = decodeURIComponent(history.location.search);
  const params = search === "" ? defaultParams : qs.parse(search, { ignoreQueryPrefix: true });
  const [ searchParams, setSearchParams ] = useState(params);
  const order = !!searchParams.order ? searchParams.order : defaultParams.order;
  const [ itemsPerPage, setItemsPerPage ] = useState(!!searchParams.itemsPerPage ? searchParams.itemsPerPage : defaultParams.itemsPerPage);

  const handleExpandedRow = (itemId) => {
    const newIsExpandedRows = isExpandedRows.map((isExpandedRow) => {
      if (isExpandedRow.id === itemId) {
        isExpandedRow.isExpanded = !isExpandedRow.isExpanded;
      }
      return isExpandedRow;
    });

    setIsExpandedRows(newIsExpandedRows);
  };

  const handleSubmit = (newFilters) => {
    let searchParamsString = '';
    const newFiltersQS = {
      page: defaultParams.page,
      itemsPerPage: !!searchParams.itemsPerPage ? searchParams.itemsPerPage : defaultParams.itemsPerPage,
      order: !!searchParams.order ? searchParams.order : defaultParams.order,
      ...newFilters,
    };
    searchParamsString = qs.stringify(newFiltersQS, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH_IMPORTS}`, search: encodeURIComponent(searchParamsString)});
    setSearchParams(newFiltersQS);
  };

  const handleChangeItemsPerPage = (e) => {
    setItemsPerPage(e.target.value);

    const newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams.itemsPerPage = parseInt(e.target.value, 10);
    setSearchParams(newSearchParams);

    let searchParamsString = '';
    searchParamsString = qs.stringify(newSearchParams, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH_IMPORTS}`, search: encodeURIComponent(searchParamsString)});
  };

  const handleChangeOrder = (column, direction) => {
    const newSearchParams = {
      ...searchParams,
      page: 1,
    };
    newSearchParams.order = {
      [column]: direction
    };
    setSearchParams(newSearchParams);

    let searchParamsString = '';
    searchParamsString = qs.stringify(newSearchParams, { ignoreQueryPrefix: true });
    history.replace({ pathname: `${PATH_IMPORTS}`, search: encodeURIComponent(searchParamsString)});
  };

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();

    if (mounted) {
      const search = decodeURIComponent(history.location.search);
      if (qs.stringify(searchParams, { addQueryPrefix: true }) !== search) {
        setSearchParams(qs.parse(search, { ignoreQueryPrefix: true }))
      } else {
        setLoading(true);
        const endpoint = `${API_PATH_IMPORTS}`;
        if (!searchParams.itemsPerPage) {
          searchParams.itemsPerPage = defaultParams.itemsPerPage;
        }
        if (!searchParams.order) {
          searchParams.order = defaultParams.order;
        }
        productService.getImports(endpoint, searchParams, {
          signal: controller.signal
        }).then((newImports) => {
          setError('');
          setImports(newImports["hydra:member"]);
          setUrlsPagination(newImports["hydra:view"]);
          setTotal(newImports["hydra:totalItems"]);

          const expandedRows = newImports["hydra:member"].map((mockImport) => {
            return {
              id: mockImport.id,
              isExpanded: false,
            };
          });
          setIsExpandedRows(expandedRows);
          setLoading(false);

          if (history.location.hash) {
            const id = history.location.hash.replace('#', '');
            if (!!id) {
              const found = newImports["hydra:member"].find((newImport) => newImport.id.toString() === id);
              if (found) {
                const ofTop = document.getElementById(id).offsetTop;
                document.getElementById('block-table-products-imports').scrollTop = ofTop - 65;
              }
            }
          }
        }).catch((e) => {
          setError(!!e && !!e.message ? e.message : e);
          setImports([]);
          setIsExpandedRows([]);
          setUrlsPagination({});
          setTotal(0);
          setLoading(false);
        });
      }
    }

    return () => {
      mounted = false;
      controller?.abort();
    }
  }, [searchParams, history.location.search, history.location.hash]);

  const dataBlock = imports && imports.map((item) => {
    const importedAt = new Date(item.importDate);

    let status = <IconCheck className="icon-cell-actions success" />;
    if (item.importDate && Object.entries(item.logs).length > 0) {
      status = <IconWarning className="icon-cell-actions warning" />;
    }

    let rowExpanded = null;
    const isItExpanded = isExpandedRows.find((isExpandedRow) => isExpandedRow.id === item.id);
    if (!!isItExpanded && !!isItExpanded.isExpanded) {
      rowExpanded = Object.keys(item.logs).map((log, index) => {
        const logs = Object.entries(item.logs[log]).map((logLine, indexLog) => {
          return (
            <tr className="tr-log-errors" key={'line-log-' + indexLog}>
              <td colSpan={1} className="bg-info text-center">{log} : { logLine[0] }</td>
              <td colSpan={4} className="bg-warning">{ logLine[1] }</td>
            </tr>
          );
        });
        return <Fragment key={'log-' + item.id + '-' + index}>{logs}</Fragment>;
      });
    }

    return (
      <Fragment key={'products-import-' + item["id"]} >
        <tr id={item.id}>
          <td>{ item.file && item.file.originalName }</td>
          <td>{ importedAt.toLocaleDateString() }</td>
          <td>{ status }</td>
          <td colSpan={1}>
            { Object.keys(item.logs).length > 0 && (
              <div className="center-line">
                <span onClick={() => handleExpandedRow(item.id)}>
                  <i className="fa fa-search icon-actions" aria-hidden="true" />
                  <i className="sr-only">Voir</i>
                </span>
              </div>
            )}
          </td>
        </tr>
        { rowExpanded }
      </Fragment>
    );
  });

  const tableHeaders = [
    {
      field: 'file.name',
      sortable: false,
      translation: 'Nom de fichier',
    },
    {
      field: 'importDate',
      sortable: true,
      translation: 'Date d\'import',
    },
    {
      field: 'status',
      sortable: false,
      translation: 'Statut',
    },
    {
      field: 'actions',
      sortable: false,
      translation: 'Actions',
      colSpan: 1,
    },
  ];

  return (
    <div className="list-products-imports d-flex flex-column h-100 mh-90vh justify-content-between">
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          { error }
        </div>
      )}

      <HeaderParamsList
        itemsPerPage={itemsPerPage}
        page={searchParams.page}
        path={PATH_IMPORTS}
        total={total}
        onChangeItemsPerPage={handleChangeItemsPerPage}
        noCreate
      />

      <div className="mb-3 overflow-hidden block-table-filters">
        <Filters
          filters={searchParams}
          onSubmit={handleSubmit}
        />
      
        <div id="block-table-products-imports" className="w-100 mw-100 overflow-auto scroll-custom mb-3">
          {
            loading
              ?
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <Spinner />
                </div>
              :
                (
                  <Table>
                    <TableHeader
                      headers={tableHeaders}
                      order={order} 
                      onChangeOrder={handleChangeOrder}  
                    />
                    <tbody>
                      { 
                        dataBlock.length === 0
                          ?
                            (<tr>
                              <td colSpan={10}>
                                <div className="d-flex justify-content-center align-items-center minh-50vh">
                                  <span>Pas de résultat.</span>
                                </div>
                              </td>
                            </tr>)
                          :
                            dataBlock
                      }
                    </tbody>
                  </Table>
                )
          }
          
        </div>
      </div>
      <PaginationWithSearch 
        view={urlsPagination} 
        filters={searchParams}  
      />
    </div>
  );
}

export default Imports;