import React from 'react';

import BlockContainer from '../../../components/BlockContainer/BlockContainer';
import Button from '../../../components/Button/Button';

const FormVideos = (props) => {
  const { urlVideos, onChangeNameField } = props;

  const handleChange = (position, value) => {
    let newUrlVideos = [...urlVideos];

    newUrlVideos = newUrlVideos.map((newUrlVideo, index) => {
      if (index === position) {
        newUrlVideo = value;
      }
      return newUrlVideo;
    });

    onChangeNameField('urlVideos', newUrlVideos);
  };

  const handleAddNewVideo = () => {
    let newUrlVideos = [...urlVideos];

    newUrlVideos.push('');

    onChangeNameField('urlVideos', newUrlVideos);
  };

  const blockVideos = urlVideos.map((urlVideo, index) => {
    const result = urlVideo.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/);
    const result1 = urlVideo.match(/youtu.be\/(.{11})/);
    let videoId = null;
    if (result !== null) {
      videoId = result.pop();
    } else if (result1 !== null) {
      videoId = result1.pop();
    }
    return (
      <div className="form-group" key={`url-video-${index}`}>
        <label
          htmlFor="urlVideo"
          className="form-control-label"
        >
          Url vidéo { index + 1 }
        </label>
        <input
          type="text"
          id='urlVideo'
          name="urlVideo"
          className="form-control"
          value={urlVideo}
          onChange={(e) => handleChange(index, e.target.value)}
        />
        {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
        { urlVideo.length > 0 && videoId !== null && (
          <img 
            src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
            className="mx-auto my-auto img-fluid mt-3"
            alt="Thumbnail youtube"
          />
        )}
      </div>
    );
  });

  return (
    <BlockContainer title="Vidéo produit">
      <p style={{color: 'red'}}>Pour avoir une miniature, les liens sont attendus sous forme : <br/> https://www.youtube.com/watch?v=VIDEO_ID<br/> OU <br/>https://youtu.be/VIDEO_ID</p>
      { blockVideos }
      { urlVideos.length > 0 && urlVideos[urlVideos.length - 1].length > 0 && (
        <Button onClick={handleAddNewVideo}>
          Ajouter une vidéo
        </Button>
      )}
    </BlockContainer>
  );
};

export default FormVideos;