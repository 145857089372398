import React from 'react';

import './styles/Button.scss';

const Button = (props) => {
  const {
    type,
    className,
    children,
    title,
    onClick,
  } = props;

  //TODO: add package className

  return (
    <button type={type} title={title} className={className} onClick={onClick}>{children}</button>
  );
};

export default Button;