import React from 'react';

import './styles/Form.scss';

const Form = (props) => {
  const { children, className, onSubmit } = props;

  return (
    <form onSubmit={onSubmit} className={className}>
      { children }
    </form>
  );
};

export default Form;