import React from 'react';
import { withRouter } from 'react-router-dom';

import './styles/ActionsIconTable.scss';

const ActionsIconTable = (props) => {
  const { history, item, match, path, onDelete } = props;
  const page = !!match.params.page ? decodeURIComponent(match.params.page) : history.location.search;

  return (
    <>
      <td className="action-table">
        <div className="center-line">
          <span onClick={() => history.push(`${path}show/${encodeURIComponent(item["id"])}${page}`)}>
            <i className="fa fa-search icon-actions" aria-hidden="true" />
            <i className="sr-only">Voir</i>
          </span>
        </div>
      </td>
      <td className="action-table">
        <div className="center-line">
          <span onClick={() => history.push(`${path}edit/${encodeURIComponent(item["id"])}${page}`)} >
            <i className="fa fa-pencil icon-actions" aria-hidden="true" />
            <i className="sr-only">Editer</i>
          </span>
        </div>
      </td>
      <td className="action-table">
        <div className="center-line">
          <span onClick={() => onDelete(item.id)}>
            <i className="fa fa-trash icon-actions" aria-hidden="true" />
            <i className="sr-only">Supprimer</i>
          </span>
        </div>
      </td>
    </>
  );
};

ActionsIconTable.defaultProps = {
  path: ''
};

export default withRouter(ActionsIconTable);