import React from 'react';

import ExpansionPanelFilters from '../../../components/ExpansionPanel/ExpansionPanelFilters';

const Filters = (props) => {
  //const { filters, onSubmit } = props;
  const { onSubmit } = props;

  const handleSubmit = () => {
    const filters = {};

    onSubmit(filters);
  };

  const handleClear = () => {
    onSubmit({});
  };

  return (
    <ExpansionPanelFilters 
      title='Filtres'
      active={false}
      onSubmit={handleSubmit}
      onClear={handleClear}
    >
    </ExpansionPanelFilters>
  );
};

export default Filters;