import React, { useState } from 'react';

import ExpansionPanelFilters from '../../../../components/ExpansionPanel/ExpansionPanelFilters';

const Filters = (props) => {
  const { filters, onSubmit } = props;
  const [ name, setName ] = useState(!!filters.name ? filters.name : '');

  const handleSubmit = () => {
    const filters = {};
    if (name !== '') {
      Object.assign(filters, { name: name.trim() });
    }

    onSubmit(filters);
  };

  const handleClear = () => {
    if (name !== '') setName('');

    onSubmit({});
  };

  return (
    <ExpansionPanelFilters 
      title='Filtres'
      active={false}
      onSubmit={handleSubmit}
      onClear={handleClear}
    >
      <div className="row">
        <div className="col-xs-12 col-md-12">
          <div className="form-group">
            <label
              htmlFor="banner_name"
              className="form-control-label"
            >
              Nom
            </label>
            <input
              type="text"
              id='banner_name'
              name="name"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {/*isInvalid && <div className="invalid-feedback">{data.meta.error}</div>*/}
          </div>
        </div>
      </div>
    </ExpansionPanelFilters>
  );
};

export default Filters;