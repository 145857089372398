import React from 'react';
import { Link } from 'react-router-dom';

import ActionsIconTable from '../../../components/ActionsIconTable/ActionsIconTable';
import MediaObject from '../../../MediaObject/components/MediaObject';

const CustomList = (props) => {
  const {
    items: laboratories,
    onDelete,
  } = props;

   const dataBlock = laboratories && laboratories.map((item) => (
    <tr key={'laboratory-' + item["id"]} id={item.id}>
      <th scope="row">
        <Link to={`show/${encodeURIComponent(item["id"])}`}>
          {item["id"]}
        </Link>
      </th>
      <td>{item["name"]}</td>
      <td>{item["phone"]}</td>
      <td>{item["website"]}</td>
      <td>
        <div className="center-logo">
          <MediaObject logo={item["logo"]} />
        </div>
      </td>
      <ActionsIconTable
        item={item}
        onDelete={onDelete}
      />
    </tr>
  ));

  return (
    <>
      {
        dataBlock.length === 0
          ?
            (<tr>
              <td colSpan={10}>
                <div className="d-flex justify-content-center align-items-center minh-50vh">
                  <span>Pas de résultat.</span>
                </div>
              </td>
            </tr>)
          :
            dataBlock
      }
    </>
  );
};

export default CustomList;