import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { catalogService } from '../service';
import * as catalogConstants from '../constants';

import Spinner from '../../components/Spinner/Spinner';

const { PATH } = catalogConstants;

const Show = (props) => {
  const [ catalog, setCatalog ] = useState(catalogConstants.initialCatalog);
  const [ error, setError ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const { history, location, match } = props;

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûrs ?")) {
      setLoading(true);
      catalogService._delete(match.params.id).then(() => {
        setError('');
        setCatalog(catalogConstants.initialCatalog);
        setLoading(false);
        if (location.search !== '') {
          history.push({
            pathname: PATH,
            search: !!location.search && location.search !== '' ? location.search.replace('?', '') : ''
          });
        } else {
          history.push(PATH);
        }
      }).catch((e) => {
        //TODO: setCatalog initial ?
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    const loadDatas = async () => {
      try {
        setLoading(true);
        const newCatalog = await catalogService.getById(match.params.id);
        setCatalog(newCatalog);
        setLoading(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoading(false);  
      }
    };

    loadDatas();
  }, [match]);

  if (loading) {
    return (
      <div className="d-flex flex-column h-100">
        <Spinner className="d-flex align-items-center h-100 justify-content-center" />
      </div>
    );
  }

  let createdAt = undefined;
  if (!isNaN(Date.parse(catalog.createdAt))) {
    const createdAtDate = new Date(catalog.createdAt);
    createdAt = createdAtDate.toLocaleDateString() + ' à ' + createdAtDate.toLocaleTimeString();
  }

  let updatedAt = undefined;
  if (!isNaN(Date.parse(catalog.updatedAt))) {
    const updatedAtDate = new Date(catalog.updatedAt);
    updatedAt = updatedAtDate.toLocaleDateString() + ' à ' + updatedAtDate.toLocaleTimeString();
  }

  const paramsForBack = {
    pathname: PATH,
    search: !!location.search ? location.search.replace('?', '') : '',
    hash: !!catalog ? '#' + catalog.id : ''
  };
  const paramsForEdit = {
    ...paramsForBack,
    pathname: `${PATH}edit/${encodeURIComponent(catalog["id"])}`
  };


  return (
    <div className="d-flex flex-column pb-3">
      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}
      {catalog && (
        <table className="table table-responsive table-striped table-hover">
          <thead>
            <tr>
              <th>Champ</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Nom</th>
              <td key="catalog_name">{catalog["name"]}</td>
            </tr>
            <tr>
              <th scope="row">Date de création</th>
              <td>{ createdAt }</td>
            </tr>
            <tr>
              <th scope="row">Date de modification</th>
              <td>{ updatedAt }</td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="d-flex justify-content-between mb-3 pb-3">
        <Link to={paramsForBack} className="btn btn-primary">
          Retour à la liste
        </Link>
        {catalog && (
          <Link to={paramsForEdit} className="btn btn-warning">
            Editer
          </Link>
        )}
        <button onClick={handleDelete} className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Show;