import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import InputSearch from './InputSearch';

import { categoryService } from '../../Category/service';
import * as categoryConstants from '../../Category/constants';

const { API_PATH } = categoryConstants;

const InputSearchCategory = (props) => {
  const { categoryId, className, label, field, value } = props;
  //const [ suggests, setSuggests ] = useState([]);
  const [ categories, setCategories ] = useState([]);
  const [ selected, setSelected ] = useState({});
  const [ loading, setLoading ] = useState(false);

  const categoriesSuggests = categories.filter((cat) => cat.id !== categoryId).map((category) => {
    return {
      label: category.name,
      value: category.id,
    };
  });

  const handleFocus = () => {
    if (categories.length === 0 && !!selected && !!selected.label && !!selected.value) {
      categoriesSuggests.push({
        label: selected.label,
        value: selected.value
      });
    }
  };

  const handleSearchChange = (field, value) => {
    const { onChange } = props;
    const filters = {
      name: value,
    };
    onChange(field, value);

    if (value.length > 2) {
      setLoading(true);
      categoryService.getAll(API_PATH, filters).then((newCategories) => {
        setCategories(newCategories && newCategories["hydra:member"]);
        setLoading(false);
      });
    } else {
      setCategories([]);
    }
  };

  const handleClear = (field) => {
    const { onClear } = props;

    onClear(field);
    setCategories([]);
  };

  const handleSelect = (field, value) => {
    const { onSelect } = props;

    setSelected(value);
    onSelect(field, value);
  };

  useEffect(() => {
    let mounted = true;
    let controller = new AbortController();

    if (mounted) {
      setLoading(true);
      categoryService.getAll(API_PATH, {}, {
        signal: controller.signal
      }).then((newCategories) => {
        setCategories(newCategories && newCategories["hydra:member"]);
        setLoading(false);
      }).catch(e => {
        console.log('Error InputSearchCategory :', e)
      });;
    }

    return () => {
      mounted = false;
      controller?.abort();
    }
  }, []);

  const selectClassName = classNames({
    'input-search-category': true,
    [className]: !!className,
  });

  //TODO: onRemove ?

  return (
    <InputSearch
      field={!!field ? field : "category"}
      label={!!label ? label : "Catégorie"}
      closeOnSelect
      value={value}
      suggests={categoriesSuggests}
      onClear={handleClear}
      onChange={handleSearchChange}
      onSelect={handleSelect}
      onFocus={handleFocus}
      className={selectClassName}
      loading={loading}
    />
  );
};

export default InputSearchCategory;