import React, { useState } from 'react';

import IconSimpleArrow from '../../icons/IconSimpleArrow';

import { classificationService } from '../service';
import * as classificationConstants from '../constants';

import Form from './Form';

const { PATH } = classificationConstants;

const Create = (props) => {
  const [ classification, setClassification ] = useState(classificationConstants.initialClassification);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const { history } = props;

  const handleChange = (e) => {
    const { target } = e;
    const newClassification = {...classification};

    newClassification[target.name] = target.value;

    setClassification(newClassification);
  };

  const handleChangeNameField = (field, value) => {
    const newClassification = {...classification};

    newClassification[field] = value;

    setClassification(newClassification);
  };

  const handleCreate = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const payload = { ...classification };
      if (!!payload.catalog && !!payload.catalog.value) {
        payload.catalog = payload.catalog.value;
      } else {
        delete payload.catalog;
      }
      const newClassification = await classificationService.create(payload);
      setError('');
      setLoading(false);
      if (!!newClassification && !!newClassification.id) {
        history.push(`${PATH}edit/${newClassification.id}`);
      }
    } catch (e) {
      //TODO: setClassification(classificationConstants.initialclassification); ?
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="go-back" onClick={() => history.push(PATH)}>
        <IconSimpleArrow />
        <span>Retour</span>
      </div>

      {error && (
        <div className="alert alert-danger" role="alert">
          <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
          {error}
        </div>
      )}

      <Form
        className="mb-5"
        onSubmit={handleCreate}
        values={classification}
        onChange={handleChange}
        onChangeNameField={handleChangeNameField}
        loading={loading}
      />
    </div>
  );
};

export default Create;