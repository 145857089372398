import React, { useState } from 'react';

import Tab from './Tab';
import Spinner from '../Spinner/Spinner';

import './styles/Tabs.scss';

const Tabs = (props) => {
  const { activeId, children, isLoading } = props;
  const [ activeTab, setActiveTab ] = useState(activeId);

  const handleTabClick = (tabId: string) => {
    const { onSelect } = props;

    setActiveTab(tabId);
    if (onSelect) {
      onSelect(tabId);
    }
  };

  const elements = React.Children.toArray(children);
  const selectedChild = elements.find((child) => child.props.id === activeTab) || {};

  return (
    <>
      <ul className="nav nav-tabs">
          {elements.map((element, index) => {
            const id = element.props.id || `${index}`;
            const isSelected = activeTab === id;
            return (
              <Tab
                {...element.props}
                key={id}
                onClick={handleTabClick}
                selected={isSelected}
              />
            );
          })}
      </ul>
      <div className="tab-content d-flex flex-column h-100 p-3">
        {isLoading && (
          <Spinner className="d-flex align-items-center h-100 justify-content-center" />
        )}

        {!isLoading && Object.keys(selectedChild).length > 0 && (
          selectedChild.props.children
        )}
      </div>
    </>
  );
}

Tabs.defaultProps = {
  activeId: '',
  className: 'tabs-menu',
  isLoading: false,
  onSelect: () => {},
};

export default Tabs;
