import React from 'react';

import Spinner from '../../../components/Spinner/Spinner';
import Button from '../../../components/Button/Button';

import * as globalHelpers from '../../../utils/globalHelpers';
import * as productConstants from '../../constants';

const { PATH, PATH_IMPORTS, PATH_BCB } = productConstants;

const Header = (props) => {
  const {
    history,
    deletingIds,
    itemsPerPage,
    loadingImport,
    searchParams,
    total,
    onChangeItemsPerPage,
    onDeleteAll,
    onImportFile,
    categoriesDisplayedPairs,
    descriptionDisplayedPairs,
  } = props;
  const [ isCategoriesDisplayed, setIsCategoriesDisplayed ] = categoriesDisplayedPairs;
  const [ isDescriptionDisplayed, setIsDescriptionDisplayed ] = descriptionDisplayedPairs;

  const handleImportFiles = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await onImportFile(e);

    // hack, if not, this function is only called first time
    e.target.value = "";
  }

  return (
    <div className="d-flex justify-content-between">
      <div className="d-inline-flex">
        <div className="me-3">
          <div>
            <label htmlFor="change_items_per_page">Éléments / page : </label>
            <select
              name="change_items_per_page"
              onChange={onChangeItemsPerPage}
              value={itemsPerPage}
              className="ms-2"
            >
              <option>10</option>
              <option>20</option>
              <option>30</option>
              <option>50</option>
              <option>100</option>
            </select>
          </div>
          { globalHelpers.generateTotalPagination(itemsPerPage, searchParams.page, total) }
        </div>
        <div>
          <div className="form-check form-switch">
            <input 
              className="form-check-input"
              type="checkbox"
              checked={isCategoriesDisplayed}
              onChange={() => setIsCategoriesDisplayed(!isCategoriesDisplayed)}
            />
            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Afficher les catégories</label>
          </div>
          <div className="form-check form-switch">
            <input 
              className="form-check-input"
              type="checkbox"
              checked={isDescriptionDisplayed}
              onChange={() => setIsDescriptionDisplayed(!isDescriptionDisplayed)}
            />
            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Afficher les descriptions courtes</label>
          </div>
        </div>
      </div>
      <div className="buttons">
        <div className="d-inline-flex buttons-block">
          { deletingIds.length > 0 && (
            <Button className="btn-danger btn-delete-all" onClick={onDeleteAll} title="Tout supprimer">
              <i className="fa fa-trash fa-2x" aria-hidden="true" />
              <i className="sr-only">Tout supprimer</i>
            </Button>
          )}
          <Button className="btn-secondary" onClick={() => history.push(`${PATH}create`)} title="Ajouter">
            <i className="fa fa-plus-circle fa-2x" aria-hidden="true" />
            <i className="sr-only">Ajouter</i>
          </Button>
          <div className="import">
            <label htmlFor="upload-btn" className="btn btn-primary btn-sm center-block btn-file btn-import">
              { loadingImport
                  ? <Spinner />
                  :
                    <>
                      <i title="Importer fichier" className="fa fa-upload fa-2x" aria-hidden="true" />
                      <i className="sr-only">Importer</i>
                      <input type="file" id="upload-btn" name="import" style={{display: "none"}} onChange={handleImportFiles} multiple />
                    </>
              }
            </label>
          </div>
          <Button onClick={() => history.push(PATH_IMPORTS)}>
            <i className="fa fa-list-ol fa-2x" aria-hidden="true" />
            <i className="sr-only">Liste des imports</i>
          </Button>
        </div>
        <div className="d-inline-flex buttons-block me-3 me-md-0">
            <Button className="btn-secondary" onClick={() => history.push(`${PATH_BCB}create`)} title="Ajouter BCB">
              <i className="fa fa-plus-circle fa-2x" aria-hidden="true" />
              <i className="d-block">BCB</i>
            </Button>
            <Button onClick={() => history.push(PATH_BCB)} className="me-md-3" title="Liste des imports BCB">
              <i className="fa fa-list-ol fa-2x" aria-hidden="true" />
              <i className="d-block">BCB</i>
            </Button>
          </div>
      </div>
    </div>
  );
};

export default Header;