// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconProducts = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-products', className),
    onClick,
  };

  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" {...newProps}>
      <path d="M8.914 6.391h-1.77v2.113H4.968v1.774h2.176v2.113h1.77v-2.113h2.113V8.504H8.914z" />
      <path d="M15.084 3.139h-3.443V.9a.909.909 0 00-.916-.9h-5.45a.908.908 0 00-.916.9v2.239H.914a.909.909 0 00-.917.9v11.063a.909.909 0 00.917.9h14.17a.909.909 0 00.916-.9V4.038a.908.908 0 00-.916-.899zm-8.888-1.34h3.611V3.14H6.196zm-4.363 12.4V4.937h12.335v9.262z" />
    </svg>
  );
};

IconProducts.defaultProps = {
  className: 'icon-products',
  onClick: () => {},
};

export default IconProducts;
