import React, { useState } from 'react';

import { default as GenericList } from '../../../../components/List/List';

import { bannerService } from '../../service';

import Filters from './Filters';
import CustomList from './CustomList';
import MediaObject from '../../../../MediaObject/components/MediaObject';
import Modal from '../../../../components/Modal/Modal';
import * as bannerConstants from '../../constants';

import '../../styles/List.scss';

const {
  API_PATH,
  PATH,
  PATH_BANNER,
  TABLE_HEADERS_BANNERS
} = bannerConstants;

const defaultParams = {
  itemsPerPage: 10,
  page: 1,
  order: {
    name: 'asc',
  }
};

const Page = (props) => {
  const [ error, setError ] = useState('');
  const [ modalPicture, setModalPicture ] = useState({});

  return (
    <>
      {
        Object.keys(modalPicture).length > 0 && (
          <Modal showFooter onClose={() => setModalPicture({})}>
            <MediaObject logo={modalPicture} />
          </Modal>
        )
      }
      <GenericList
        className="banners"
        config={
          {
            pathForButtons: PATH_BANNER,
            PATH: PATH,
            API_PATH: API_PATH,
            service: bannerService,
            tableHeaders: TABLE_HEADERS_BANNERS,
            defaultParams: defaultParams,
          }
        }
        errorPairs={[error, setError]}
      >
        <Filters />
        <CustomList setModalPicture={setModalPicture} />
      </GenericList>
    </>
  );
};

export default Page;