/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconProductLine = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-product-lines', className),
    onClick,
  };

  return (
    <svg viewBox="-48 0 480 480" xmlns="http://www.w3.org/2000/svg" {...newProps}>
      <path d="m104 176c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-88v-64h120c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-120v-56.0625c.003906-4.382812 3.554688-7.933594 7.9375-7.9375h144.125c4.382812.003906 7.933594 3.554688 7.9375 7.9375v64.0625c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-64.0625c-.035156-13.207031-10.730469-23.9023438-23.9375-23.9375h-144.125c-13.207031.0351562-23.9023438 10.730469-23.9375 23.9375v336.125c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-184.0625zm0 0"/>
      <path d="m40 120c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h24c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0"/>
      <path d="m104 128c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8 3.582031-8 8-8 8 3.582031 8 8zm0 0"/>
      <path d="m104 208h-48c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h48c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0"/>
      <path d="m112 248c0-4.417969-3.582031-8-8-8h-64c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h64c4.417969 0 8-3.582031 8-8zm0 0"/>
      <path d="m40 272c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h32c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0"/>
      <path d="m360.0625 112h-32.0625c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h32.0625c4.382812.003906 7.933594 3.554688 7.9375 7.9375v40.0625h-40c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h40v112h-8c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h8v32.0625c-.003906 4.382812-3.554688 7.933594-7.9375 7.9375h-.0625c-4.417969.019531-7.984375 3.613281-7.96875 8.03125.019531 4.417969 3.613281 7.988281 8.03125 7.96875 13.207031-.035156 23.902344-10.730469 23.9375-23.9375v-216.125c-.035156-13.207031-10.730469-23.902344-23.9375-23.9375zm0 0"/>
      <path d="m151.9375 128h32.0625c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-32.0625c-13.207031.035156-23.902344 10.730469-23.9375 23.9375v144.0625c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-88h40c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-40v-40.0625c.003906-4.382812 3.554688-7.933594 7.9375-7.9375zm0 0"/>
      <path d="m184 304h-104c-26.5.027344-47.972656 21.5-48 48v80c.027344 26.5 21.5 47.972656 48 48h10c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-10c-17.671875 0-32-14.328125-32-32v-56h168v56c0 17.671875-14.328125 32-32 32h-48c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h48c26.5-.027344 47.972656-21.5 48-48v-80c-.027344-26.5-21.5-47.972656-48-48zm-136 56v-8c0-17.671875 14.328125-32 32-32h104c17.671875 0 32 14.328125 32 32v8zm0 0"/>
      <path d="m192 408c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-120c-4.417969 0-8 3.582031-8 8v40c0 4.417969 3.582031 8 8 8h120c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-112v-24zm0 0"/>
      <path d="m267.0625 296.609375c-2.988281-1.238281-6.429688-.554687-8.71875 1.734375l-16 16c-3.03125 3.140625-2.988281 8.128906.097656 11.214844 3.085938 3.085937 8.074219 3.128906 11.214844.097656l2.34375-2.34375v20.6875c0 .152344.078125.273438.089844.425781.046875.542969.15625 1.078125.324218 1.597657.105469.472656.25.9375.433594 1.382812.230469.425781.5.824219.800782 1.203125.308593.457031.667968.882813 1.0625 1.269531.113281.105469.167968.242188.28125.335938.382812.253906.78125.476562 1.199218.664062.300782.1875.609375.359375.929688.511719.914062.378906 1.890625.582031 2.878906.609375 1.128906-.007812 2.238281-.257812 3.265625-.726562.242187-.148438.480469-.308594.710937-.480469.730469-.410157 1.390626-.929688 1.960938-1.546875.085938-.101563.222656-.140625.308594-.246094l17.753906-22.191406v17.191406c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-40c0-3.398438-2.144531-6.425781-5.351562-7.550781-3.207032-1.128907-6.773438-.101563-8.894532 2.550781l-17.753906 22.191406v-17.191406c0-3.234375-1.949219-6.152344-4.9375-7.390625zm0 0"/>
      <path d="m296 368h-40c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h40c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0"/>
      <path d="m264 120c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8 3.582031-8 8-8 8 3.582031 8 8zm0 0"/>
      <path d="m312 208h-8v-104c0-13.253906-10.746094-24-24-24h-48c-13.253906 0-24 10.746094-24 24v104h-8c-13.253906 0-24 10.746094-24 24v48c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-8h96c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-96v-24c0-4.417969 3.582031-8 8-8h112c4.417969 0 8 3.582031 8 8v168h-64c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h64v40c0 4.417969-3.582031 8-8 8h-64c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h64c13.253906 0 24-10.746094 24-24v-224c0-13.253906-10.746094-24-24-24zm-80-112h48c4.417969 0 8 3.582031 8 8v40h-64v-40c0-4.417969 3.582031-8 8-8zm-8 112v-48h64v48zm0 0"/>
    </svg>
  );
};

IconProductLine.defaultProps = {
  className: 'icon-product-lines',
  onClick: () => {},
};

export default IconProductLine;
