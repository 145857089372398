// @flow
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';

type Props = {
  className: string,
  onClick: () => void,
};

const IconBurgerMenu = (props: Props) => {
  const { className, onClick } = props;
  const newProps = {
    className: classNames('icon', 'icon-burger-menu', className),
    onClick,
  };

  return (
    <svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" {...newProps}>
      <path d="M16 14v-2H6v2zm0-6V6H0v2zm0-6V0H0v2z" />
    </svg>
  );
};

IconBurgerMenu.defaultProps = {
  className: 'icon-burger-menu',
  onClick: () => {},
};

export default IconBurgerMenu;
