import React, { useEffect, useRef, useState } from 'react';

import { default as GenericList } from '../../../components/List/List';

import { productService } from '../../service';
import { mediaObjectService } from '../../../MediaObject/service';

import Header from './Header';
import CustomList from './CustomList';
import FormCategories from '../Form/FormCategories';
import Filters from './Filters';
import MediaObject from '../../../MediaObject/components/MediaObject';
import Modal from '../../../components/Modal/Modal';

import * as productHelpers from '../../helpers';
import * as productConstants from '../../constants';

import '../../styles/List.scss';

const {
  API_PATH,
  PATH,
  TABLE_HEADERS
} = productConstants;

const defaultParams = {
  itemsPerPage: 100,
  page: 1,
  order: {
    name: 'asc',
  }
};

const Page = () => {
  const [ deletingIds, setDeletingIds ] = useState([]);
  const [ isCategoriesDisplayed, setIsCategoriesDisplayed ] = useState(false);
  const [ isDescriptionDisplayed, setIsDescriptionDisplayed ] = useState(false);
  const [ modalPicture, setModalPicture ] = useState({});
  const [ modalCategories, setModalCategories ] = useState({});
  const [ loadingImport, setLoadingImport ] = useState(false);
  const [ error, setError ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const timer = useRef(null);

  const handleChangeProduct = (field, value) => {
    const newProduct = {...modalCategories};

    newProduct[field] = value;

    setModalCategories(newProduct);
  };

  const handleRemoveCatalogs = (field, value) => {
    const valuesSelected = !!modalCategories[field] ? modalCategories[field] : [];
    const filteredValues = valuesSelected.filter((valueSelected) => valueSelected.value !== value.value);

    let newClassifications = [...modalCategories.classifications];
    const classificationsToRemove = newClassifications
      .filter((classification) => classification.catalog === value.value)
      .map((classification) => classification.value)
    newClassifications = newClassifications.filter((classification) => classification.catalog !== value.value);

    let newCategories = [...modalCategories.categories];
    newCategories = newCategories
      .map((cat) => cat.filter((category) => !classificationsToRemove.includes(category.classification)))
      .filter((c) => c.length > 0);

    const newProduct = {
      ...modalCategories,
      classifications: newClassifications,
      catalogs: filteredValues,
      categories: newCategories,
    };

    setModalCategories(newProduct);
  };

  const handleRemoveClassifications = (field, value) => {
    const valuesSelected = !!modalCategories[field] ? modalCategories[field] : [];
    const filteredValues = valuesSelected.filter((valueSelected) => valueSelected.value !== value.value);

    let newCategories = [...modalCategories.categories];
    newCategories = newCategories
      .map((cat) => cat.filter((category) => category.classification !== value.value))
      .filter((c) => c.length > 0);

    const newProduct = {
      ...modalCategories,
      categories: newCategories,
      classifications: filteredValues,
    };

    setModalCategories(newProduct);
  };

  const handleUpdateCategories = async () => {
    try {
      setLoading(true);
      const payload = await productHelpers.parseProductOut({...modalCategories});
      await productService.update(payload.id, payload);
      setError('');
      setLoading(false);
      setModalCategories({});
      if (window.location.hash === '#' + payload.id) {
        // little hack for making reloading datas, when we have already hash set
        window.location.hash = '';
      } 
      window.location.hash = payload.id;
    } catch (e) {
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false);
    }
  };

  const handleDeleteAll = async () => {
    try {
      setLoading(true);
      for (let x = 0; x < deletingIds.length; x++) {
        await productService._delete(deletingIds[x]);
      }
      setDeletingIds([]);
      setError('');
      
      setLoading(false);

      //reload List
      window.location.reload(true);

    } catch (e) {
      setDeletingIds([]);
      setError(!!e && !!e.message ? e.message : e);
      setLoading(false); 
    }
  };

  const handleImportFile = async (event) => {
    //TODO: check extension? only images and xlsx ?
    const { files } = event.target;
    if (files.length > 50) {
      setError('Trop de fichiers uploadés. Maximum 50 fichiers.');
      return;
    }
    if (files.length > 0) {        
      let filesUploaded = [];
      try {
        setLoadingImport(true);
        for (let i = 0; i < files.length; i++) {
          let uploadedFile = await mediaObjectService.createMediaObject();
          await mediaObjectService.uploadMediaObject(uploadedFile.postUrl, files[i]);
          
          uploadedFile.originalName = files[i].name;
          uploadedFile.name = files[i].name;
          uploadedFile.contentType = files[i].type;
          uploadedFile.contentSize = files[i].size;
          await mediaObjectService.updateMediaObject(uploadedFile.id, uploadedFile);
          filesUploaded.push(uploadedFile.id);
        }
        await productService.uploadImportFiles({'mediaObjects': filesUploaded});
        setError('');
        setLoadingImport(false);
      } catch (e) {
        setError(!!e && !!e.message ? e.message : e);
        setLoadingImport(false);
      }
    }
  }

  useEffect(() => {
    if (error !== '') {
      timer.current = setInterval(() => {
        setError('')
      }, 5000);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [error, timer]);
  
  return (
    <>
      {
        Object.keys(modalPicture).length > 0 && (
          <Modal showFooter onClose={() => setModalPicture({})}>
            <MediaObject logo={modalPicture} />
          </Modal>
        )
      }

      {
        Object.keys(modalCategories).length > 0 && (
          <Modal
            showFooter
            onClose={() => setModalCategories({})}
            onSave={handleUpdateCategories}
            loading={loading}
          >
            <div className="container">
              <div className="row">
                <FormCategories
                  classifications={modalCategories.classifications}
                  categories={modalCategories.categories}
                  catalogs={modalCategories.catalogs}
                  onChangeNameField={handleChangeProduct}
                  onRemoveCatalogs={handleRemoveCatalogs}
                  onRemoveClassifications={handleRemoveClassifications}
                />
              </div>
            </div>
          </Modal>
        )
      }

      <GenericList
        className="products"
        config={
          {
            pathForButtons: PATH,
            PATH: PATH,
            API_PATH: API_PATH,
            service: productService,
            tableHeaders: TABLE_HEADERS,
            defaultParams: defaultParams,
          }
        }
        loading={loading}
        errorPairs={[error, setError]}
        deletingIdsPairs={[deletingIds, setDeletingIds]}
        categoriesDisplayedPairs={[isCategoriesDisplayed, setIsCategoriesDisplayed]}
        descriptionDisplayedPairs={[isDescriptionDisplayed, setIsDescriptionDisplayed]}
        header={
          <Header
            loadingImport={loadingImport}
            onImportFile={handleImportFile}
            onDeleteAll={handleDeleteAll}
          />
        }
      >
        <Filters />
        <CustomList 
          setModalPicture={setModalPicture}
          setModalCategories={setModalCategories}
        />
      </GenericList>
    </>
  );
};

export default Page;